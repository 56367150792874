<template>
  <div>
    <div v-if="showLoading" class="mt-3 ml-6 d-flex justify-content-center">
      <div class="text-bold mb-0">Fetching Data</div>
      <div class="spinner-border ml-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <v-snackbar v-model="snackbar" timeout="5000" color="green lighten-1">
      Allocation Request Sent! This may take a minute to complete. <br />
      Page will refresh to see updates.
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarLock" timeout="1000" color="red lighten-1">
      Currently unable to change allocation while previous request is pending
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarLock = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-text-field
      v-if="items.length > 0"
      v-model="includedSearch"
      label="Search Programs"
      prepend-inner-icon="mdi-magnify"
      variant="outlined"
      hide-details
      single-line
    ></v-text-field>

    <v-layout style="height: 41vh">
      <v-flex style="overflow: auto">
        <v-data-table
          v-if="items.length > 0"
          :items="items"
          :headers="headers"
          :search="includedSearch"
          height="35vh"
          fixed-header
          fixed-footer
          :items-per-page="200"
          :key
          dense
        >
          <template slot="item.program_name" slot-scope="props">
            <span
              class="hover"
              @click="getFieldIDsFromProgram(props.item)"
              v-if="filter_type == 'corporation'"
            >
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ props.item.program_name }}
                    <v-icon small color="blue"> mdi-filter-outline </v-icon>
                  </div>
                </template>
                <span
                  >Click to filter the fieldsupply below to only those within
                  this program's eligible supply pool</span
                >
              </v-tooltip>
            </span>

            <span v-else>
              {{ props.item.program_name }}
            </span>
          </template>
          <template
            v-if="filter_type == 'program'"
            slot="item.corporation_name"
            slot-scope="props"
          >
            <span
              v-if="filter_type == 'program'"
              class="hover"
              @click="exportChannelPartner(props.item)"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ props.item.corporation_name }}
                    <v-icon small color="blue"> mdi-filter-outline </v-icon>
                  </div>
                </template>
                <span>Click to select Channel Partner for Re-Allotment</span>
              </v-tooltip>
            </span>

            <span v-else>
              {{ props.item.corporation_name }}
            </span>
          </template>
          <template slot="item.allocation_add" slot-scope="props">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn
                    color="dark green lighten-1"
                    :disabled="
                      props.item.program_locked ||
                      lockAll ||
                      props.item.pct_filled_and_signed == 100
                    "
                    small
                    @click="autoAllocatePool(props.item, 'add')"
                    >Allocate</v-btn
                  >
                </div>
              </template>
              <span
                >Click to automatically fill this program with eligible field
                supply
              </span>
            </v-tooltip>
          </template>
          <template
            v-if="!props.item.program_locked"
            slot="item.allocation_remove"
            slot-scope="props"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <i
                    class="pt-2 fa fa-times-circle float-right"
                    @click="autoAllocatePool(props.item, 'remove')"
                  ></i>
                </div>
              </template>
              <span
                >Click to remove all currently allocated supply from this
                program</span
              >
            </v-tooltip>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"

export default {
  name: "AllocationTable",
  props: ["filter_type", "programID"],
  data() {
    return {
      key: 0,
      items: [],
      showLoading: false,
      snackbarLock: false,
      includedSearch: "",
      lockAll: false,
      snackbar: false,
    }
  },
  computed: {
    headers() {
      if (this.filter_type == "program") {
        return [
          { text: "Program ID", value: "program_id" },
          { text: "Program Name", value: "program_name" },
          { text: "Channel Partner", value: "corporation_name" },
          { text: "Current Allocation (ac)", value: "allocated" },
          { text: "Total Allotment (ac)", value: "total_allotment" },
          {
            text: "% Available to Allocate from Total Supply Shed",
            value: "pct_filled",
          },
          {
            text: "% Available to Allocate From Requested",
            value: "pct_filled_and_requested",
          },
          { text: "Priority Score", value: "program_priority" },
          { text: "", value: "allocation_add" },
          { text: "", value: "allocation_remove" },
        ]
      } else {
        return [
          { text: "Program ID", value: "program_id" },
          { text: "Program Name", value: "program_name" },
          { text: "Current Allocation (ac)", value: "allocated" },
          { text: "Total Allotment (ac)", value: "total_allotment" },
          {
            text: "% Available to Allocate from Total Supply Shed",
            value: "pct_filled",
          },
          {
            text: "% Available to Allocate From Requested",
            value: "pct_filled_and_requested",
          },

          { text: "Priority Score", value: "program_priority" },
          { text: "", value: "allocation_add" },
          { text: "", value: "allocation_remove" },
        ]
      }
    },
  },

  methods: {
    exportChannelPartner(e) {
      this.$emit("export-channel-partner", e)
    },
    autoAllocatePool(e, type) {
      if (this.lockAll || e.program_locked) {
        this.snackbarLock = true
        return
      }
      this.showLoading = true
      this.lockAll = true
      this.snackbar = true
      CarbonReadyAPI.autoAllocateOnePool({
        order_id: e.program_id,
        job_type: type,
      }).then(response => {
        if (response.status == 200) {
          this.showLoading = false
          this.lockAll = false
          this.key = this.key + 1
          this.$emit("refresh-allocation")
        }
      })
    },
    getFieldIDsFromProgram(e) {
      this.showLoading = true
      CarbonReadyAPI.getEAOrderFSS({
        order_id: e.program_id,
        restrict_corporation: 1,
      }).then(response => {
        if (response.status == 200) {
          let fieldIDs = []
          let fssIDs = []
          response.data.forEach(fss => {
            fieldIDs.push(fss.fieldcrop.field_id)
            fssIDs.push(fss.id)
          })
          fieldIDs = _.uniq(fieldIDs)

          this.includedSearch = e.program_name
          this.$emit("field-id-list", fieldIDs)
          this.$emit("fss-id-list", fssIDs)
          this.showLoading = false
        }
      })
    },
  },

  mounted() {
    this.showLoading = true
    CarbonReadyAPI.postAllocationData({
      filter_type: this.filter_type,
      programID: this.programID,
    }).then(response => {
      if (response.status == 200) {
        this.items = response.data
        this.showLoading = false
        this.$emit("num_programs", this.items.length)
      }
    })
  },
}
</script>

<style scoped>
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
