export const CHANNEL_PARTNER = "cp"
export const GROWER = "grower"

export const YES = "yes"
export const NO = "no"

export const CLIENT_PROFILES = "clientProfiles"
export const ENROLLMENT_TABLE = "enrollmentTable"
export const ROLLUP_TABLE = "rollupTable"
export const FSS_INDIVIUAL = "fss"

export const OPEN = "open"
export const REQUESTED = "requested"
export const REQUESTED_PENDING = "requested_pending"
export const AVAILABLE = "available"
export const CONFIRMED = "confirmed"
export const LOCKED_FOR_PAYMENT = "locked_for_payment"

export const ADD_PROFILE = 0
export const REMOVE_PROFILE = 1
export const UPDATE_PROFILE = 2
export const SWAP_PROFILE = 3
export const CREATE_PROFILE = 4

export const BLANK_GROUP_DATA = {
  enrollment: {},
  cp_profiles: [],
  grower_profiles: [],
}

export const BLANK_METADATA = {
  thumbnail: null,
  farmName: null,
  practices: [],
  orderIds: 0,
}

export const BLANK_ACREAGE_VALUES = {}
BLANK_ACREAGE_VALUES[REQUESTED] = {}
BLANK_ACREAGE_VALUES[CONFIRMED] = {}
BLANK_ACREAGE_VALUES[REQUESTED_PENDING] = {}
BLANK_ACREAGE_VALUES[LOCKED_FOR_PAYMENT] = {}

export const STATUS_MAP = {}
STATUS_MAP[OPEN] = {
  text: "Unavailable",
  color: "gray",
}
STATUS_MAP[AVAILABLE] = {
  text: "Available",
  color: "gray",
}
STATUS_MAP[REQUESTED] = {
  text: "Requested",
  color: "green",
}
STATUS_MAP[REQUESTED_PENDING] = {
  text: "Pre-Requested",
  color: "green",
}
STATUS_MAP[CONFIRMED] = {
  text: "Confirmed",
  color: "confirmed-green",
}
STATUS_MAP[LOCKED_FOR_PAYMENT] = {
  text: "Locked For Payment",
  color: "green",
}

export const CP_VIEW = 0
export const CSM_VIEW = 1
