<template>
  <b-sidebar
    width="80vw"
    id="sidebar-right"
    title="Grower Contract"
    right
    shadow
    backdrop
    bg-variant="white"
    v-model="show"
  >
    <v-progress-circular
      v-if="profilesLoading['loading']"
      indeterminate
      :size="64"
      color="#79c61c"
    />

    <div
      :class="`px-8 sidebar-body-inner-padding-bottom ${
        profilesLoading['loading'] ? 'loading' : ''
      }`"
    >
      <div v-if="pdfUrl && !showClientProfileView" class="mb-4">
        <iframe
          :src="pdfUrl"
          width="100%"
          height="700px"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>

      <div v-else class="contract-input-group pb-16">
        <div class="mt-5">
          <p class="ma-0 mb-3 font-weight-bold confirm-contract-text">
            Overview
          </p>
          <v-row class="mb-2">
            <v-col cols="6">
              <p class="font-weight-bold">Legal Entity Name:</p>
              <p>{{ entityName || "N/A" }}</p>
            </v-col>
            <v-col cols="6">
              <p class="font-weight-bold">Channel Partner Name:</p>
              <p>{{ channelPartnerName || "N/A" }}</p>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-0 mb-2">
          <v-col>
            <p class="ma-0 font-weight-bold confirm-contract-text">
              Authorized representative
            </p>
          </v-col>
        </v-row>

        <SingularClientProfile
          :isCPProfile="true"
          :selected="groupData['cp_profiles'].length > 0 ? groupData['cp_profiles'][0] : null"
          @updateProfile="updateGroupProfile"
        />

        <div class="pt-8 mb-5">
          <p class="ma-0 font-weight-bold confirm-contract-text">
            Land Ownership
          </p>
          <v-radio-group v-model="landOwnership">
            <v-row>
              <v-col cols="4" class="d-flex align-center">
                <div>
                  <v-radio
                    label="Rented"
                    value="rented"
                  ></v-radio>
                  <p class="text-caption text-grey ml-8">
                    Land is rented by signatory
                  </p>
                </div>
              </v-col>
              <v-col cols="4" class="d-flex align-center">
                <div>
                  <v-radio
                    label="Owned"
                    value="owned"
                  ></v-radio>
                  <p class="text-caption text-grey ml-8">
                    Land is owned by signatory
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>

        <v-expansion-panels
          multiple
          accordion
          v-model="growerProfilePanelIndices"
          v-if="makingNewGrowerProfile || groupData['grower_profiles'].length > 0"
          class="mb-4"
        >
          <ClientProfileEditor
            v-for="(profile, idx) in groupData['grower_profiles']"
            :key="profile + idx"
            :selected="profile"
            :clientProfiles="clientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />

          <ClientProfileEditor
            v-if="makingNewGrowerProfile"
            :selected="null"
            :clientProfiles="clientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />
        </v-expansion-panels>

        <v-row class="mt-0 mb-0">
          <v-col cols="4" class="d-flex flex-wrap py-0">
            <v-btn
              @click="makingNewGrowerProfile = true"
              :disabled="makingNewCPProfile || makingNewGrowerProfile"
              :ripple="false"
              outlined
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="ml-2">Add additional signer</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      :class="`contract-navigation-handlers ${
        profilesLoading['loading'] ? 'loading' : ''
      }`"
    >
      <div v-if="pdfUrl && !showClientProfileView">
        <v-btn
          :ripple="false"
          outlined
          height="52"
          @click="removeSidebar()"
          >Exit</v-btn
        >
        <v-btn v-if="showVoidBtn"
          :ripple="false"
          outlined
          height="52"
          @click="voidContract()"
          >Void
        </v-btn>
        <v-btn
          color="green"
          class="white--text"
          :ripple="false"
          outlined
          height="52"
          @click="downloadContract()"
          >Download</v-btn
        >
        <v-btn v-if="!showVoidBtn && generateContractBtnText"
          color="green"
          class="white--text"
          :ripple="false"
          outlined
          height="52"
          @click="showClientProfileView = true"
          >{{ generateContractBtnText }}</v-btn
        >

        <!-- temp force disable -->
        <v-btn v-if="showESignBtn"
          color="green"
          class="white--text"
          :ripple="false"
          outlined
          height="52"
          @click="sendESign()"
          disabled
          >Send e-sign</v-btn
        >
      </div>
      <div v-else>
        <v-btn
          :ripple="false"
          outlined
          height="52"
          @click="removeSidebar()"
          >Exit</v-btn
        >
        <v-btn
          v-if="generateContractBtnText"
          color="green"
          class="white--text"
          :ripple="false"
          outlined
          height="52"
          :disabled="!generateContractIsEnabled"
          @click="generateContract()"
          >{{ generateContractBtnText }}</v-btn
        >
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import Vue from "vue"
import ClientProfileEditor from "@/components/contractGroups/ClientProfileEditor.vue"
import SingularClientProfile from "@/components/contractGroups/SingularClientProfile.vue"
import { EnrollmentGroups } from "@/store/modules"
import { mapActions, mapState } from "vuex"
import {
  GROWER,
  ADD_PROFILE,
  REMOVE_PROFILE,
  UPDATE_PROFILE,
  SWAP_PROFILE,
  CREATE_PROFILE,
  CHANNEL_PARTNER,
  BLANK_GROUP_DATA,
  CLIENT_PROFILES,
} from "@/constants/contractGroups"

const CONTRACT_HEADERS = [
  { text: "Date generated", valuef: "dateGenerated" },
  { text: "Due", value: "due" },
  { text: "Contract Owners", value: "contractOwners" },
  { text: "Status", value: "status" },
  { text: "LOI File", value: "loiFile" },
  { text: "LOI Last Sent", value: "loiLastSent" },
  { text: "Actions", value: "actions" },
]

const FIELD_HEADERS = [
  { text: "Field", value: "field" },
  { text: "Crop", value: "crop" },
  { text: "Farm", value: "farm" },
  { text: "Field ID", value: "fieldId" },
  { text: "Acres", value: "acres" },
]

export default {
  name: "GrowerContractSidebar",
  props: {
    groupId: { type: Number | String },
    visible: { type: Boolean },
    channelPartnerName: { type: String },
  },
  emits: ["closeSidebar"],
  components: { ClientProfileEditor, SingularClientProfile },
  data() {
    const clientProfileModificationsExist = {}
    clientProfileModificationsExist[CHANNEL_PARTNER] = {}
    clientProfileModificationsExist[GROWER] = {}

    return {
      show: false,
      cancelled: true,
      clientProfileModificationsExist,
      contractInputGroup: "groupDetails",
      dataForLOIModal: {},
      landOwnership: 'owned',
      landOwnershipDetails: "",
      entityName: "",
      makingNewCPProfile: false,
      makingNewGrowerProfile: false,
      showClientProfileView: false,
      cpProfilesOpen: 0,
      growerProfilePanelIndices: [],
      cpProfile: [],
      cpEmailsPreopen: [],
      growerEmailsPreopen: [],
      CONTRACT_HEADERS,
      FIELD_HEADERS,
      CHANNEL_PARTNER,
      GROWER,
    }
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year,
      org: state => state.Organization.organization,
      profilesLoading: state => state.EnrollmentGroups[CLIENT_PROFILES],
      clientProfiles: state => state.EnrollmentGroups.allClientProfiles,
      groupData: function (state) {
        const data = state.EnrollmentGroups.groupData[this.groupId]
        if (data == null) return structuredClone(BLANK_GROUP_DATA)
        return data
      },
    }),
    showVoidBtn() {
      return this.groupData["status"] != "contract voided" && this.groupData["status"] != "contract completed"
    },
    showESignBtn() {
      return this.groupData["status"] != "contract voided" && this.groupData["status"] != "contract completed"
    },
    pdfUrl() {
      return this.groupData["enrollment"]["file_link"]
    },
    filename() {
      return this.groupData["enrollment"]["file_name"].split("/").pop()
    },
    generateContractBtnText() {
      if (this.groupData["status"] === "contract completed") return null
      if (!this.showClientProfileView && this.pdfUrl) return "Re-generate Contract"
      else return "Generate Contract"
    },
    generateContractIsEnabled() {
      if (this.groupData["cp_profiles"].length == 0) return false

      // if any of the existing client profiles have unsaved changes, don't allow the 
      // contract to be generated... i.e., force profiles to have static, saved changes
      for (const key in this.clientProfileModificationsExist[GROWER]) {
        if (this.clientProfileModificationsExist[GROWER][key] == true) return false
      }

      // we need all client profiles to be complete, otherwise the contract will have missing data
      // for (const profile of this.groupData['cp_profiles']) {
      //   if (!profile['validated']) return false
      // }
      return true
    },
  },
  methods: {
    ...mapActions({
      editClientProfiles: EnrollmentGroups.Actions.editClientProfiles,
      fetchClientProfiles: EnrollmentGroups.Actions.fetchClientProfiles,
    }),
    cancelCreation({ as_cp_profile }) {
      if (as_cp_profile) this.makingNewCPProfile = false
      else this.makingNewGrowerProfile = false
    },
    addProfile({ as_cp_profile, to_id }) {
      this.editClientProfiles({
        type: ADD_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          client_profile_id: to_id,
          as_cp_profile,
        }
      })
      .then((data) => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    removeProfile({ as_cp_profile, remove_id }) {
      this.editClientProfiles({
        type: REMOVE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          as_cp_profile,
          remove_id,
        }
      })
      .then((data) => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    swapProfile({ as_cp_profile, from_id, to_id }) {
      this.editClientProfiles({
        type: SWAP_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          as_cp_profile,
          from_id,
          to_id,
        }
      })
      .then((data) => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    updateGroupProfile({ as_cp_profile, profile_dataset, profile_id }) {
      this.editClientProfiles({
        type: UPDATE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          profile_dataset,
          as_cp_profile,
          profile_id,
        }
      })
    },
    createGroupProfile({ as_cp_profile, profile_dataset }) {
      this.editClientProfiles({
        type: CREATE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          profile_dataset,
          as_cp_profile,
        },
      })
      this.makingNewGrowerProfile = false
    },
    setLocalTrackers() {
      this.entityName = this.groupData["name"]
    },
    handleProfileStatusChange({ as_cp_profile, profileId, status }) {
      if (as_cp_profile)
        Vue.set(this.clientProfileModificationsExist[CHANNEL_PARTNER], profileId, status)
      else Vue.set(this.clientProfileModificationsExist[GROWER], profileId, status)
    },
    removeSidebar() {
      this.$emit("closeSidebar", this.cancelled)
      this.entityName = ""
      this.cancelled = true
      this.showClientProfileView = false
      this.clientProfileModificationsExist[CHANNEL_PARTNER] = {}
      this.clientProfileModificationsExist[GROWER] = {}
    },
    cancel() {
      this.cancelled = true
      this.show = false
    },
    apply() {
      this.cancelled = false
      this.show = false
    },
    maintainExpansionStateAfterModification({ as_cp_profile, data }) {
      const targetIndices = as_cp_profile
        ? "cpProfile"
        : "growerProfilePanelIndices"
      const targetMakeKey = as_cp_profile
        ? "makingNewCPProfile"
        : "makingNewGrowerProfile"
      const profileKey = as_cp_profile ? "cp_profiles" : "grower_profiles"

      const newAddition = data[profileKey].find(({ id }) =>
        this.groupData[profileKey].every(c => c["id"] != id)
      )
      const newRemoval = this.groupData[profileKey].find(({ id }) =>
        data[profileKey].every(c => c["id"] != id)
      )

      const indices = this[targetIndices].slice()
      const idsByIndices = this.groupData[profileKey]
        .map(({ id }, idx) => (indices.includes(idx) ? id : null))
        .filter(i => i)

      const newIndices = data[profileKey]
        .map(({ id }, idx) => {
          if (newRemoval && newRemoval["id"] == id) return null
          if (newAddition && newAddition["id"] == id) return idx
          if (idsByIndices.includes(id)) return idx
          return null
        })
        .filter(d => d != null)

      if (this[targetMakeKey]) this[targetMakeKey] = false
      setTimeout(() => Vue.set(this, targetIndices, newIndices))
    },
    generateContract() {
      this.$emit("generate-contract", this.groupId, this.landOwnership)
      this.showClientProfileView = false
    },
    sendESign() {
      this.$emit("send-esign", this.groupId)
      this.apply()
    },
    downloadContract() {
      this.$emit("download-contract", this.pdfUrl, this.filename)
      this.apply()
    },
    voidContract() {
      this.$emit("void-contract", this.groupId)
      this.apply()
    },
  },
  watch: {
    visible(curr) {
      this.show = curr
    },
    show(curr) {
      if (!curr) this.removeSidebar()
      else {
        this.fetchClientProfiles({ group_id: this.groupId })
        this.setLocalTrackers()
      }
    },
    org() {
      this.fetchClientProfiles({ group_id: this.groupId })
    },
    makingNewCPProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "cpProfile",
          this.groupData["cp_profiles"].length == 0
            ? [0]
            : [...this.cpProfile, this.groupData["cp_profiles"].length]
        )
      }
    },
    makingNewGrowerProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "growerProfilePanelIndices",
          this.groupData["grower_profiles"].length == 0
            ? [0]
            : [
                ...this.growerProfilePanelIndices,
                this.groupData["grower_profiles"].length,
              ]
        )
      }
    },
  },
}
</script>

<style scoped>
#sidebar-right {
  background: #ffffff !important;
}

p {
  color: #000000;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.contracts-button-toggle > button {
  text-transform: none;
  padding: 12px 24px !important;
  letter-spacing: normal;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
  color: #374151;
}

.contracts-button-toggle
  > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.v-btn .v-btn__content > i {
  font-size: 20px;
}

.contracts-button-toggle > button.v-item--active {
  color: #61af02;
  border-bottom: 2px solid #61b100 !important;
  background: #f1fddf !important;
}

.contracts-button-toggle > button.v-item--active::before {
  display: none;
}

.contract-input-group p {
  color: #212121;
}

.contract-navigation-handlers button {
  border-color: rgba(0, 0, 0, 0.5);
}

.contract-navigation-handlers > div > button:not(:last-child) {
  margin-right: 12px;
}

.text-caption {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: -4px;
}

p.disabled-label {
  opacity: 0.5;
}

::v-deep .fips-option-not-supported + ::before {
  background-color: red !important;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep #sidebar-right {
  overflow: hidden;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 96px;
}

::v-deep .contract-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
}
::v-deep header.b-sidebar-header {
  padding: 32px 32px 0;
  position: relative;
}
::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
}
::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #000000;
  margin-left: 12px;
}
.confirm-contract-text {
  font-size: 23px;
  line-height: 33px;
}
.legal-business-name-text {
  font-size: 10px;
  line-height: 20px;
}
:deep(.v-label) {
  color: #000000;
}
:deep(.v-data-table-header) {
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.contract-table-header {
  font-size: 24px;
  line-height: 32px;
}
.contract-table-subheader {
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
:deep(.v-data-table__empty-wrapper > td) {
  padding-top: 16px !important;
}
:deep(.v-list-item__title) {
  text-transform: lowercase;
}
.v-expansion-panels {
  z-index: 0;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
}
.v-tooltip__content {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.v-tooltip__content :deep(ul) {
  padding: 0;
}
.v-tooltip__content :deep(ul li) {
  list-style: none;
}
.v-tooltip__content :deep(i) {
  color: #000000;
}
</style>
