<template>
  <ArvaModal
    name="confirmFSSRemoveModal"
    title="Confirm Field Removal From Program"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <h5>Remove this Field Crop from the Program: {{ selectedOrderName }}?</h5>
      <!-- also have a input for order name-->

      <h5 v-if="showError" class="pw-error pa-2">{{ errorText }}</h5>

      <ArvaModalSaveFooter
        confirmText="Confirm Remove"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="handleSubmit"
      />
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "ConfirmFSSRemovalModal",
  props: ["fssID", "selectedOrderID", "selectedOrderName"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      showError: false,
      errorText: "This FSS Could not Be Removed. It May be Locked, or is Part of a Generated Contract.",
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      let payload = {
        fieldSupplyIds: [this.fssID],
        order_id: this.selectedOrderID,
        job_type: "remove",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
