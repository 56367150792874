<template>
  <v-form class="mb-10 position-relative" :disabled="selected == null">
    <v-progress-circular v-if="loading" class="position-absolute" indeterminate :size="64" color="#79c61c" />
    <v-row :class="`mb-8 ${loading ? 'showing-spinner' : ''}`">
      <v-col cols="4">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="First Name"
          placeholder="First Name"
          underlined
          autocomplete="home street-address"
          :value="firstName"
          @change="(e) => handleChange('first_name', e)"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Last Name"
          placeholder="Last Name"
          underlined
          :value="lastName"
          @change="(e) => handleChange('last_name', e)"
        />
      </v-col>

      <v-col cols="4">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Title"
          placeholder="Title"
          underlined
          :value="title"
          @change="(e) => handleChange('title', e)"
        />
      </v-col>
    </v-row>

    <v-row :class="`mt-0 mb-6 ${loading ? 'showing-spinner' : ''}`">
      <v-col cols="6">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Email Address"
          placeholder="Email Address"
          underlined
          autocomplete="home street-address"
          :value="email"
          @change="(e) => handleChange('poc_email', e)"
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Phone Number"
          placeholder="(000) 000-000"
          :value="phoneNumber"
          @change="(e) => handleChange('phone_number', e)"
        >
          <template v-slot:prepend-inner>
            <v-select class="pa-0 ma-0 inner-select" height="40px" hide-details>
              <template v-slot:prepend-inner>
                <img :src="usIcon" class="pa-3 pr-0" />
              </template>

              <template v-slot:selection>yo</template>
            </v-select>
            <span class="d-flex align-center ml-1 mr-1">+1</span>
          </template>
        </v-text-field>
      </v-col>
    </v-row>

    <v-row :class="`mt-0 mb-8 ${loading ? 'showing-spinner' : ''}`">
      <v-col cols="6">
        <v-text-field
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Street Address Line 1"
          placeholder="Street Address Line 1"
          underlined
          autocomplete="home street-address"
          :value="streetAddress1"
          @change="(e) => handleChange('street_address_1', e)"
        />
      </v-col>

      <v-col cols="6">
        <v-text-field
          underlined
          hide-details
          height="40"
          class="mt-0 pa-0"
          label="Street Address Line 2 (Optional)"
          placeholder="Street Address Line 2 (Optional)"
          autocomplete="home street-address"
          :value="streetAddress2"
          @change="(e) => handleChange('street_address_2', e)"
        />
      </v-col>
    </v-row>

    <v-row :class="`mt-0 mb-8 ${loading ? 'showing-spinner' : ''}`">
      <v-col cols="4">
        <v-text-field
          hide-details
          height="40"
          label="City"
          placeholder="City"
          underlined
          autocomplete="home street-address"
          :value="city"
          @change="(e) => handleChange('city', e)"
        />
      </v-col>

      <v-col cols="4">
        <div class="d-flex align-end">
          <v-autocomplete
            solo
            :items="US_STATES_INVERTED"
            label="State"
            height="40"
            hide-details
            :value="state"
            @change="(e) => handleChange('state', e)"
          >
          </v-autocomplete>

          <v-text-field
            hide-details
            height="40"
            class="ml-6"
            label="Zip"
            placeholder="Zip"
            underlined
            autocomplete="home street-address"
            :value="zip"
            @change="(e) => handleChange('zip_code', e)"
          />
        </div>
      </v-col>

      <v-col cols="4">
        <v-autocomplete
          :items="COUNTRIES"
          hide-details
          height="40"
          label="Country"
          placeholder="Country"
          autocomplete="home street-address"
          :value="country"
          @change="(e) => handleChange('country', e)"
        >
          <template v-slot:prepend-inner>
            <v-select class="pa-0 ma-0 inner-select" height="40px" hide-details>
              <template v-slot:prepend-inner>
                <img :src="usIcon" class="pa-3 pr-0" />
              </template>

              <template v-slot:selection>yo</template>
            </v-select>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import usIcon from "@/assets/images/flags/us.png"
import { US_STATES_INVERTED, COUNTRIES } from "@/constants"

export default {
  name: 'ClientProfileEditor',
  props: {
    selected: { type: Object },
    isCPProfile: { type: Boolean },
  },
  emits: ['updateProfile', 'modificationStatusChange'],
  data() {
    return {
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      title: '',
      phoneNumber: '',
      streetAddress1: '',
      streetAddress2: '',
      zip: '',
      city: '',
      state: '',
      country: 'US',
      usIcon,
      US_STATES_INVERTED,
      COUNTRIES,
    }
  },
  mounted() {
    this.updateLocalTrackers(this.selected)
  },
  methods: {
    handleChange(prop, e) {
      // if the new value is different than the existing one, change it
      if (this.selected[prop] != e) {
        const profile_dataset = { ...this.selected }
        profile_dataset[prop] = e
        this.$emit('updateProfile', {
          as_cp_profile: this.isCPProfile,
          profile_id: this.selected['id'],
          profile_dataset
        })
      }
    },
    updateLocalTrackers(selectedProfile) {
      if (selectedProfile != null) {
        this.firstName = selectedProfile['first_name']
        this.lastName = selectedProfile['last_name']
        this.title = selectedProfile['title']
        this.phoneNumber = selectedProfile['phone_number']
        this.email = selectedProfile['poc_email']
        this.streetAddress1 = selectedProfile['street_address_1']
        this.streetAddress2 = selectedProfile['street_address_2']
        this.city = selectedProfile['city']
        this.state = selectedProfile['state']
        this.zip = selectedProfile['zip_code']
        this.country = selectedProfile['country'] || 'US'
      }
      else {
        this.firstName = null
        this.lastName = null
        this.title = null
        this.phoneNumber = null
        this.email = null
        this.streetAddress1 = null
        this.streetAddress2 = null
        this.city = null
        this.state = null
        this.zip = null
        this.country = 'US'
      }
    }
  },
  watch: {
    selected() {
      this.updateLocalTrackers(this.selected)
    }
  }
}
</script>

<style scoped>
p, h4 {
  color: #000000;
  letter-spacing: normal;
}

.showing-spinner {
  opacity: 0.5;
}

form .v-progress-circular {
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}

.v-btn:not(.border-none) {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

:deep(.v-input__prepend-inner) {
  margin: 0;
}

:deep(.inner-select .v-input__prepend-inner) {
  align-self: center;
  padding: 0;
}

:deep(.inner-select .v-input__append-inner) {
  padding: 0;
  margin: 4px 0 0 4px
}

:deep(.inner-select) {
  width: 68px;
  background: #F3F4F6;
  pointer-events: none;
  opacity: 0.5;
}

.v-autocomplete :deep(.v-text-field__details) {
  display: none;
}

.v-autocomplete :deep(.v-input__slot) {
  margin-bottom: 0;
}
</style>