import API from "./API"

const endpoint = "/contract-groups"

export const listOverview = ({ org_node_id, year }) => 
    API.get(`${endpoint}/list_overview?org_node_id=${org_node_id}&year=${year}`)

export const getContractTabMetadata = ({ group_ids }) =>
    API.post(`${endpoint}/get_contract_tab_metadata/`, { group_ids })

export const getFssMetadata = ({ fss_ids }) =>
    API.post(`${endpoint}/get_fss_metadata/`, { fss_ids })

export const getRollup = ({ org_node_id, year, group_id }) =>
    API.get(`${endpoint}/get_rollup/${group_id != null ? `?group_id=${group_id}&` : '?'}org_node_id=${org_node_id}&year=${year}`)

export const submitSupplyRequests = ({ fss_to_request, fss_to_available, group_id, crop_specific_snapshot }) =>
    API.post(`${endpoint}/submit_supply_requests/`, { fss_to_request, fss_to_available, group_id, crop_specific_snapshot })

export const applyPendingSupplyStates = ({ fss_ids, supplyState, group_id }) =>
    API.post(`${endpoint}/apply_pending_supply_states/`, { fss_ids, supplyState, group_id })

export const submitEvidencingLink = ({ group_id, updated_link_obj }) =>
    API.post(`${endpoint}/submit_evidencing_link/`, { group_id, updated_link_obj })

export const addProfileToGroup = ({ client_profile_id, org_node_id, group_id, year, as_cp_profile }) =>
    API.post(`${endpoint}/add_client_profile_to_group/`, { client_profile_id, org_node_id, group_id, year, as_cp_profile })

export const swapGroupProfile = ({ org_node_id, year, group_id, from_id, to_id, as_cp_profile }) =>
    API.post(`${endpoint}/swap_client_profile/`, { org_node_id, year, group_id, from_id, to_id, as_cp_profile })

export const removeProfileFromGroup = ({ org_node_id, year, group_id, remove_id, as_cp_profile }) =>
    API.post(`${endpoint}/remove_profile_from_group/`, { org_node_id, year, group_id, remove_id, as_cp_profile })

export const createProfile = ({ org_node_id, year, group_id, as_cp_profile, profile_dataset }) =>
    API.post(`${endpoint}/create_new_profile/`, { org_node_id, year, group_id, as_cp_profile, profile_dataset })

export const updateProfile = ({ org_node_id, year, group_id, profile_dataset, profile_id }) =>
    API.post(`${endpoint}/update_profile/`, { org_node_id, year, group_id, profile_dataset, profile_id })

export const submitConfirmRequests = ({ fss_to_confirm, group_id }) =>
    API.post(`${endpoint}/submit_confirm_requests/`, { fss_to_confirm, group_id })

export const fetchClientProfiles = () =>
    API.get("/client-profile/?get_parent_org=1")

export const fetchGroupClientProfiles = ({ group_id }) =>
    API.get(`/client-profile/?group_id=${group_id}`)
