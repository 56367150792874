<template>
  <div>
    <v-snackbar v-model="snackbarBulk" timeout="5000" color="green lighten-1">
      Your distribution code export has begun. Start checking the s3 bucket for
      updates in a few minutes.
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbarBulk = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbar" timeout="5000" color="green lighten-1">
      You will receive an email with a link to your program information shortly.
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="snackbarSave" timeout="2000" color="green lighten-1">
      Program Filter Saved!
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <ReviewAllocationModal
      v-if="showAllocationModal"
      :programID="currentOrderID"
      @close-modal="showAllocationModal = false"
    />
    <EditFieldAssignmentModal
      v-if="showFieldAssignmentModal"
      :updateOrderID="currentOrderID"
      :updateOrderName="currentOrderName"
      @close-modal="handleCloseModal"
      @save-from-modal="handleSaveModal"
      @open-allotment="handleOpenAllotmentModal"
    />
    <ConfirmInsetOrderModal
      v-if="showModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      :currentSupplyTarget="currentSupplyTarget"
      :currentAggregationMetric="currentAggregationMetric"
      :currentOrderPrice="currentOrderPrice"
      @close-modal="handleCloseModal"
      @save-from-modal="handleSaveModal"
    />

    <ConfirmInsetOrderModal
      v-if="showCloneModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :reviewOnly="reviewOnly"
      :currentSupplyTarget="currentSupplyTarget"
      :currentAggregationMetric="currentAggregationMetric"
      :currentOrderPrice="currentOrderPrice"
      :updateOrderID="currentOrderID"
      :updateOrderName="currentOrderName"
      @close-modal="handleCloseModal"
      @save-from-modal="handleSaveModalClone"
    />

    <ExecuteInsetOrderModal
      v-if="showExecuteModal"
      :filterParams="filterParams"
      :headers="headers"
      :items="items"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      @close-modal="showExecuteModal = false"
      @save-from-modal="handleExecuteModal"
    />

    <ConfirmDeleteInsetOrderModal
      v-if="currentOrderID && showDeleteModal"
      :currentOrderName="currentOrderName"
      :currentOrderID="currentOrderID"
      @close-modal="handleCloseDeleteModal"
      @confirm-delete-modal="handleConfirmDeleteModal"
    />
    <Permissions package="inset-enrollment" :superUserOnly="true" />

    <div v-if="showInsetEnrollment && isSuperuser" class="row reverse-margin">
      <div class="col scroll-col ml-0 pl-0" style="min-width: 575px">
        <!-- button container -->
        <div class="d-flex mb-3">
          <DashboardTitle
            title="Create and Manage EA Programs"
            faClass="fa-globe"
          />
        </div>

        <v-card class="mb-1 pt-2">
          <v-toolbar flat>
            <div v-if="tab == 0 || tab == 1">
              <div v-if="currentOrderName && tab == 0">
                <span>
                  <b>Viewing Subprogram: </b><br />
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        color="green lighten-1"
                        @click="handleShowReviewModal"
                      >
                        {{ orderShortText }} ({{ currentOrderID }})
                      </v-chip>
                    </template>
                    <span
                      >Click to View Subprogram Details for: <br />
                      {{ currentOrderName }}</span
                    >
                  </v-tooltip>
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mb-0"
                      icon
                      @click="handleDeselectOrder(1)"
                      ><v-icon color="red" small
                        >mdi-close-circle</v-icon
                      ></v-btn
                    >
                  </template>
                  <span
                    >Click to Deselect and Return to Full Subprogram List</span
                  >
                </v-tooltip>
              </div>
              <div v-if="currentOrderRollupName && tab == 1">
                <span>
                  <b>Viewing Rollup: </b>
                  <v-chip class="mt-0" color="orange lighten-1">
                    {{ rollupShortText }} (CO2e/ac:{{ insetOrderRollupCO2e }})
                  </v-chip>
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      @click="handleDeselectRollup(2)"
                      ><v-icon color="red" small
                        >mdi-close-circle</v-icon
                      ></v-btn
                    >
                  </template>
                  <span
                    >Click to Deselect and Return to Full Program Rollup
                    List</span
                  >
                </v-tooltip>
              </div>

              <div
                v-else-if="
                  currentOrderRollupName == null &&
                  currentOrderName == null &&
                  tab == 0
                "
              >
                <h4>
                  <span>
                    <v-chip color="primary">
                      Building New Program
                    </v-chip></span
                  >
                </h4>
              </div>
            </div>
            <div v-if="tab == 2">
              <v-chip
                v-if="EAListItems.length > 0"
                color="primary"
                class="float-left"
                @click="handleBulkExport"
              >
                Export All Supply Summary
                <v-icon>mdi-download</v-icon>
                <div
                  v-if="isZipExportLoading"
                  class="spinner-border spinner-border-sm text-light ml-1"
                  role="status"
                ></div>
              </v-chip>
            </div>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-auto"
                  small
                  @click="handleHideShowMap"
                  >{{ showMap ? "Hide Map " : "Show Map " }}
                  <v-icon v-if="showMap">mdi-map-minus</v-icon
                  ><v-icon v-else>mdi-map-plus</v-icon>
                </v-btn>
              </template>
              <span v-if="!showMap"
                >Click to Show Map and Reduce Table Attributes</span
              >
              <span v-else>Click to Hide Map and Expand Table Attributes</span>
            </v-tooltip>

            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
        </v-card>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabTitles" :key="item.index">
            <v-card flat v-if="item.name == 'Create/Modify'">
              <div v-if="loading" class="mt-3 d-flex justify-content-center">
                <div class="text-bold mb-0">Fetching Data</div>
                <div class="spinner-border ml-4" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-else>
                <v-expansion-panels focusable v-model="panel" :key="key">
                  <template v-for="filterSet in filterSets">
                    <InsetFilter
                      :filterParams="filterParams"
                      :title="filterSet.title"
                      :graphTitle="filterSet.graphTitle"
                      :previewParam="filterSet.previewParam"
                      :selectionType="filterSet.selectionType"
                      @matchSummary="handleMatchSummary"
                      @choroplethData="handleChoroplethData"
                      @filterParams="handleFilterParams"
                      @close="handleClose"
                      @save="saveFilter"
                    />
                  </template>

                  <v-expansion-panel v-if="items.length > 0">
                    <v-expansion-panel-header color="green lighten-5">
                      <b
                        >Current Selection Summary
                        <span
                          v-if="
                            Object.keys(filterParams.filter_params).length > 0
                          "
                        >
                          ({{
                            Object.keys(filterParams.filter_params).filter(
                              e => e != "max_price"
                            ).length
                          }}
                          Filters/Program Rules Selected)</span
                        ></b
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="supplySummaryHeaders"
                        :items="items"
                      ></v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-container>
                  <v-row class="justify-end">
                    <v-col cols="auto" class="text-right">
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="
                          currentOrderName &&
                          status != 'executed' &&
                          status != 'closed'
                        "
                        @click="handleShowModal"
                        >Review / Update
                      </v-btn>
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="
                          currentOrderName &&
                          (status == 'executed' || status == 'closed')
                        "
                        @click="handleShowFieldAssignmentModal"
                        >Edit Field Assignment</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="currentOrderName"
                        @click="handleShowCloneModal"
                        >Clone Program</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="currentOrderName"
                        @click="handleShowAllocationModal"
                        >Review/Edit CP Allotments</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="
                          currentOrderName &&
                          status != 'executed' &&
                          status != 'closed'
                        "
                        @click="handleShowExecuteModal"
                      >
                        Execute Order</v-btn
                      >

                      <v-btn
                        class="ml-5 mt-3"
                        v-else-if="
                          status != 'executed' &&
                          status != 'closed' &&
                          Object.keys(filterParams.filter_params).length > 0
                        "
                        @click="handleShowModal"
                        >Create New Program with Selected Rules</v-btn
                      >
                      <v-btn
                        class="ml-5 mt-3"
                        v-if="
                          status != 'executed' &&
                          status != 'closed' &&
                          Object.keys(filterParams.filter_params).length > 0
                        "
                        @click="handleDeselectOrder(0)"
                        >Clear all Filters<v-icon color="red" small
                          >mdi-close-circle</v-icon
                        ></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="currentOrderName && pctFilled > 0">
                    <v-col></v-col>
                    <v-col>
                      <v-btn class="mb-1 float-right" @click="handleExport">
                        <v-icon>mdi-download</v-icon>
                        Export Supply Summary
                        <div
                          v-if="isZipExportLoading"
                          class="spinner-border spinner-border-sm text-light ml-1"
                          role="status"
                        ></div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container
                  v-if="Object.keys(filterParams.filter_params).length == 0"
                >
                  <h4>
                    <b>Build a Program by Choosing Categorical Rules Above</b>
                  </h4></v-container
                >
              </div>
            </v-card>

            <v-card flat v-if="item.name == 'Subprograms'">
              <div
                v-if="loadingManage"
                class="mt-3 d-flex justify-content-center"
              >
                <div class="text-bold mb-0">Fetching Data</div>
                <div class="spinner-border ml-4" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <v-layout
                v-if="EAListItems.length > 0"
                column
                style="height: 65vh"
              >
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="EAListHeaders"
                    :items="EAListItemsFiltered"
                    :search="searchOrder"
                    :sort-by="['id']"
                    :sort-desc="true"
                    :items-per-page="200"
                    height="45vh"
                    fixed-header
                    fixed-footer
                    v-if="!loadingManage"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="searchOrder"
                        label="Filter"
                      ></v-text-field>
                    </template>

                    <template slot="item.params_vlist" slot-scope="props">
                      <ul v-for="subItem in props.item.params_vlist">
                        <li>{{ subItem.title }}</li>
                      </ul>
                    </template>

                    <template slot="item.is_locked" slot-scope="props">
                      <v-switch
                        @change="handleEAOrderLock(props.item)"
                        color="blue"
                        inset
                        v-model="props.item.is_locked"
                      >
                        Locked : {{ props.item.is_locked }}
                      </v-switch>
                    </template>

                    <template slot="item.name" slot-scope="props">
                      <span
                        class="hover blue--text font-weight-bold"
                        @click="handleEAOrderSelect(props.item)"
                        style="max-width: 100px"
                      >
                        {{ props.item.name.replace(/_/g, " ") }}
                        <v-icon color="blue">
                          mdi-arrow-top-right-bold-box-outline
                        </v-icon>
                      </span>
                    </template>

                    <template slot="item.status" slot-scope="props">
                      <v-chip color="transparent"
                        >{{ props.item.status | capitalize }}
                        <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              v-if="
                                props.item.status != 'closed' &&
                                props.item.is_locked == false
                              "
                              class="ml-2"
                              color="error"
                              @click="handleOpenDeletePlanModal(props.item)"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click to Delete Program</span>
                        </v-tooltip>
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-card>
            <v-card flat v-if="item.name == 'Rollups'">
              <div
                v-if="loadingManage"
                class="mt-3 d-flex justify-content-center"
              >
                <div class="text-bold mb-0">Fetching Data</div>
                <div class="spinner-border ml-4" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <v-layout column style="height: 65vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    :headers="EARollupHeaders"
                    :items="EARollupItems"
                    :search="searchOrderRollup"
                    :sort-by="['id']"
                    :sort-desc="true"
                    :items-per-page="200"
                    height="45vh"
                    fixed-header
                    fixed-footer
                    v-if="!loadingManage"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="searchOrderRollup"
                        label="Filter"
                      ></v-text-field>
                    </template>

                    <template slot="item.name" slot-scope="props">
                      <span
                        class="hover blue--text font-weight-bold"
                        @click="handleEARollupSelect(props.item)"
                        style="max-width: 100px"
                      >
                        {{ props.item.name.replace(/_/g, " ") }}
                        <v-icon color="blue">
                          mdi-arrow-top-right-bold-box-outline
                        </v-icon>
                      </span>
                    </template>
                    <template slot="item.subprogram_count" slot-scope="props">
                      <span
                        class="hover blue--text font-weight-bold"
                        @click="handleEARollupSelect(props.item)"
                        style="max-width: 100px"
                      >
                        {{ props.item.subprogram_count }}
                      </span>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <transition name="slide" v-if="mapShowCounter > 0">
        <div v-show="showMap" class="col map-col">
          <div class="map-wrapper" oncontextmenu="return false">
            <LeafletMap
              :fields="[]"
              :isYield="isYield"
              :zoneGeoJSON="zoneGeoJSON"
              :mapParams="mapParams"
              :mapStart="mapStart"
              :editing="editing"
              @drawCreated="drawCreated"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import LeafletMap from "@/components/map/LeafletMap"
import DashboardTitle from "@/components/dashboard/DashboardTitle"

import InsetFilter from "@/components/insets/filters/GenericInsetFilter"
import ConfirmInsetOrderModal from "@/components/insets/modals/ConfirmOrderModal"
import ExecuteInsetOrderModal from "@/components/insets/modals/ExecuteInsetOrderModal"

import ConfirmDeleteInsetOrderModal from "@/components/insets/modals/ConfirmInsetDeleteModal"
import ReviewAllocationModal from "@/components/insets/modals/ReviewAllocationByProgramNewModal"
import EditFieldAssignmentModal from "@/components/insets/modals/EditFieldAssignmentModal"

import Permissions from "@/components/permissions/Permissions"
import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import { pickBy } from "lodash"
import { limitTextLength } from "@/utility"

export default {
  name: "BuyerInsetFilterView",
  components: {
    LeafletMap,
    DashboardTitle,
    Permissions,
    ConfirmInsetOrderModal,
    ConfirmDeleteInsetOrderModal,
    ExecuteInsetOrderModal,
    ReviewAllocationModal,
    EditFieldAssignmentModal,
    InsetFilter,
  },
  data() {
    return {
      tab: 0,
      snackbar: false,
      snackbarSave: false,
      snackbarBulk: false,
      tabTitles: [
        { index: 0, name: "Create/Modify" },
        { index: 1, name: "Subprograms" },
        { index: 2, name: "Rollups" },
      ],
      currentOrderRollupID: null,
      currentOrderRollupName: null,
      insetOrderRollupCO2e: null,
      currentOrderID: null,
      currentOrderName: null,
      currentOrderPrice: null,
      currentSupplyTarget: null,
      currentAggregationMetric: null,

      status: "open",
      key: 0,
      showExecuteModal: false,

      searchOrder: "",
      searchOrderRollup: "",
      zoneGeoJSON: null,
      isYield: false,
      loading: false,
      loadingManage: false,
      showMap: true,
      mapShowCounter: 1,
      mapStart: null,
      pctFilled: 0,
      reviewOnly: false,
      filterParams: { filter_params: {} }, // "year": [2023], "crop_id": [4] }
      panel: -1,
      items: [],
      headers: [
        { text: "Category", value: "category" },
        { text: "Value", value: "value" },
      ],
      supplySummaryHeaders: [
        /*{ text: "Category", value: "category" },
        { text: "Value", value: "value" },*/
        { text: "Category", value: "Metric" },
        { text: "Supply Shed", value: "Supply Shed" },
        { text: "Allocatable", value: "Available to Allocate" },
        { text: "In Program", value: "In Current Program" },
      ],
      colorLUT: {},
      showModal: false,
      showCloneModal: false,
      showDeleteModal: false,
      showAllocationModal: false,
      showFieldAssignmentModal: false,
      EAListItems: [],
      EARollupItems: [],
      editing: false,
      isZipExportLoading: false,
      EAListHeaders: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },

        { text: "% Allocated", value: "pct_filled_and_signed" },
        { text: "% Allocatable", value: "pct_filled" },

        { text: "Status", value: "status" },
      ],
      EARollupHeaders: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "# Subprograms", value: "subprogram_count" },
        { text: "Ac Allocated", value: "acres_allocated" },
        { text: "Average CO2e/Ac", value: "average_co2e" },
        //{ text: "Target CO2e/Ac", value: " targetCO2e" },
      ],

      filterSets: [
        {
          title: "Filter by Crop",
          graphTitle: "Crops: ",
          previewParam: "crop_id",
          selectionType: "select",
        },
        {
          title: "Filter by Year",
          graphTitle: "Years: ",
          previewParam: "year",
          selectionType: "select",
        },
        {
          title: "Filter by Region",
          graphTitle: "Counties: ",
          previewParam: "geoids",
          selectionType: "select",
        },
        {
          title: "Filter by Harvest Type",
          graphTitle: "Harvest Type: ",
          previewParam: "harvest_type",
          selectionType: "select",
        },
        /*{
          title: "Filter by DAS",
          graphTitle: "Data Assurance Scores: ",
          previewParam: "min_das",
          selectionType: "slider",
        },*/
        /*{
          title: "Filter by Price",
          graphTitle: "Prices: ",
          previewParam: "max_price",
          selectionType: "slider",
        },*/
        {
          title: "Filter by Available Practice",
          graphTitle: "Regenerative Practices: ",
          previewParam: "practices",
          selectionType: "select",
        },
        {
          title: "Filter by Supplier Org",
          graphTitle: "Supplier Organizations: ",
          previewParam: "org_nodes",
          selectionType: "select",
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      user: state => state.User.user,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      isSuperuser: state => state.User.user.is_superuser,
    }),

    EAListItemsFiltered() {
      if (this.currentOrderRollupID) {
        return this.EAListItems.filter(e => {
          return e.program_rollup == this.currentOrderRollupID
        })
      } else {
        return this.EAListItems
      }
    },
    mapParams() {
      let tempParams = {}
      tempParams["order_id"] = -99
      tempParams["category"] = "insets"

      tempParams["useMap"] = true
      tempParams["useInset"] = true
      tempParams["colorLUT"] = this.colorLUT
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },
    orderShortText() {
      if (this.showMap) {
        return limitTextLength(this.currentOrderName, 30)
      } else {
        return limitTextLength(this.currentOrderName, 75)
      }
    },
    rollupShortText() {
      if (this.showMap) {
        return limitTextLength(this.currentOrderRollupName, 25)
      } else {
        return limitTextLength(this.currentOrderRollupName, 75)
      }
    },
  },

  methods: {
    handleEAOrderLock(e) {
      let payload = { order_id: e.id, lock_only: true, is_locked: e.is_locked }

      CarbonReadyAPI.updateInsetOrder(payload)
    },
    saveFilter() {
      if (this.currentOrderName && this.status == "open") {
        let payload = {
          filter_params: this.filterParams["filter_params"],
          name: this.currentOrderName,
          price: this.currentOrderPrice,
          geoids: this.filterParams["filter_params"]["geoids"],
          order_id: this.currentOrderID,
          status: this.status,
        }
        payload["filter_params"]["supply_target"] = {
          type: this.currentAggregationMetric,
          value: this.currentSupplyTarget,
        }

        if ("max_price" in payload["filter_params"]) {
          payload["filter_params"] = Object.assign(payload["filter_params"], {
            price: payload["filter_params"]["max_price"],
          })
          delete payload["filter_params"]["max_price"]
        }

        CarbonReadyAPI.updateInsetOrder(payload).then(response => {
          this.snackbarSave = true
        })
      } else {
        this.snackbarSave = true
      }
    },
    handleHideShowMap() {
      this.showMap = !this.showMap
      this.mapShowCounter = this.mapShowCounter + 1
      let savedColorLUT = this.colorLUT
      this.colorLUT = {}
      this.colorLUT = savedColorLUT
    },

    handleMatchSummary(resp) {
      this.items = resp

      /*
      this.items = []
      let keyLookup = {
        scope3_emission: "Estimated CO2e Savings",
        harvest: "Estimated Harvest Amount",
        fields: "# Fields",
        acreage: "Acreage",
      }
      for (const [key, value] of Object.entries(resp)) {
        let val = value
        if (val != undefined) {
          val = val.toLocaleString()
        } else {
          val = 0
        }
        this.items.push({
          category: keyLookup[key],
          value: val,
        })
      }*/
    },

    handleChoroplethData(resp) {
      this.colorLUT = {}
      resp.forEach(x => {
        let key = String(x[0])
        this.colorLUT[key] = { value: x[3], label: x[1], supply: x[2] }
      })

      this.mapShowCounter += 1
    },
    handleFilterParams(resp) {
      this.filterParams = resp
    },
    handleClose() {
      this.panel = -1
    },

    handleCloseModal() {
      this.showModal = false
      this.showCloneModal = false
      this.reviewOnly = false
      this.showFieldAssignmentModal = false
    },
    handleOpenAllotmentModal() {
      this.showAllocationModal = true
      this.showFieldAssignmentModal = false
    },

    buildVList(json) {
      let items = []
      for (let key in json) {
        if (key == "price") {
          //skipping for now
          continue
        }
        /*items.push({ type: "subheader", title: key })*/
        let value = json[key]
        if (Array.isArray(value)) {
          //for (let arrayValueIdx in value) {
          let allVals = []
          for (let thisVal in value) {
            let tempSplit = String(value[thisVal]).split(":")
            tempSplit = tempSplit[tempSplit.length - 1]
            allVals.push(tempSplit)
          }

          items.push({ title: key + ": " + allVals.join(", ") })
          //}
        } else if (typeof value === "object") {
          items.push({ title: key + ": " + JSON.stringify(value) })
        } else {
          items.push({ title: key + ": " + value })
        }
      }

      return items
    },

    handleSaveModal() {
      this.showModal = false
      this.showCloneModal = false
      this.reviewOnly = false
      this.loadingManage = true
      this.showFieldAssignmentModal = false

      CarbonReadyAPI.getInsetOrders().then(response => {
        this.loadingManage = false
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"], null, "\t")
        }) //add % filled to EAListItems
        this.tab = 1
      })
    },

    handleSaveModalClone() {
      this.showModal = false
      this.showCloneModal = false
      this.reviewOnly = false
      this.loadingManage = true
      this.showFieldAssignmentModal = false
      this.currentOrderID = null
      this.currentOrderName = null
      this.tab = 1
      CarbonReadyAPI.getInsetOrders().then(response => {
        this.loadingManage = false
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"], null, "\t")
        }) //add % filled to EAListItems
        this.tab = 1
      })
    },
    handleShowModal() {
      this.showModal = true
    },
    handleShowCloneModal() {
      this.showCloneModal = true
    },
    handleShowReviewModal() {
      this.reviewOnly = true
      this.showCloneModal = true
    },
    handleShowFieldAssignmentModal() {
      this.showFieldAssignmentModal = true
    },
    handleShowAllocationModal() {
      this.showAllocationModal = true
    },
    handleShowExecuteModal() {
      this.showExecuteModal = true
    },

    handleDeselectOrder(checkTab) {
      this.currentOrderID = null
      this.currentOrderName = null
      this.mapStart = null

      if (this.currentOrderRollupID) {
        this.filterParams["rollup_id"] = this.currentOrderRollupID
      } else {
        this.filterParams = { filter_params: {} }
      }

      this.status = null
      this.key = this.key + 1
      this.tab = checkTab
    },

    handleDeselectRollup(checkTab) {
      this.currentOrderID = null
      this.currentOrderName = null
      this.currentOrderRollupID = null
      this.currentOrderRollupName = null
      this.mapStart = null
      this.filterParams = { filter_params: {} }
      this.status = null
      this.key = this.key + 1
      this.tab = checkTab
    },

    handleOpenDeletePlanModal(e) {
      this.currentOrderID = e.id
      this.currentOrderName = e.name
      this.showDeleteModal = true
    },
    handleCloseDeleteModal() {
      this.currentOrderID = null
      this.currentOrderName = null
      this.showDeleteModal = false
    },

    handleConfirmDeleteModal() {
      this.currentOrderID = null
      this.currentOrderName = null
      this.showDeleteModal = false
      CarbonReadyAPI.getInsetOrders().then(response => {
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"], null, "\t")
        }) //add % filled to EAListItems
      })
    },

    handleExecuteModal() {
      this.showExecuteModal = false
      this.status = "executed"

      CarbonReadyAPI.getInsetOrders().then(response => {
        this.loadingManage = false
        this.EAListItems = response.data
        this.EAListItems.forEach(x => {
          x["params_json"] = JSON.stringify(x["params"], null, "\t")
        }) //add % filled to EAListItems
        this.tab = 1
      })
    },
    drawCreated(geojson) {
      this.filterParams["geojson"] = geojson
      this.key = this.key + 1
    },

    async handleExport() {
      this.isZipExportLoading = true
      if (this.currentOrderID == null) {
        this.isZipExportLoading = false
        return
      }

      const payload = {
        eao_id: this.currentOrderID,
      }
      await CarbonReadyAPI.fetchProgramExport(payload).then(response => {
        this.isZipExportLoading = false
        if (response.status == 200) {
          this.snackbar = true
        }
      })
    },

    async handleBulkExport() {
      this.isZipExportLoading = true

      const payload = {
        eao_id: this.EAListItems.map(x => x.id),
      }

      await CarbonReadyAPI.fetchProgramExport(payload).then(response => {
        this.isZipExportLoading = false
        if (response.status == 200) {
          this.snackbarBulk = true
        }
      })
    },

    handleEARollupSelect(e) {
      //this.searchOrder = e.name

      this.currentOrderRollupID = e.id
      this.currentOrderRollupName = e.name
      this.insetOrderRollupCO2e = e.average_co2e.toFixed(2)

      this.currentOrderID = null
      this.currentOrderName = null

      this.loading = true
      this.filterParams["rollup_id"] = e.id
      let payload = {
        rollup_id: e.id,
        preview_param: "year",
        aggregation_metric: "acreage",
      }
      CarbonReadyAPI.postInsetFilter(payload).then(response => {
        //let choroplethData = response.data["chloropleth_data"]
        //this.handleMatchSummary(response.data["match_summary"])
        //this.handleChoroplethData(response.data["chloropleth_data"])

        //this.showMap
        this.loading = false
        this.mapStart = {}
        this.mapStart["geojson"] = response.data["bounding_geojson"]
      })
      this.tab = 1
      this.mapStart = null
    },

    async handleEAOrderSelect(e) {
      this.tab = 0

      this.currentOrderID = e.id
      this.currentOrderName = e.name
      this.currentOrderPrice = e.params.price
      this.pctFilled = e.pct_filled

      if ("supply_target" in e.params) {
        this.currentSupplyTarget = e.params.supply_target.value
        this.currentAggregationMetric = e.params.supply_target.type
      }
      this.status = e.status

      this.filterParams["rollup_id"] = null
      this.filterParams["filter_params"]["geoids"] = e.aoi.map(x => x.geoid)
      this.filterParams["filter_params"]["org_nodes"] = e.org_nodes

      this.filterParams["filter_params"]["practices"] = e.params.practices
      this.filterParams["filter_params"]["min_das"] = e.params.das
      this.filterParams["filter_params"]["max_price"] = e.params.price
      this.filterParams["filter_params"]["year"] = e.params.year
      this.filterParams["filter_params"]["crop_id"] = e.params.crop_id
      this.filterParams["filter_params"]["harvest_type"] = e.params.harvest_type

      this.filterParams["preview_param"] = "crop_id"
      this.filterParams["aggregation_metric"] = "acreage" //change this in above view later

      this.filterParams["filter_params"] = pickBy(
        this.filterParams["filter_params"],
        v => v !== undefined
      )

      let payload = this.filterParams
      this.loading = true

      payload["program_id"] = this.currentOrderID

      CarbonReadyAPI.postInsetFilter(payload).then(response => {
        this.loading = false
        this.panel = -1

        this.handleMatchSummary(response.data["match_summary"])
        this.mapStart = {}
        this.mapStart["geojson"] = response.data["bounding_geojson"]

        //this.showMap = false
      })
    },
  },

  watch: {
    showMap() {
      if (this.showMap) {
        this.EAListHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },

          { text: "% Allocated", value: "pct_filled_and_signed" },
          { text: "% Allocatable", value: "pct_filled" },
          { text: "Status", value: "status" },
        ]
        this.EARollupHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "# Subprograms", value: "subprogram_count" },
          { text: "Ac Allocated", value: "acres_allocated" },
          //{ text: "Ac Desired", value: "acres_allotted" },
          { text: "Average CO2e/Ac", value: "average_co2e" },
          //{ text: "Target Co2e/Ac", value: " targetCO2e" },
        ]
      } else {
        this.EAListHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          /*{ text: "Filters", value: "params_json" },*/

          { text: "% Allocated", value: "pct_filled_and_signed" },
          { text: "% Allocatable", value: "pct_filled" },
          {
            text: "% Allocatable From Requested",
            value: "pct_filled_and_requested",
          },
          { text: "Supply Allocated", value: "allocated_acres" },
          { text: "Average Allocated CO2e/Ac", value: "average_co2e" },
          {
            text: "Max CO2e/ac Potential in Current Supply Shed",
            value: "best_co2e",
          },
          {
            text: "Supply Type",
            value: "program_supply_type",
          },
          //{ text: "Filters", value: "params_vlist" },
          { text: "Status", value: "status" },
          { text: "Locked", value: "is_locked" },
        ]
        this.EARollupHeaders = [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "# Subprograms", value: "subprogram_count" },
          { text: "Ac Allocated", value: "acres_allocated" },
          { text: "CO2e Allocated", value: "co2e_allocated" },

          { text: "Alloted", value: "acres_allotted" },

          { text: "Average CO2e/Ac", value: "average_co2e" },
          { text: "Target CO2e/Ac", value: "targetCO2e" },
        ]
      }
    },
    panel: function () {
      if (this.panel == 2) {
        this.editing = true
      } else {
        this.editing = false
      }
    },
    tab: function () {
      if (this.tab == 1 || this.tab == 2) {
        //do nothing right now
        //this.showMap = false
      }
    },
  },

  mounted() {
    this.loadingManage = true
    CarbonReadyAPI.getInsetOrders().then(response => {
      this.EAListItems = response.data
      //this.loadingManage = false
      this.EAListItems.forEach(x => {
        x["params_json"] = JSON.stringify(x["params"], null, "\t")
        x["params_vlist"] = this.buildVList(x["params"])
        x["allocated_acres"] = (
          (x["params"]["supply_target"]["value"] * x["pct_filled_and_signed"]) /
          100
        ).toFixed(0)
      }) //add % filled to EAListItems
      CarbonReadyAPI.getInsetOrderRollups().then(response => {
        this.EARollupItems = response.data

        this.EARollupItems.forEach(x => {
          if (x["params"].hasOwnProperty("target_co2e")) {
            x["targetCO2e"] = x["params"]["target_co2e"]
          } else {
            x["targetCO2e"] = null
          }
          x["co2e_allocated"] = x["co2e_allocated"].toLocaleString()

          x["acres_allocated"] = x["acres_allocated"].toLocaleString()
          x["acres_allotted"] = x["acres_allotted"].toLocaleString()
        })

        this.loadingManage = false
      })
    })
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}

.map-col {
  padding-left: 0px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info {
  color: #666666;
}

.thirty {
  width: 30%;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
</style>
