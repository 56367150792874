<template>
  <div>
    <Permissions v-if="!isInset" package="sustainability" />
    <Permissions
      package="inset-enrollment"
      :superUserOnly="true"
      v-if="isInset"
    />

    <div
      v-if="
        (showInsetEnrollment && isInset && isSuperUser) ||
        (!isInset && showSustainability)
      "
      class="row reverse-margin"
    >
      <ConfirmEnrollmentModal
        v-if="confirmEnrollModalOpen && !isInset"
        :enrollment="selectedEnrollment"
        :activeFields="activeFields"
        @close-template-modal="closeConfirmEnrollModal"
      />

      <DeleteSoilPlanModal
        v-if="deleteEnrollmentModalOpen"
        titleText="Delete this Enrollment?"
        :planName="enrollmentToDelete.name"
        @close-modal="closeDeleteEnrollmentModal"
        @confirm-delete="handleConfirmDeleteEnrollment"
      />
      <div class="col scroll-col">
        <b-alert v-model="showErrorAlert" variant="danger" dismissible>
          <div>
            {{ message }}
          </div>
        </b-alert>

        <div v-if="!showMap" class="mb-4">
          <CarbonFlowSteppers v-if="!isInset" :step="4" />
        </div>

        <!-- button container -->

        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <span class="sel-enroll">
                <div v-if="selectedEnrollment">
                  <span>
                    Viewing:
                    <v-chip color="green lighten-1">
                      {{ selectedEnrollment.name }}
                    </v-chip>
                  </span>
                  <v-btn icon @click="handleDeselectEnrollment"
                    ><v-icon color="red" small>mdi-close-circle</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <span>
                    <h4>
                      <v-icon>mdi-view-dashboard</v-icon> Allocate Fields To EA
                      Programs
                    </h4>
                  </span>
                </div>
                <!--h2 class="ml-2 text-bold"-->
                <!--<h3 class="ml-2 text-bold" v-else>Manage Carbon Enrollments</h3>-->
              </span></v-toolbar-title
            >
            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="green"></v-tabs-slider>
                <v-tab
                  v-for="(item, idx) in tabTitles"
                  :key="item.name + '-' + idx"
                >
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>

            <v-btn v-if="tab == 2" class="ml-auto" @click="handleHideShowMap"
              >{{ showMap ? "Hide Map" : "Show Map" }}
              <v-icon v-if="showMap">mdi-map-minus</v-icon
              ><v-icon v-else>mdi-map-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(item, idx) in tabTitles"
              :key="item.name + '--' + idx"
            >
              <v-card flat>
                <div
                  v-if="
                    (item.name === 'Grower Enrollments' && isInset) ||
                    (item.name === 'Enrollments' && !isInset)
                  "
                  class="py-3"
                >
                  <div class="px-3">
                    <v-expansion-panels v-model="panel" multiple v-if="isInset">
                      <v-expansion-panel v-model="panel">
                        <v-expansion-panel-header class="expansion-header">
                          Current Allocation Pool Summary
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <AllocationVisualizer />
                          <AllocationTable
                            filter_type="corporation"
                            @fieldIdList="handleProgramFieldSubSelection"
                            @fss-id-list="handleProgramFSSSubSelection"
                            :key="allocationKey"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-card class="pa-0 mt-3">
                      <div v-if="filteredSortedEnrollmentsVDataTable != null">
                        <v-text-field
                          v-model="searchEnrollment"
                          label="Search"
                          prepend-inner-icon="mdi-magnify"
                          variant="outlined"
                          hide-details
                          single-line
                        ></v-text-field>
                        <v-data-table
                          :headers="enrollmentHeaders"
                          :items="filteredSortedEnrollmentsVDataTableFinal"
                          :search="searchEnrollment"
                          :sort-by="['id']"
                          :items-per-page="25"
                          :sort-desc="true"
                        >
                          <template v-slot:header="{ header }">
                            <VDataTablesMultiSelectHeader
                              :items="filteredSortedEnrollmentsVDataTable"
                              :headers="enrollmentHeaders"
                              :filters="filters"
                              :showIcon="false"
                              @filtered-data="filteredData"
                            />
                          </template>

                          <template slot="item.name" slot-scope="props">
                            <v-tooltip right>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <span
                                    v-if="
                                      props.item.status == 'approved' ||
                                      props.item.status == 'confirmed' ||
                                      props.item.status == 'in review'
                                    "
                                    class="hover"
                                    @click="handleEnrollClicked(props.item)"
                                    >{{ props.item.name }}
                                    <v-icon color="blue">
                                      mdi-arrow-top-right-bold-box-outline
                                    </v-icon></span
                                  >

                                  <span
                                    v-else
                                    :class="rowColor(props.item)"
                                    @click="handleEnrollClicked(props.item)"
                                    >{{ props.item.name }}
                                    <v-icon color="blue">
                                      mdi-arrow-top-right-bold-box-outline
                                    </v-icon>
                                  </span>
                                </div>
                              </template>
                              <span v-html="toolTipData(props.item)"></span>
                            </v-tooltip>
                          </template>

                          <template
                            slot="item.loi_entity_name"
                            slot-scope="props"
                          >
                            <v-tooltip top opacity="0.9" open-delay="500">
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <span
                                    v-if="
                                      props.item.status == 'approved' ||
                                      props.item.status == 'confirmed' ||
                                      props.item.status == 'in review' ||
                                      props.item.status == 'contract generated'
                                    "
                                    class="hover"
                                    @click="handleEnrollClicked(props.item)"
                                    >{{ props.item.loi_entity_name }}
                                    <v-icon color="blue">
                                      mdi-arrow-top-right-bold-box-outline
                                    </v-icon></span
                                  >

                                  <span
                                    v-else
                                    :class="rowColor(props.item)"
                                    class="hover"
                                    @click="handleEnrollClicked(props.item)"
                                    >{{ props.item.loi_entity_name }}
                                    <v-icon small :color="rowColor(props.item)">
                                      mdi-arrow-expand-right
                                    </v-icon>
                                  </span>
                                </div>
                              </template>
                              <span v-html="toolTipData(props.item)"></span>
                            </v-tooltip>
                          </template>

                          <template slot="item.status" slot-scope="props">
                            <v-btn
                              v-if="
                                props.item.status == 'requested' &&
                                canApprove &&
                                !isInset
                              "
                              color="dark green lighten-1"
                              small
                              @click="handleConfirm(props.item)"
                              >Approve Enrollment</v-btn
                            >
                            <v-btn
                              v-else-if="
                                props.item.status == 'requested' &&
                                canApprove &&
                                isInset
                              "
                              color="dark green lighten-1"
                              small
                              @click="handleConfirm(props.item)"
                              >Ready to Allocate</v-btn
                            >
                            <!--<span
                              class="text-bold"
                              :class="rowColor(props.item)"
                              v-else-if="
                                (props.item.status == 'approved' ||
                                  props.item.status == 'confirmed') &&
                                isInset
                              "
                              >In Program Review</span
                            >-->
                            <span :class="rowColor(props.item)" v-else>{{
                              props.item.status | capitalize
                            }}</span>
                            <i
                              v-if="
                                props.item.status == 'requested' ||
                                props.item.status == 'in review' ||
                                (props.item.status == 'approved' &&
                                  isCustomerAdmin)
                              "
                              class="pt-2 fa fa-times-circle float-right"
                              @click="cancelEnrollment(props.item)"
                            ></i>
                          </template>
                        </v-data-table>
                        <!--<v-toolbar>
                          <v-btn @click="refreshLOIMatches"
                            >Refresh LOI -> Program Match Groups</v-btn
                          >
                          <v-spacer></v-spacer>

                          <v-switch
                            :label="`Show Pending Enrollment Groups: ${showAllGroups}`"
                            class="mt-3"
                            v-model="showAllGroups"
                          ></v-switch>
                        </v-toolbar>-->
                      </div>
                      <div v-else>
                        <h3 class="ma-3">
                          Add Fields to Programs in Order to See and Manage
                          Those Enrollment Groups Here
                        </h3>
                      </div>
                    </v-card>
                  </div>
                </div>
                <div v-if="item.name === 'Fields' && !isInset" class="pt-3">
                  <div class="d-flex">
                    <v-btn class="ml-auto mr-4" @click="viewCreatePlan">
                      Create New Plan
                      {{
                        selectedEnrollment
                          ? `for ${selectedEnrollment.name}`
                          : "without Enrollment"
                      }}
                    </v-btn>
                  </div>

                  <FieldImageryListView
                    :canAccess="true"
                    :soilSamplingView="true"
                    :tabView="true"
                    :zonesView="true"
                    @row-clicked="handleFieldListClick"
                  />
                </div>

                <div v-if="item.name === 'Fields' && isInset">
                  <v-expansion-panels v-model="panel" multiple v-if="isInset">
                    <v-expansion-panel v-model="panel">
                      <v-expansion-panel-header class="expansion-header">
                        Current Allocation Pool Summary
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <AllocationVisualizer />
                        <AllocationTable
                          filter_type="corporation"
                          @field-id-list="handleProgramFieldSubSelection"
                          @fss-id-list="handleProgramFSSSubSelection"
                          @refresh-allocation="refreshFieldCropManagement += 1"
                          :key="allocationKey"
                        />
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-card class="mt-3">
                    <FieldCropProgramManagement
                      :activeFields="activeFields"
                      @refreshAllocation="refreshAllocationViewer"
                      :targetFSSIds="targetFSSIds"
                      :key="refreshFieldCropManagement"
                    />
                  </v-card>
                </div>

                <div
                  v-if="item.name == 'Program Allotments' && isInset"
                  class="pt-3"
                >
                  <v-card>
                    <AllocationTable
                      filter_type="corporation"
                      @field-id-list="handleProgramFieldSubSelection"
                      @fss-id-list="handleProgramFSSSubSelection"
                      @refresh-allocation="refreshFieldCropManagement += 1"
                      :key="allocationKey"
                    />
                  </v-card>
                </div>
                <div v-if="item.name === 'Sampling Plans'" class="pt-3">
                  <div v-if="hideSwitchBool == false" class="d-flex px-3 mb-4">
                    <v-tooltip top max-width="300" nudge-right="100"
                      ><template v-slot:activator="{ on, attrs }"
                        ><v-icon v-bind="attrs" v-on="on"
                          >mdi-information</v-icon
                        ></template
                      ><span
                        >This will show sampling plans by field if your
                        organization made sampling plans before Arva's
                        Enrollment update.</span
                      ></v-tooltip
                    >
                    <v-switch
                      class="ml-3"
                      inset
                      :color="
                        showPlansByEnrollment
                          ? 'purple lighten-4'
                          : 'blue lighten-4'
                      "
                      v-model="showPlansByEnrollment"
                      :label="
                        showPlansByEnrollment
                          ? 'Switch to View All Sampling Plans in Current Field Selection'
                          : 'Switch to View Only Plans With Current Carbon Enrollment'
                      "
                    >
                      ></v-switch
                    >
                  </div>
                  <!-- yield zone controller -->
                  <div class="pb-3">
                    <EnrollController
                      :eligibleCollectors="eligibleCollectors"
                      :eligibleLabs="eligibleLabs"
                      :activeFields="activeFields"
                      :tab="tab"
                      :samplingPlans="samplingPlans"
                      :samplingControllerView="samplingControllerView"
                      :finishedLoading="finishedLoading"
                      :selectedEnrollment="selectedEnrollment"
                      :showPlansByEnrollment="showPlansByEnrollment"
                      @create-plan-for-enroll="handleCreateEnrollPlan"
                      @select-fields="toggleZoneFields"
                      @set-view-list="setControllerView"
                      @update-list="fetchSamplingPlans"
                      @update-zones="updateZoneGeoJSON"
                      @reset-zones="resetZoneGeoJSON"
                      @update-map-params="updateMapParams"
                      @hide-switch="hideSwitch"
                      @map-zoom="mapZoom"
                    />
                  </div>
                </div>
                <div v-if="item.name === 'Control Center'" class="pt-3">
                  <div class="text-center my-3">
                    <CarbonEnrollmentControlCenter
                      :selectedEnrollment="selectedEnrollment"
                      :key="control_center_update_key"
                    />
                  </div>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <transition v-if="mapShowCounter > 0" name="slide">
        <div v-show="showMap" class="col map-col">
          <div class="map-wrapper" oncontextmenu="return false">
            <LeafletMap
              :boundaries="fieldBoundaries"
              :fields="fields"
              :isYield="isYield"
              :zoneGeoJSON="zoneGeoJSON"
              :mapParams="mapParams"
              :mapStart="mapStart"
            />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import LeafletMap from "@/components/map/LeafletMap"

import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import SamplingAPI from "@/api/SamplingAPI"
// import CarbonReadyFieldRow from "@/components/carbonReadyField/CarbonReadyFieldRow"
import FieldImageryListView from "@/views/FieldImageryListView"
import EnrollController from "@/components/sampling/EnrollController"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import ConfirmEnrollmentModal from "@/components/modals/ConfirmEnrollmentModal"

import DeleteSoilPlanModal from "@/components/modals/DeleteSoilPlanModal"
import CarbonEnrollmentControlCenter from "@/components/sustainability/CarbonEnrollmentControlCenter"
import AllocationVisualizer from "@/components/insets/AllocationVisualizationController"
import AllocationTable from "@/components/insets/AllocationTable"

import { Enrollment, Fields, Filter, SoilSampling } from "@/store/modules"
import Permissions from "@/components/permissions/Permissions"
import FieldCropProgramManagement from "@/components/insets/FieldCropProgramManagement"
import VDataTablesMultiSelectHeader from "@/components/misc/VDataTablesMultiSelectHeader"

export default {
  name: "EnrollmentManagement",
  props: ["isYield"],
  components: {
    // CarbonReadyFieldRow,
    FieldImageryListView,
    EnrollController,
    LeafletMap,
    CarbonFlowSteppers,
    ConfirmEnrollmentModal,
    DeleteSoilPlanModal,
    CarbonEnrollmentControlCenter,
    Permissions,
    AllocationVisualizer,
    AllocationTable,
    FieldCropProgramManagement,
    VDataTablesMultiSelectHeader,
  },
  data() {
    return {
      tab: 0,
      tabTitleAbridged: [
        {
          index: 0,
          name: "Fields",
        },
        {
          index: 1,
          name: "Program Allotments",
        },
      ],
      tabTitlesBaseInset: [
        {
          index: 0,
          name: "Fields",
        },
        {
          index: 1,
          name: "Program Allotments",
        },
        /*{
          index: 2,
          name: "Sampling Plans",
        },
        {
          index: 3,
          name: "Control Center",
        },*/
      ],
      tabTitlesBase: [
        {
          index: 0,
          name: "Enrollments",
        },
        {
          index: 1,
          name: "Fields",
        },
        {
          index: 2,
          name: "Sampling Plans",
        },
        {
          index: 3,
          name: "Control Center",
        },
      ],
      samplingControllerView: "list",
      zoneGeoJSON: null,
      eligibleCollectors: [],
      eligibleLabs: [],
      order_id: null,
      fetchPlansInterval: null,
      finishedLoading: false,
      showMap: false,
      filteredSortedEnrollmentsVDataTableFinal: [],
      // enrollment changes
      enrollments: null,
      enrolledFields: [],
      regenPracticeOptions: [],
      newEnrollmentStartDate: new Date(),
      selectedEnrollment: null,
      showPlansByEnrollment: true,
      confirmEnrollModalOpen: false,
      showAllGroups: false,
      filters: { loi_entity_name: [] },
      // enrollment sorting
      sortById: "desc",
      sortByName: null,
      sortByFields: null,
      sortByAcres: null,
      sortByDate: null,
      sortByStatus: null,
      deleteEnrollmentModalOpen: false,
      enrollmentToDelete: {},
      hideSwitchBool: false,
      control_center_update_key: 0,
      mapStart: null,
      panel: [-1],
      refreshFieldCropManagement: 0,
      targetFSSIds: [],

      searchEnrollment: "",
      mapShowCounter: 0,
      showErrorAlert: false,
      message: null,
      allocationKey: 0,
      commodityAllocationKey: 0,
    }
  },

  computed: {
    ...mapGetters({
      activeFields: Filter.Getters.getSelectedFields,
    }),

    ...mapState({
      organization: state => state.Organization.organization,
      samplingPlans: state => state.SoilSampling.samplingPlans,
      selectedFields: state => state.Filter.selectedFields,
      fieldBoundaries: state => state.Map.fieldBoundaries,
      previousEnrollment: state => state.Enrollment.previousEnrollment,
      user: state => state.User.user,
      userRole: state => state.User.userRole,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      showOffset: state => state.Organization.organization.showOffset,
      showInset: state => state.Organization.organization.showInset,
      isSuperUser: state => state.User.user.is_superuser,
    }),

    tabTitles() {
      if (this.selectedEnrollment) {
        if (this.isInset) {
          if (this.selectedEnrollment.status == "requested") {
            return this.tabTitleAbridged
          } else {
            return this.tabTitlesBaseInset
          }
        } else {
          return this.tabTitlesBase
        }
      } else {
        if (this.isInset) {
          return this.tabTitleAbridged
        } else {
          return this.tabTitlesBase
        }
      }
    },
    isInset() {
      if ("type" in this.$route.query) {
        if (this.$route.query.type == "inset") {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    },

    enrollmentHeaders() {
      if (this.isInset) {
        return [
          { text: "ID", value: "id" },
          { text: "Grower Group", value: "loi_entity_name", width: "20%" },
          //{ text: "Program Name", value: "ea_program_name", width: "20%" },
          /*{ text: "Program Price", value: "initial_price" },*/

          { text: "Fields", value: "num_fields" },
          { text: "Acres", value: "acreage" },
          //{ text: "Creation Date", value: "enrollment_start_date" },
          { text: "Status", value: "status" },
        ]
      } else {
        return [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "Fields", value: "num_fields" },
          { text: "Acres", value: "acreage" },
          { text: "Creation Date", value: "enrollment_start_date" },
          { text: "Status", value: "status" },
        ]
      }
    },
    isCustomerAdmin() {
      if (
        this.userRole === "Corporation Admin" ||
        this.userRole === "Organization Admin"
      )
        return true
      return false
    },

    sortedEnrollmentsVDataTable() {
      let new_array = this.enrollments
      if (new_array != null) {
        new_array.map(o => (o.num_fields = o.enrollment_fields.length))
        new_array.map(o => (o.acreage = _.round(o.acreage, 1)))

        return new_array
      } else {
        return null
      }
    },

    filteredSortedEnrollmentsVDataTable() {
      let newArray = this.sortedEnrollmentsVDataTable
      if (newArray != null) {
        if (this.isInset) {
          if (this.showAllGroups) {
            newArray = newArray.filter(e => e.enrollment_type == "inset")
          } else {
            newArray = newArray.filter(
              e => e.enrollment_type == "inset" && e.status != "requested"
            )
          }

          if (newArray.length < 1) {
            return null
          }
          let activeFieldIds = this.activeFields.map(field => field.id)

          newArray =
            activeFieldIds.length > 0
              ? newArray.filter(row =>
                  _.some(
                    _.intersection(
                      activeFieldIds,
                      row.enrollment_fields.map(e => e.field_id)
                    )
                  )
                )
              : newArray
        } else {
          newArray = newArray.filter(e => e.enrollment_type == "offset")
        }
      }
      return newArray
    },

    mapParams() {
      let tempParams = {}
      tempParams["order_id"] = this.order_id
      tempParams["category"] = "soil_samples"

      if (this.order_id != null) {
        tempParams["useMap"] = true
      } else {
        tempParams["useMap"] = false
      }
      return tempParams
    },

    fields() {
      const activeFieldIds = this.activeFields.map(field => field.id)
      const filteredBounds =
        activeFieldIds.length > 0
          ? this.fieldBoundaries.filter(row =>
              activeFieldIds.includes(row.properties.field.id)
            )
          : this.fieldBoundaries
      return filteredBounds
    },

    canApprove() {
      return this.isCustomerAdmin && this.user.can_approve_enrollment
    },

    enrollmentsFiltered() {
      //this is to prepare a future where we filter by fields in focus like we do with plans
      const filtered = this.enrollments.filter(
        i => i.status != "cancelled" // &&
        //i.enrollment_fields
        //  .map(f => f.field_id)
        //  .some(r => this.activeFields.map(field => field.id).includes(r))
      )

      return filtered
    },
  },
  methods: {
    ...mapActions({
      fetchSamplingPlans: SoilSampling.Actions.fetchSamplePlans,
      fetchFields: Fields.Actions.fetchFieldsSkinny,
    }),

    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
      setPreviousEnrollment: Enrollment.Mutations.setPreviousEnrollment,
      clearPreviousEnrollment: Enrollment.Mutations.clearPreviousEnrollment,
    }),

    filteredData(e) {
      this.filteredSortedEnrollmentsVDataTableFinal = e
    },

    fetchSamplingPlansForInterval() {
      this.fetchSamplingPlans(this.organization.id)
    },

    resetZoneGeoJSON() {
      this.zoneGeoJSON = []
      this.order_id = null
    },

    toggleZoneFields(fieldIds) {
      const selFields = this.selectedFields
      for (const fieldId in selFields) {
        if (fieldIds.includes(Number(fieldId))) {
          selFields[fieldId] = true
        } else {
          selFields[fieldId] = false
        }
      }

      this.setSelectedFields(fieldIds)
    },
    handleProgramFieldSubSelection(response) {
      this.toggleZoneFields(response)
    },
    handleProgramFSSSubSelection(response) {
      this.targetFSSIds = response
      this.tab = 0
    },

    updateZoneGeoJSON(d) {
      this.zoneGeoJSON = d
    },

    updateMapParams(id) {
      this.order_id = id
    },

    setControllerView(view) {
      this.samplingControllerView = view
    },

    handleCreateEnrollPlan() {
      this.viewCreatePlan()
    },

    async viewCreatePlan() {
      this.samplingControllerView = "create"
      this.tab = 2
      const payload = {
        field_ids: this.activeFields.map(field => field.id),
      }
      await SamplingAPI.postSoilLabs(payload).then(response => {
        this.eligibleLabs = response.data
        this.eligibleLabs.forEach(function (obj) {
          obj.short_name = obj.name

          obj.name = obj.name + " (" + obj.address + ")"
        })
      })
      await SamplingAPI.postSoilCollectors(payload).then(response => {
        this.eligibleCollectors = response.data
        this.eligibleCollectors.forEach(function (obj) {
          obj.short_name = obj.name

          obj.name = obj.name + " (" + obj.address + ")"
        })
      })
    },

    hideSwitch(val) {
      this.hideSwitchBool = val
    },

    // begin enrollment
    async fetchEnrollments() {
      await CarbonReadyAPI.listEnrollments().then(resp => {
        const responseData = resp.data

        responseData.forEach(
          enroll =>
            (enroll["acreage"] = enroll["enrollment_fields"]
              .map(x => x.acreage)
              .reduce((a, o) => a + o))
        )
        this.enrollments = responseData
      })
    },

    handleConfirm(rolment) {
      this.selectedEnrollment = rolment

      this.confirmEnrollModalOpen = true
    },

    refreshLOIMatches() {
      CarbonReadyAPI.getRefreshEnrollments().then(() => {
        this.fetchEnrollments()
      })
    },

    handleEnrollClicked(rolment) {
      // turned off instantly selecting fields for now
      const fieldIds = rolment.enrollment_fields.map(x => x.field_id)
      //this.setSelectedFields(fieldIds)
      this.toggleZoneFields(fieldIds)
      this.selectedEnrollment = rolment
      this.tab = 3
      this.control_center_update_key += 1
    },

    closeConfirmEnrollModal() {
      this.fetchFields()

      /*if (this.isInset && this.selectedEnrollment.status == "approved") {
        this.enrollments = this.enrollments.filter(plan => {
          return (
            plan.loi_entity_name != this.selectedEnrollment.loi_entity_name ||
            plan.id == this.selectedEnrollment.id
          )
        })
      }*/
      this.fetchEnrollments() //dunno about this, better way to refresh list here bryan? should we block better on closing modal?
      this.selectedEnrollment = null
      this.confirmEnrollModalOpen = false
      this.allocationKey += 1
      this.commodityAllocationKey += 1
    },

    refreshAllocationViewer() {
      this.allocationKey += 1
      this.commodityAllocationKey += 1
    },

    handleEnrollFieldsClick() {
      this.$router.push("/enroll-fields")
    },

    handleFieldListClick(fieldId) {
      if (this.selectedEnrollment) {
        const enrollmentFieldIds = this.selectedEnrollment[
          "enrollment_fields"
        ].map(ef => ef.field_id)
        if (!enrollmentFieldIds.includes(fieldId)) {
          this.selectedEnrollment = null
        }
      }
    },

    toolTipData(rolment) {
      let html = ""
      rolment.enrollment_fields.forEach(field => {
        html =
          html +
          "<tr><td>Field: </td><td>" +
          field.field_name +
          " (" +
          field.acreage +
          " ac)</td></tr>"
      })
      html = "<table>" + html + "</table>"

      return html
    },

    // enrollment row coloring
    rowColor(enrollment) {
      if (enrollment) {
        if (enrollment.status == "requested") {
          return "request-color"
        }
        if (enrollment.status == "in review") {
          return "approved-color"
        }
        if (enrollment.status == "approved") {
          return "approved-color"
        }
        if (enrollment.status == "confirmed") {
          return "confirmed-color"
        }
      }
      return ""
    },

    backColor(enrollment) {
      if (enrollment) {
        if (enrollment.status == "requested") {
          return "request-color-back"
        }
        if (enrollment.status == "approved") {
          return "approved-color-back"
        }
        if (enrollment.status == "confirmed") {
          return "confirmed-color-back"
        }
      }
      return ""
    },

    handleDeselectEnrollment() {
      this.selectedEnrollment = null
      this.toggleZoneFields(
        this.fieldBoundaries.map(x => x.properties.field.id)
      )
      /*this.setSelectedFields(
        this.fieldBoundaries.map(x => x.properties.field.id)
      )*/
      this.control_center_update_key += 1
      if (this.isInset) {
        this.tab = 1 //default to grower view if deselecting
      }
    },

    cancelEnrollment(enrollment) {
      this.enrollmentToDelete = enrollment
      this.deleteEnrollmentModalOpen = true
    },
    closeDeleteEnrollmentModal() {
      this.deleteEnrollmentModalOpen = false
      this.allocationKey += 1
      this.commodityAllocationKey += 1
    },
    mapZoom(resp) {
      this.mapStart = resp
      this.showMap = true
      this.mapShowCounter = this.mapShowCounter + 1
    },
    handleHideShowMap() {
      this.showMap = !this.showMap
      this.mapShowCounter = this.mapShowCounter + 1
    },
    async handleConfirmDeleteEnrollment() {
      const payload = {
        id: this.enrollmentToDelete.id,
      }

      await CarbonReadyAPI.deleteRequestedEnrollment(payload)
        .then(() => {
          this.enrollments = this.enrollments.filter(
            plan => plan.id != this.enrollmentToDelete.id
          )
          this.fetchFields()
          this.allocationKey += 1
          this.commodityAllocationKey += 1
          this.refreshFieldCropManagement += 1
        })
        .catch(() => {
          this.showErrorAlert = true
          this.message =
            "Unable to delete selected enrollment. Please contact Arva support For further assistance."
        })

      this.enrollmentToDelete = null
      this.closeDeleteEnrollmentModal()
      this.handleDeselectEnrollment()
    },
  },
  async mounted() {
    // await this.fetchEnrollmentFields()
    if (
      this.$router.history.router.prevRoute.path.includes("/data-spreadsheet")
    ) {
      this.selectedEnrollment = this.previousEnrollment
      this.confirmEnrollModalOpen = true
      this.clearPreviousEnrollment()
    }

    const soilCollectorsPayload = {
      field_ids: this.activeFields.map(field => field.id),
    }
    await SamplingAPI.postSoilCollectors(soilCollectorsPayload).then(
      response => {
        this.eligibleCollectors = response.data
        this.eligibleCollectors.forEach(function (obj) {
          obj.short_name = obj.name

          obj.name = obj.name + " (" + obj.address + ")"
        })
      }
    )

    await this.fetchEnrollments()
    this.fetchPlansInterval = setInterval(
      this.fetchSamplingPlansForInterval,
      90000
    )

    this.filteredSortedEnrollmentsVDataTableFinal =
      this.filteredSortedEnrollmentsVDataTable
  },

  destroyed() {
    if (this.fetchPlansInterval) clearInterval(this.fetchPlansInterval)
  },

  watch: {
    tab(newTab, oldTab) {
      if (oldTab === 1 || newTab !== 1) {
        this.zoneGeoJSON = []
      }
      if (newTab != 2) {
        this.showMap = false
      }
      if (newTab == 1) {
        this.fetchEnrollments() //WARNING this is a better user experience with small data, but might not be functional with lots of data
      }
    },
    fetchPlansInterval() {
      this.finishedLoading = true
    },
    activeFields() {
      if (this.eligibleCollectors.length < 1) {
        const payload = {
          field_ids: this.activeFields.map(field => field.id),
        }
        SamplingAPI.postSoilLabs(payload).then(response => {
          this.eligibleLabs = response.data
          this.eligibleLabs.forEach(function (obj) {
            obj.short_name = obj.name
            obj.name = obj.name + " (" + obj.address + ")"
          })
        })
        SamplingAPI.postSoilCollectors(payload).then(response => {
          this.eligibleCollectors = response.data
          this.eligibleCollectors.forEach(function (obj) {
            obj.short_name = obj.name
            obj.name = obj.name + " (" + obj.address + ")"
          })
        })
      }
    },
  },
}
</script>

<style scoped>
h2 {
  font-weight: bold;
  border-bottom: 1pt solid #bbb;
  padding-bottom: 9px;
  margin-bottom: 20px;
}

.pointer {
  cursor: pointer;
}

.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}

.map-col {
  padding-left: 0px;
}

.map-wrapper {
  height: calc(100vh - 65px);
  width: 100%;
  margin-top: -30px;
  margin-bottom: -17px;
  position: relative;
  background: #1b1b1d;
}

.fill-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.scroll-col {
  max-height: 85vh;
  overflow: scroll;
  padding-right: 40px;
}

.reverse-margin {
  margin-bottom: -60px;
}

.single {
  font-weight: bold;
  color: green;
}

.comparison {
  font-weight: bold;
  color: purple;
}

.loading-text {
  font-weight: bold;
  font-size: 16px;
}

.info {
  color: #666666;
}

.thirty {
  width: 30%;
}

.sel-enroll {
  font-size: 16px;
  font-weight: 500;
  line-height: 34px;
}

.available-ball {
  background: #000000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.requested-ball {
  background: #850000;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.approved-ball {
  background: #ccb33b;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.confirmed-ball {
  background: rgb(11, 217, 39);
  width: 12px;
  height: 12px;
  border-radius: 12px;
  margin-top: 3px;
  margin-right: 1px;
}

.request-color {
  font-weight: 500;
  color: #850000;
}

.approved-color {
  font-weight: 500;
  color: #ccb33b;
}

.confirmed-color {
  font-weight: 500;
  color: #72cc6b;
}

.date-chip {
  width: 125px;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  margin: 5px 0px;
}

.request-color-back {
  background-color: #850000;
}

.approved-color-back {
  background-color: #ccb33b;
}

.confirmed-color-back {
  background-color: #72cc6b;
}

.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
.expansion-header {
  background-color: #d3d3d350;
}
</style>
