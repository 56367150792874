import Vue from 'vue'
import { EnrollmentGroups } from "@/store/modules"
import { parseGroupData, chunkIntoClientData } from "@/utility/contractGroups"
import {
  getRollup,
  listOverview,
  getFssMetadata,
  submitSupplyRequests,
  applyPendingSupplyStates,
  submitEvidencingLink,
  submitConfirmRequests,
  fetchClientProfiles,
  fetchGroupClientProfiles,
  addProfileToGroup,
  removeProfileFromGroup,
  swapGroupProfile,
  updateProfile,
  createProfile,
  getContractTabMetadata
} from "@/api/ContractGroupAPI"
import {
  NO,
  YES,
  CP_VIEW,
  ADD_PROFILE,
  REMOVE_PROFILE,
  UPDATE_PROFILE,
  SWAP_PROFILE,
  CREATE_PROFILE,
  ROLLUP_TABLE,
  FSS_INDIVIUAL,
  ENROLLMENT_TABLE,
  CLIENT_PROFILES,
} from "@/constants/contractGroups"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"


const tableLoadingState = {}
tableLoadingState[CLIENT_PROFILES] = { loading: true, error: null }
tableLoadingState[ENROLLMENT_TABLE] = { loading: true, error: null }
tableLoadingState[ROLLUP_TABLE] = { loading: true, error: null }
tableLoadingState[FSS_INDIVIUAL] = []

const state = {
  ...tableLoadingState,
  allClientProfiles: null,
  groupData: {},
  rollupData: [],
  clientData: {},
  programs: null,
  view: CP_VIEW,
  org: null
}

const getters = {
  [EnrollmentGroups.Getters.allUniqueRegions]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { regions }) => {
        for (const region of regions) {
          const found = US_STATES.find(({ text }) => text == region)
          if (found == null && !accum.includes("Canada")) accum.push("Canada")
          if (found != null && !accum.includes(found["value"]))
            accum.push(found["value"])
        }

        return accum
      }, [])
      .sort()
  },
  [EnrollmentGroups.Getters.allUniqueCrops]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { crops }) => {
        for (const { id, harvest_type } of crops) {
          const found = accum.find(c => c["id"] == id)

          // look what they did to my boy
        if (found == null) accum.push({ id, harvest_type: null, itemKey: `${id}-null` })
        else if (
          found['id'] == 4
          && accum.find(c => c['id'] == 4 && c['harvest_type'] == 'Silage') == null
          && harvest_type == 'Silage'
        ) {
          accum.push({ id, harvest_type, itemKey: `${id}-${'Silage'}` })
        }
      }

        return accum
      }, [])
      .sort((a, b) =>
        CROP_DISPLAY_NAME[a["id"]].localeCompare(CROP_DISPLAY_NAME[b["id"]])
      )
  },
}

const mutations = {
  [EnrollmentGroups.Mutations.setPrograms](state, programs) {
    state.programs = Object.freeze(programs)
  },
  [EnrollmentGroups.Mutations.setClientProfiles](state, profiles) {
    state.allClientProfiles = Object.freeze(profiles)
  },
  [EnrollmentGroups.Mutations.setOrg](state, orgId) {
    state.org = orgId
  },
  [EnrollmentGroups.Mutations.setView](state, view) {
    state.view = view
  },
  [EnrollmentGroups.Mutations.setRollupData](state, rollupData) {
    Vue.set(state, "rollupData", rollupData)
  },
  [EnrollmentGroups.Mutations.setAllGroupData](state, groupData) {
    state.groupData = Object.freeze(groupData)
  },
  [EnrollmentGroups.Mutations.setAllClientData](state, clientData) {
    state.clientData = Object.freeze(clientData)
  },
  [EnrollmentGroups.Mutations.setFssLoading](state, { fssIds, loading }) {
    const newLoading = [...state[FSS_INDIVIUAL]]

    if (loading) {
      for (const fssId of fssIds)
        if (!newLoading.includes(fssId)) newLoading.push(fssId)
    } else {
      for (const fssId of fssIds)
        if (newLoading.includes(fssId))
          newLoading.splice(newLoading.indexOf(fssId), 1)
    }

    state[FSS_INDIVIUAL] = Object.freeze(newLoading)
  },
  [EnrollmentGroups.Mutations.setFssMetadata](state, { groupId, data }) {
    const newState = { ...state.groupData }
    const newGroupData = newState[groupId]

    for (const [fssId, practices, farmName, thumbnail, orderIds] of data) {
      newGroupData["fieldsupply"].find(({ id }) => id == fssId)["metadata"] = {
        practices,
        farmName,
        thumbnail,
        orderIds
      }
    }

    state.groupData = Object.freeze(newState)

    const newClientState = { ...state.clientData }
    for (const clientId in newClientState) {
      for (const [fssId, practices, farmName, thumbnail, orderIds] of data) {
      const found = newClientState[clientId]['fieldsupply'].find(({ id }) => fssId == id)
      if (found != null) {
          found["metadata"] = {
            practices,
            farmName,
            thumbnail,
            orderIds
          }
        }
      }
    }

    state.clientData = Object.freeze(newClientState)
  },
  [EnrollmentGroups.Mutations.setIndividualGroupData](state, groupData) {
    state.groupData = Object.freeze({ ...state.groupData, ...groupData })
  },
  [EnrollmentGroups.Mutations.setGroupSupplyChanges](state, { groupId, supplyState, fssIds }) {
    const idx = state.groupData.findIndex(g => g["id"] == groupId)
    if (idx != -1) {
      Vue.set(state.groupData[idx]["supplyChanges"], supplyState, fssIds)
    }
  },
  [EnrollmentGroups.Mutations.setTableLoadingState](state, { target, loading, error = null }) {
    Vue.set(state, target, { loading, error })
  }
}

const actions = {
  [EnrollmentGroups.Actions.listOverview]({
    state,
    commit,
    rootState,
    dispatch,
  }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      listOverview({ org_node_id: organization.id, year })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setOrg, organization.id)
          const parsedGroupData = parseGroupData(data, state.rollupData)
          commit(EnrollmentGroups.Mutations.setAllGroupData, parsedGroupData)

          const clientChunkedData = chunkIntoClientData(parsedGroupData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        })
        .catch(error => {
          console.log("Error fetching group data overview: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.getContractTabMetadata]({
    commit,
    state,
    rootState,
  }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })

      const group_ids = Object.keys(state.groupData)

      getContractTabMetadata({ group_ids }).then(
        ({ data: { program_ids, fss_program_map } }) => {
          commit(EnrollmentGroups.Mutations.setPrograms, program_ids)

          const curGroupData = { ...state.groupData }
          for (const groupId in curGroupData) {
            for (const fss of curGroupData[groupId]["fieldsupply"]) {
              fss["matched_program_id"] = fss_program_map[fss["id"]]
            }
          }

          commit(EnrollmentGroups.Mutations.setAllGroupData, curGroupData)

          const clientChunkedData = chunkIntoClientData(curGroupData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        }
      )
    })
  },
  [EnrollmentGroups.Actions.fetchFssMetadata](
    { commit, state, rootState, dispatch },
    { groupId, clientId }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      const groupIds = []

      if (groupId == null && clientId != null) {
        // fetch metadata from a client-based view... just find all legal entity
        // ids that the client's fss exist in and get those
        for (const fss of state.clientData[clientId]["fieldsupply"]) {
          for (const gId in state.groupData) {
            if (groupIds.includes(gId)) continue

            if (
              state.groupData[gId]["fieldsupply"].find(
                ({ id }) => id == fss["id"]
              ) != null
            ) {
              groupIds.push(gId)
            }
          }
        }
      } else groupIds.push(groupId)

      Promise.all(
        groupIds.map(gId => {
          return new Promise((innerResolve, innerReject) => {
            const fssIds = state.groupData[gId]["fieldsupply"].map(
              ({ id }) => id
            )
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: ENROLLMENT_TABLE,
              loading: true,
            })

            getFssMetadata({
              fss_ids: fssIds,
              org_node_id: organization.id,
              year,
            })
              .then(({ data }) => {
                commit(EnrollmentGroups.Mutations.setFssMetadata, {
                  groupId: gId,
                  data,
                })
                innerResolve()
              })
              .catch(error => {
                console.log("Error fetching group metadata: ", error)
                innerReject(error)
              })
          })
        })
      )
        .then(_ => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.fetchRollup](
    { commit, rootState, dispatch },
    { fetchOverview }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      if (fetchOverview) {
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: true,
        })
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ROLLUP_TABLE,
        loading: true,
      })
      getRollup({ org_node_id: organization.id, year })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setRollupData, data)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ROLLUP_TABLE,
            loading: false,
          })

          if (!fetchOverview) {
            resolve()
          } else {
            dispatch(EnrollmentGroups.Actions.listOverview).then(() =>
              resolve()
            )
          }
        })
        .catch(error => {
          console.log("Error fetching program rollup: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ROLLUP_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitSupplyChanges](
    { commit, dispatch, state },
    { groupId, clientId, crop, crop_specific_snapshot = false }
  ) {
    const parsedIds = []

    if (groupId == null && clientId != null) {
      // chunk all client fss into group fss
      for (const fss of state.clientData[clientId]["fieldsupply"]) {
        for (const gId in state.groupData) {
          if (parsedIds.find(([g]) => g == gId) != null) continue

          if (
            state.groupData[gId]["fieldsupply"].find(
              ({ id }) => fss["id"] == id
            ) != null
          ) {
            if (crop != null) {
              parsedIds.push([gId, crop["id"], crop["harvest_type"]]) // allow multiple crop ids, crop['id'] is array
            } else parsedIds.push([gId, null, null])
          }
        }
      }
    }

    if (groupId != null && clientId == null) {
      if (crop != null)
        parsedIds.push([groupId, crop["id"][0], crop["harvest_type"]])
      else parsedIds.push([groupId, null, null])
    }

    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: ENROLLMENT_TABLE,
      loading: true,
    })

    Promise.all(
      parsedIds.map(([fssSetId, cropId, harvest_type]) => {
        return new Promise((resolve, reject) => {
          const getFss = fssId => {
            return state.groupData[fssSetId]["fieldsupply"].find(fss => {
              if (fssId == fss["id"]) {
                if (cropId == null) return true
                if (
                  cropId == fss["crop_id"] ||
                  (Array.isArray(cropId) && cropId.includes(fss["crop_id"]))
                ) {
                  if (harvest_type == "null" && fss["harvest_type"] == null)
                    return true
                  if (harvest_type == fss["harvest_type"]) return true
                }
              }
            })
          }

          submitSupplyRequests({
            group_id: fssSetId,
            crop_specific_snapshot,
            fss_to_request:
              state.groupData[fssSetId]["supplyChanges"][YES].filter(getFss),
            fss_to_available:
              state.groupData[fssSetId]["supplyChanges"][NO].filter(getFss),
          })
            .then(({ data }) => {
              const toParse = {}
              toParse[fssSetId] = data

              // include the old fss metadata, matched program id
              for (const fss of toParse[fssSetId]["fieldsupply"]) {
                const found = state.groupData[fssSetId]["fieldsupply"].find(
                  f => f["id"] == fss["id"]
                )
                if (found != null) {
                  if (found.hasOwnProperty("metadata")) {
                    fss["metadata"] = found["metadata"]
                  }

                  if (found["matched_program_id"] != null) {
                    fss["matched_program_id"] = found["matched_program_id"]
                  }
                }
              }

              const parsedData = parseGroupData(toParse, state.rollupData)
              commit(
                EnrollmentGroups.Mutations.setIndividualGroupData,
                parsedData
              )
              resolve()
            })
            .catch(error => {
              console.log(
                "Error fetching group data after supply submission",
                error
              )
              commit(EnrollmentGroups.Mutations.setTableLoadingState, {
                target: ENROLLMENT_TABLE,
                loading: false,
                error,
              })
              reject(error)
            })
        })
      })
    ).then(_ => {
      const newAllGroupsParsedData = state.groupData
      const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
      commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: false,
      })
      dispatch(EnrollmentGroups.Actions.fetchRollup, { fetchOverview: false })
    })
  },
  [EnrollmentGroups.Actions.applyPendingFssState](
    { commit, state },
    { groupId, clientId, fssIds, supplyState }
  ) {
    commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds, loading: true })

    const fssByGroup = {}

    if (groupId == null && clientId != null) {
      // fetch metadata from a client-based view... just find all legal entity
      // ids that the client's fss exist in and get those
      for (const gId in state.groupData) {
        if (fssByGroup.hasOwnProperty(gId)) continue

        const groupFss = state.groupData[gId]["fieldsupply"]
          .filter(({ id }) => fssIds.includes(id))
          .map(({ id }) => id)

        if (groupFss.length > 0) {
          fssByGroup[gId] = groupFss
          break
        }
      }
    } else {
      fssByGroup[groupId] = fssIds
    }

    Promise.all(
      Object.keys(fssByGroup).map(gId => {
        return new Promise((innerResolve, innerReject) => {
          applyPendingSupplyStates({
            fss_ids: fssByGroup[gId],
            group_id: gId,
            supplyState,
          })
            .then(({ data }) => {
              const newGroupData = {}
              newGroupData[gId] = { ...state.groupData[gId] }
              newGroupData[gId]["supplyChanges"][NO] = []
              newGroupData[gId]["supplyChanges"][YES] = []

              for (const fss of newGroupData[gId]["fieldsupply"]) {
                const otherState = fss["supply_state"] == YES ? NO : YES

                if (data[`${otherState}_fieldsupply`].includes(fss["id"])) {
                  fss["supply_state"] = otherState
                }

                // flag, just do this every time i suppose
                const parsedGroupData = parseGroupData(
                  newGroupData,
                  state.rollupData
                )
                commit(
                  EnrollmentGroups.Mutations.setIndividualGroupData,
                  parsedGroupData
                )
                innerResolve()
              }
            })
            .catch(error => {
              innerReject(error)
            })
        })
      })
    )
      .then(() => {
        const newAllGroupsParsedData = state.groupData
        const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
        commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)
        commit(EnrollmentGroups.Mutations.setFssLoading, {
          fssIds,
          loading: false,
        })
      })
      .catch(error => {
        console.log("sumn happened", error)
      })
  },
  [EnrollmentGroups.Actions.submitEvidencingLink](
    { commit, state },
    { group_id, updated_link_obj }
  ) {
    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: ENROLLMENT_TABLE,
      loading: true,
    })
    submitEvidencingLink({ group_id, updated_link_obj })
      .then(() => {
        const newGroupData = {}
        newGroupData[group_id] = {
          ...state.groupData[group_id],
          evidencing_links: updated_link_obj,
        }
        commit(EnrollmentGroups.Mutations.setIndividualGroupData, newGroupData)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: false,
        })
      })
      .catch(error => {
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: false,
          error,
        })
      })
  },
  [EnrollmentGroups.Actions.confirmSupplyChanges](
    { commit, state },
    { group_id, fss_to_confirm }
  ) {
    const groupData = state.groupData[group_id]

    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: ENROLLMENT_TABLE,
      loading: true,
    })
    submitConfirmRequests({ group_id, fss_to_confirm })
      .then(({ data }) => {
        const toParse = {}
        toParse[group_id] = data

        // include the old fss metadata
        for (const fss of toParse[group_id]["fieldsupply"]) {
          const found = groupData["fieldsupply"].find(f => f["id"] == fss["id"])
          if (found != null) {
            if (found.hasOwnProperty("metadata")) {
              fss["metadata"] = found["metadata"]
            }

            if (found["matched_program_id"] != null) {
              fss["matched_program_id"] = found["matched_program_id"]
            }
          }
        }

        const parsedData = parseGroupData(toParse, state.rollupData)
        commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: false,
        })
      })
      .catch(err => console.log("error confirming requests:", err))
  },
  [EnrollmentGroups.Actions.fetchClientProfiles](
    { rootState, commit },
    { group_id }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })
      new Promise((innerResolve, innerReject) => {
        if (state.allClientProfiles != null) innerResolve()
        else {
          fetchClientProfiles()
            .then(({ data }) => {
              commit(EnrollmentGroups.Mutations.setClientProfiles, data)
              innerResolve()
            })
            .catch(error => {
              console.log("Error fetching client profiles: ", error)
              commit(EnrollmentGroups.Mutations.setTableLoadingState, {
                target: CLIENT_PROFILES,
                loading: false,
                error,
              })
              innerReject(error)
            })
        }
      })
        .then(() => {
          if (group_id == null) {
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: CLIENT_PROFILES,
              loading: false,
            })
            resolve()
            return
          }
          fetchGroupClientProfiles({ group_id }).then(({ data }) => {
            const toParse = {}
            toParse[group_id] = state.groupData[group_id]
            toParse[group_id]["cp_profiles"] = data.filter(
              ({ profile_type }) => profile_type == "cp"
            )
            toParse[group_id]["grower_profiles"] = data.filter(
              ({ profile_type }) => profile_type == "grower"
            )
            const parsedData = parseGroupData(toParse, state.rollupData)
            commit(
              EnrollmentGroups.Mutations.setIndividualGroupData,
              parsedData
            )
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: CLIENT_PROFILES,
              loading: false,
            })
            resolve()
          })
        })
        .catch(error => {
          console.log("Error 2 fetching profiles: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.editClientProfiles](
    { commit, state },
    { type, group_id, payload }
  ) {
    let api = null

    if (type == ADD_PROFILE) api = addProfileToGroup
    if (type == REMOVE_PROFILE) api = removeProfileFromGroup
    if (type == UPDATE_PROFILE) api = updateProfile
    if (type == SWAP_PROFILE) api = swapGroupProfile
    if (type == CREATE_PROFILE) api = createProfile

    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: CLIENT_PROFILES,
      loading: true,
    })
    return api(payload)
      .then(({ data }) => {
        const toParse = {}
        toParse[group_id] = data

        // include the old fss metadata
        for (const fss of toParse[group_id]["fieldsupply"]) {
          const found = state.groupData[group_id]["fieldsupply"].find(
            f => f["id"] == fss["id"]
          )
          if (found != null) {
            if (found.hasOwnProperty("metadata")) {
              fss["metadata"] = found["metadata"]
            }

            if (found["matched_program_id"] != null) {
              fss["matched_program_id"] = found["matched_program_id"]
            }
          }
        }

        const parsedData = parseGroupData(toParse, state.rollupData)
        commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: CLIENT_PROFILES,
          loading: false,
        })
        return parsedData
      })
      .catch(error => {
        console.log("error editing client profiles", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: CLIENT_PROFILES,
          loading: false,
          error,
        })
      })
  },
  [EnrollmentGroups.Actions.submitContractGeneration](
    { commit, state },
    { groupId, landOwnership }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })

      CarbonReadyAPI.postGenerateInsetContract({ groupId, landOwnership })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          // include the old fss metadata
          for (const fss of toParse[groupId]["fieldsupply"]) {
            const found = state.groupData[groupId]["fieldsupply"].find(
              f => f["id"] == fss["id"]
            )
            if (found != null) {
              if (found.hasOwnProperty("metadata")) {
                fss["metadata"] = found["metadata"]
              }

              if (found["matched_program_id"] != null) {
                fss["matched_program_id"] = found["matched_program_id"]
              }
            }
          }

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitVoidContract](
    { commit, state },
    { groupId, enrollmentId, voidedReason }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })
      CarbonReadyAPI.postVoidContract({ groupId, enrollmentId, voidedReason })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          // include the old fss metadata
          for (const fss of toParse[groupId]["fieldsupply"]) {
            const found = state.groupData[groupId]["fieldsupply"].find(
              f => f["id"] == fss["id"]
            )
            if (found != null) {
              if (found.hasOwnProperty("metadata")) {
                fss["metadata"] = found["metadata"]
              }

              if (found["matched_program_id"] != null) {
                fss["matched_program_id"] = found["matched_program_id"]
              }
            }
          }

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitContractEsign](
    { commit, state },
    { groupId, enrollmentId }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })

      CarbonReadyAPI.postESignContract({ groupId, enrollmentId })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          // include the old fss metadata
          for (const fss of toParse[groupId]["fieldsupply"]) {
            const found = state.groupData[groupId]["fieldsupply"].find(
              f => f["id"] == fss["id"]
            )
            if (found != null) {
              if (found.hasOwnProperty("metadata")) {
                fss["metadata"] = found["metadata"]
              }

              if (found["matched_program_id"] != null) {
                fss["matched_program_id"] = found["matched_program_id"]
              }
            }
          }
          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
}

export default {
    state,
    mutations,
    getters,
    actions
  }
