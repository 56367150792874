import {
  NO,
  YES,
  REQUESTED,
  CONFIRMED,
  LOCKED_FOR_PAYMENT,
  REQUESTED_PENDING,
  BLANK_METADATA,
  BLANK_ACREAGE_VALUES,
} from "@/constants/contractGroups"

export const validateClientProfiles = profile => {
  const modifiedProfile = structuredClone(profile)

  let isValidated = true

  // user is optional
  // street_address_2 is optional
  if (profile["first_name"] == null || profile["first_name"] == "")
    isValidated = false
  if (profile["last_name"] == null || profile["last_name"] == "")
    isValidated = false
  if (profile["title"] == null || profile["title"] == "") isValidated = false
  if (profile["phone_number"] == null || profile["phone_number"] == "")
    isValidated = false
  if (profile["poc_email"] == null || profile["poc_email"] == "")
    isValidated = false
  if (profile["street_address_1"] == null || profile["street_address_1"] == "")
    isValidated = false
  if (profile["city"] == null || profile["city"] == "") isValidated = false
  if (profile["state"] == null || profile["state"] == "") isValidated = false
  if (profile["zip_code"] == null || profile["zip_code"] == "")
    isValidated = false
  if (profile["country"] == null || profile["country"] == "")
    isValidated = false

  modifiedProfile["validated"] = isValidated
  return modifiedProfile
}

export const parseGroupData = (groupData, rollupData) => {
  const parsedData = {}
  for (const [
    groupId,
    {
      name,
      timestamps,
      snapshot,
      fieldsupply,
      evidencing_links,
      status,
      contract_outdated,
      can_generate_contract,
      has_maxcap,
      enrollment,
      cp_profiles,
      grower_profiles,
      is_confirmed,
    },
  ] of Object.entries(groupData)) {
    const acreageValues = structuredClone(BLANK_ACREAGE_VALUES)
    const clientNames = []
    const filteredFss = []
    const cropRegionMap = {}
    const supplyChanges = {}
    supplyChanges[YES] = []
    supplyChanges[NO] = []

    const validatedCPProfiles = cp_profiles.map(validateClientProfiles)
    const validatedGrowerProfiles = grower_profiles.map(validateClientProfiles)

    fieldsupply
      .map(fss => {
        return {
          ...fss,
          // we're doing silage or not silage for fss -> order matching, so let's format the fss as such
          harvest_type: fss["harvest_type"] == "Silage" ? "Silage" : null,
          metadata:
            fss["metadata"] == null ? { ...BLANK_METADATA } : fss["metadata"],
        }
      })
      .filter(({ crop_id, harvest_type }) => {
        const found = rollupData.find(rollup => {
          if (rollup["crop_ids"].includes(crop_id)) {
            return (
              rollup["harvest_type"] == null ||
              rollup["harvest_type"] == harvest_type ||
              (rollup["harvest_type"] == "Grain" &&
                ["Grain", "Seed"].includes(harvest_type))
            )
          }

          return false
        })
        return found != null
      })
      .forEach(fss => {
        const { supply_state, id, status, harvest_type, state_name, crop_id, acreage, client_name, metadata = null } = fss

        if (state_name == null) return

        filteredFss.push({ ...fss, harvest_type: harvest_type })

        if (!clientNames.includes(client_name)) clientNames.push(client_name)

        if (!cropRegionMap.hasOwnProperty(crop_id)) {
          cropRegionMap[crop_id] = {}
        }
        if (!cropRegionMap[crop_id].hasOwnProperty(harvest_type)) {
          cropRegionMap[crop_id][harvest_type] = { regions: [] }
        }
        if (
          !cropRegionMap[crop_id][harvest_type]["regions"].includes(state_name)
        ) {
          cropRegionMap[crop_id][harvest_type]["regions"].push(state_name)
        }

        for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING, LOCKED_FOR_PAYMENT]) {
          if (!acreageValues[s].hasOwnProperty(crop_id)) {
            acreageValues[s][crop_id] = {}
          }

          if (!acreageValues[s][crop_id].hasOwnProperty(harvest_type)) {
            acreageValues[s][crop_id][harvest_type] = {}
          }

          if (
            !acreageValues[s][crop_id][harvest_type].hasOwnProperty(state_name)
          ) {
            acreageValues[s][crop_id][harvest_type][state_name] = {
              acreage: 0,
              numFields: 0,
            }
          }
        }

        const otherState = supply_state == YES ? NO : YES

        // everything w/o a snapshot is a 'change'
        // if a snapshot exists, 'yes' group supply in a 'no' snapshot supply is a 'change'
        // i.e. a group supply change has happened that hasn't been saved to a snapshot yet
        if (snapshot == null) {
          supplyChanges[supply_state].push(id)
        } else {
          if (snapshot[`${otherState}_fieldsupply`].includes(id)) {
            // group supply 'yes', snapshot 'no', for example, is a change to 'yes'
            // that hasn't been realized yet
            supplyChanges[supply_state].push(id)
          } else if (!snapshot[`${supply_state}_fieldsupply`].includes(id)) {
            // fss isn't in any of the snapshot supply, so we must have submitted a partial crop-only snapshot
            // so this would count as a supply change since it's in the group but not the snapshot
            supplyChanges[supply_state].push(id)
          }
        }

        // NOTE: the below steps should amalgamate into the above steps
        //
        // supply can be counted for both requested/confirmed AND for pending changes
        // i.e. is in a requested state + is pending request removal
        if ([LOCKED_FOR_PAYMENT, CONFIRMED, REQUESTED].includes(status)) {
          acreageValues[status][crop_id][harvest_type][state_name]["acreage"] +=
            acreage
          acreageValues[status][crop_id][harvest_type][state_name][
            "numFields"
          ] += 1
        }

        for (const supplyState of [YES, NO]) {
          if (supplyChanges[supplyState].includes(id)) {
            if (supplyState == YES) {
              acreageValues[REQUESTED_PENDING][crop_id][harvest_type][
                state_name
              ]["acreage"] += acreage
              acreageValues[REQUESTED_PENDING][crop_id][harvest_type][
                state_name
              ]["numFields"] += 1
            }
            if (supplyState == NO) {
              if (
                snapshot != null &&
                snapshot["yes_fieldsupply"].includes(id)
              ) {
                // check if there's a previous snapshot. If there's no snapshot, don't count
                // the 'no's, as you can't subtract from 0 here.
                // Also, because we are allowing partial snapshots, we need to confirm that this fss Id exists
                // in the opposite 'yes' field supply in the snapshot. If it doesn't, we shouldn't count it
                // as it hasn't been submitted at all yet.
                acreageValues[REQUESTED_PENDING][crop_id][harvest_type][
                  state_name
                ]["acreage"] -= acreage
                acreageValues[REQUESTED_PENDING][crop_id][harvest_type][
                  state_name
                ]["numFields"] += 1
              }
            }
          }
        }
      })

    const { crops, regions } = Object.keys(cropRegionMap).reduce(
      (acc, cropId) => {
        for (const harvest_type in cropRegionMap[cropId]) {
          const foundCrop = acc["crops"].find(
            c => c["id"] == cropId && c["harvest_type"] == harvest_type
          )
          if (foundCrop == null)
            acc["crops"].push({ id: Number(cropId), harvest_type })

          for (const region of cropRegionMap[cropId][harvest_type]["regions"]) {
            if (!acc["regions"].includes(region)) acc["regions"].push(region)
          }
        }

        return acc
      },
      { crops: [], regions: [] }
    )

    if (filteredFss.length > 0) {
      parsedData[groupId] = {
        name,
        crops,
        regions,
        cropRegionMap,
        snapshot,
        timestamps,
        evidencing_links,
        clientNames,
        status,
        contract_outdated,
        can_generate_contract,
        has_maxcap,
        enrollment,
        fieldsupply: filteredFss,
        supplyChanges,
        acreageValues,
        is_confirmed,
        cp_profiles: validatedCPProfiles,
        grower_profiles: validatedGrowerProfiles,
      }
    }
  }

  return parsedData
}


/**
 * It seems this is very much a temporary functionality
 * 
 * Therefore making completely parsed + ready group data a dependency here,
 * all this does is chunk it out into client-based keys
 * @param {*} parsedGroupData 
 */
export const chunkIntoClientData = (parsedGroupData) => {
  const clientChunks = {}

  for (const { fieldsupply, supplyChanges, snapshot } of Object.values(parsedGroupData)) {
    for (const fss of fieldsupply) {
      if (!clientChunks.hasOwnProperty(fss['client_id'])) {
        clientChunks[fss['client_id']] = {
          name: fss['client_name'],
          fieldsupply: [],
          acreageValues: {},
          cropRegionMap: {},
          supplyChanges: { no: [], yes: [] },
          snapshot: { no_fieldsupply: [], yes_fieldsupply: [] }
        }
      }

      const clientData = clientChunks[fss['client_id']]
      clientData['fieldsupply'].push(fss)

      for (const supplyState of [YES, NO]) {
        if (supplyChanges[supplyState].includes(fss['id'])) {
          clientData['supplyChanges'][supplyState].push(fss['id'])
        }

        if (snapshot != null && snapshot[`${supplyState}_fieldsupply`].includes(fss['id'])) {
          clientData['snapshot'][`${supplyState}_fieldsupply`].push(fss['id'])
        }
      }

      for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING, LOCKED_FOR_PAYMENT]) {
        if (!clientData['acreageValues'].hasOwnProperty(s)) {
          clientData['acreageValues'][s] = {}
        }
        if (!clientData['acreageValues'][s].hasOwnProperty(fss['crop_id'])) {
          clientData['acreageValues'][s][fss['crop_id']] = {}
        }
        if (!clientData['acreageValues'][s][fss['crop_id']].hasOwnProperty(fss['harvest_type'])) {
          clientData['acreageValues'][s][fss['crop_id']][fss['harvest_type']] = {}
        }
        if (!clientData['acreageValues'][s][fss['crop_id']][fss['harvest_type']].hasOwnProperty(fss['state_name'])) {
          clientData['acreageValues'][s][fss['crop_id']][fss['harvest_type']][fss['state_name']] = { acreage: 0, numFields: 0 }
        }
      }

      if (!clientData['cropRegionMap'].hasOwnProperty(fss['crop_id'])) {
        clientData['cropRegionMap'][fss['crop_id']] = {}
      }
      if (!clientData['cropRegionMap'][fss['crop_id']].hasOwnProperty(fss['harvest_type'])) {
        clientData['cropRegionMap'][fss['crop_id']][fss['harvest_type']] = { regions: [] }
      }
      if (!clientData['cropRegionMap'][fss['crop_id']][fss['harvest_type']]['regions'].includes(fss['state_name'])) {
        clientData['cropRegionMap'][fss['crop_id']][fss['harvest_type']]['regions'].push(fss['state_name'])
      }
    }
  }

  for (const clientId in clientChunks) {
    const { crops, regions } = Object.keys(clientChunks[clientId]['cropRegionMap']).reduce((acc, cropId) => {
      for (const harvest_type in clientChunks[clientId]['cropRegionMap'][cropId]) {
        const foundCrop = acc['crops'].find((c) => c['id'] == cropId && c['harvest_type'] == harvest_type)
        if (foundCrop == null) acc['crops'].push({ id: Number(cropId), harvest_type })
        
        for (const region of clientChunks[clientId]['cropRegionMap'][cropId][harvest_type]['regions']) {
          if (!acc['regions'].includes(region)) acc['regions'].push(region)
        }
      }
      
      return acc
    }, { crops: [], regions: [] })

    clientChunks[clientId]['crops'] = crops
    clientChunks[clientId]['regions'] = regions

    for (const fss of clientChunks[clientId]['fieldsupply']) {  
      if ([CONFIRMED, REQUESTED].includes(fss['status'])) {
        clientChunks[clientId]['acreageValues'][fss['status']][fss['crop_id']][fss['harvest_type']][fss['state_name']]['acreage'] += fss['acreage']
        clientChunks[clientId]['acreageValues'][fss['status']][fss['crop_id']][fss['harvest_type']][fss['state_name']]['numFields'] += 1
      }
  
      for (const supplyState of [YES, NO]) {
        if (clientChunks[clientId]['supplyChanges'][supplyState].includes(fss['id'])) {
          if (supplyState == YES) {
            clientChunks[clientId]['acreageValues'][REQUESTED_PENDING][fss['crop_id']][fss['harvest_type']][fss['state_name']]['acreage'] += fss['acreage']
            clientChunks[clientId]['acreageValues'][REQUESTED_PENDING][fss['crop_id']][fss['harvest_type']][fss['state_name']]['numFields'] += 1
          }
          if (supplyState == NO) {
            if (clientChunks[clientId]['snapshot'] != null && clientChunks[clientId]['snapshot']['yes_fieldsupply'].includes(fss['id'])) {
              // check if there's a previous snapshot. If there's no snapshot, don't count
              // the 'no's, as you can't subtract from 0 here.
              // Also, because we are allowing partial snapshots, we need to confirm that this fss Id exists
              // in the opposite 'yes' field supply in the snapshot. If it doesn't, we shouldn't count it
              // as it hasn't been submitted at all yet.
              clientChunks[clientId]['acreageValues'][REQUESTED_PENDING][fss['crop_id']][fss['harvest_type']][fss['state_name']]['acreage'] -= fss['acreage']
              clientChunks[clientId]['acreageValues'][REQUESTED_PENDING][fss['crop_id']][fss['harvest_type']][fss['state_name']]['numFields'] += 1
            }
          }
        }
      }
    }
  }

  return clientChunks
}
