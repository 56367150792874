<template>
  <ArvaModal
    name="editFieldAssignmentModal"
    title="Edit Field Assignment"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card>
      <div>
        <v-card class="pa-2" v-if="tabTitles">
          <v-toolbar>
            <v-chip color="green lighten-1 py-6" v-if="updateOrderID">
              Subprogram: {{ updateOrderName }} - ({{ updateOrderID }}) <br />
              Supply Allocated vs Alloted: {{ subprogramAcreageSummary }} ({{
                orderSupplyType
              }})
            </v-chip>
            <v-spacer />
            <v-chip
              class="ml-5 py-6 float-right"
              v-if="insetOrderRollupName"
              color="orange lighten-1"
            >
              Program Rollup: {{ insetOrderRollupName }} (Avg Co2e:
              {{ insetOrderRollupCO2e }}) <br />
              Acres Allocated vs Alloted: {{ insetOrderRollupAcreSummary }}
            </v-chip>

            <template v-slot:extension>
              <v-tabs grow v-model="tab">
                <v-tabs-slider color="blue"></v-tabs-slider>
                <v-tab v-for="item in tabTitles" :key="item.index">
                  {{ item.name }}
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-card class="pt-2">
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="item in tabTitles" :key="item.index">
                <v-card flat v-if="item.index == 0">
                  <v-text-field
                    v-model="includedSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToRemove"
                    :headers="supplyHeaders"
                    :items="fieldSupply"
                    :search="includedSearch"
                    :single-select="false"
                    show-select
                    item-key="fss_id"
                    :items-per-page="200"
                    height="40vh"
                    fixed-header
                    fixed-footer
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToRemove.length > 0"
                    @click="removeFields"
                    >Remove Selected {{ selectedToRemove.length }} Field(s) ({{
                      toRemoveAcreage.toFixed(1)
                    }}
                    ac) From Program</v-btn
                  >
                </v-card>
                <v-card flat v-if="item.index == 1">
                  <!--<h4>Requested Order Filters:</h4>-->
                  <v-text-field
                    v-model="eligibleSearch"
                    label="Search"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                    single-line
                  ></v-text-field>
                  <v-data-table
                    v-model="selectedToInclude"
                    :headers="supplyHeaders"
                    :items="fieldSupplyEligible"
                    :search="eligibleSearch"
                    show-select
                    :items-per-page="200"
                    height="40vh"
                    fixed-header
                    fixed-footer
                    item-key="fss_id"
                  ></v-data-table>
                  <v-btn
                    color="primary"
                    v-if="selectedToInclude.length > 0"
                    @click="addFields"
                    >Add Selected {{ selectedToInclude.length }} Field(s) ({{
                      toAddAcreage.toFixed(1)
                    }}
                    ac) to Program
                  </v-btn>
                </v-card>
                <v-card flat v-if="item.index == 2">
                  <v-data-table
                    v-model="summaryDataArray"
                    :headers="supplySummaryHeaders"
                    :items="summaryDataArray"
                    item-key="channelPartner"
                    fixed-header
                    :items-per-page="200"
                    height="40vh"
                    fixed-footer
                  >
                    <template slot="item.channelPartner" slot-scope="props">
                      <span class="hover" @click="handleOpenAllotment">
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              {{ props.item.channelPartner }}
                            </div>
                          </template>
                          <span
                            >Click to Manage Channel Partner Allocations for
                            this Subprogram</span
                          >
                        </v-tooltip>
                      </span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <div
            v-if="showLoading"
            class="mt-3 ml-6 d-flex justify-content-center"
          >
            <div class="text-bold mb-0">Fetching Data</div>
            <div class="spinner-border ml-4" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else class="pa-4">
            <h5 v-if="fieldSupplyEligible.length < 1 && fieldSupply.length < 1">
              <b>No Fields Currently Available to Add or Remove</b>
            </h5>
          </div>
        </v-card>

        <ArvaModalSaveFooter
          confirmText="Return"
          cancelText="Cancel"
          @on-cancel="handleCloseModal"
          @on-confirm="handleSubmit"
        />
      </div>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "EditFieldAssignmentModal",
  props: ["updateOrderID", "updateOrderName"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      tab: 0,

      showError: false,
      orderSupplyTarget: 0,
      orderSupplyType: null,
      errorText: "Please Provide an Unique Order Name",
      fieldSupplyEligible: [],
      fieldSupply: [],
      selectedToRemove: [],
      selectedToInclude: [],
      includedSearch: "",
      eligibleSearch: "",
      totalAcreageInProgram: 0,
      totalAcreageEligible: 0,
      showLoading: false,
      insetOrderRollupCO2e: null,
      insetOrderRollupName: null,
      summaryDataArray: [],
      insetOrderRollupAcreSummary: "",
      supplySummaryHeaders: [
        { text: "Channel Partner", value: "channelPartner" },
        { text: "Total Fields", value: "num_fields" },
        { text: "Available Supply", value: "supply_available" },
        { text: "Allocated Supply", value: "supply_allocated" },
      ],
      supplyHeaders: [
        { text: "Field ID", value: "field_id" },
        { text: "Field", value: "field" },
        //{ text: "Client", value: "client" },
        { text: "Legal Enitity", value: "legal_entity", width: "8%" },
        { text: "Channel Partner", value: "channel_partner" },
        { text: "Status", value: "status" },
        { text: "Acreage", value: "acreage" },
        { text: "CO2e/ac", value: "average_co2e" },
        { text: "Total CO2e", value: "total_co2e" },
        //{ text: "Crop", value: "crop" },
        //{ text: "Enroll ID", value: "enrollment_id" },
      ],
    }
  },

  computed: {
    toAddAcreage() {
      return this.selectedToInclude
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
    toRemoveAcreage() {
      return this.selectedToRemove
        .map(e => e.acreage)
        .reduce((a, b) => a + b, 0)
    },
    tabTitles() {
      let tabNames = []
      if (this.fieldSupply.length > 0) {
        tabNames.push({
          index: 0,
          name:
            "Allocated Fields" +
            " (" +
            this.totalAcreageInProgram.toFixed(2) +
            "ac" +
            ")",
        })
      }
      if (this.fieldSupplyEligible.length > 0) {
        tabNames.push({
          index: 1,
          name:
            "Eligible Fields" +
            " (" +
            (this.totalAcreageEligible.toFixed(2) + "ac" + ")"),
        })
      }

      if (this.fieldSupply.length > 0 || this.fieldSupplyEligible.length > 0) {
        tabNames.push({ index: 2, name: "Supply Summary" })
      }
      return tabNames
    },
    subprogramAcreageSummary() {
      return (
        this.totalAcreageInProgram.toFixed(2) + " / " + this.orderSupplyTarget
      )
    },
  },

  methods: {
    handleOpenAllotment() {
      this.$emit("open-allotment")
    },
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      this.$emit("close-modal")
    },

    removeFields() {
      let payload = {
        fieldSupplyIds: this.selectedToRemove.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "remove",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToRemove = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },
    addFields() {
      this.showError = false
      let payload = {
        fieldSupplyIds: this.selectedToInclude.map(e => e.fss_id),
        order_id: this.updateOrderID,
        job_type: "add",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.refreshFSSList()
            this.selectedToInclude = []
            //this.$emit("save-from-modal")
          } else {
            this.errorText = response.data
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          this.showError = true
        })
    },

    refreshFSSList() {
      this.showLoading = true
      this.fieldSupplyEligible = []
      this.fieldSupply = []
      this.totalAcreageEligible = 0
      this.totalAcreageInProgram = 0
      CarbonReadyAPI.getEAOrderFSS({
        order_id: this.updateOrderID,
        restrict_corporation: 0,
      }).then(response => {
        this.getInsetRollup()

        this.fieldSupplyList = response.data

        let summaryDataDict = {}
        this.fieldSupplyList.forEach(fss => {
          //let loi_name = enroll["loi_entity_name"]
          //enroll["fieldsupply"].forEach(fss => {
          let entity = null
          if (fss["contractgroup_yessupply"].length > 0) {
            entity = fss["contractgroup_yessupply"][0].name
          } else if (fss["contractgroup_nosupply"].length > 0) {
            entity = fss["contractgroup_nosupply"][0].name
          }

          let fss_dict = {
            field: fss["fieldcrop"]["field_name"],
            field_id: fss["fieldcrop"]["field_id"],
            client: fss["client"],
            legal_entity: entity,
            crop: fss["fieldcrop"]["crop_name"],
            year: fss["fieldcrop"]["year"],
            fss_id: fss["id"],
            status: fss["status"],
            channel_partner: fss["channel_partner_name"],
            acreage: fss["fieldcrop"]["acreage"],
            average_co2e: fss["scope3_emission"],
            total_co2e: (
              fss["fieldcrop"]["acreage"] * fss["scope3_emission"]
            ).toFixed(0),
            //loi_entity: loi_name,
            //enrollment_id: enroll["id"],
          }
          let addToSummary = true
          let inCurrentProgram = false
          let this_supply_amt = 0

          if (this.orderSupplyType == "acreage") {
            this_supply_amt = fss["fieldcrop"]["acreage"]
          } else {
            this_supply_amt = fss["fieldcrop"]["acreage"] * fss["average_co2e"]
          }

          if (fss["matched_program"] == null) {
            this.fieldSupplyEligible.push(fss_dict)

            this.totalAcreageEligible += this_supply_amt
          } else if (fss["matched_program"] == this.updateOrderID) {
            inCurrentProgram = true
            this.fieldSupply.push(fss_dict)
            this.totalAcreageInProgram += this_supply_amt
          } else {
            addToSummary = false
          }

          if (addToSummary) {
            if (summaryDataDict.hasOwnProperty(fss["channel_partner_name"])) {
              summaryDataDict[fss["channel_partner_name"]][
                "supply_available"
              ] += this_supply_amt
              summaryDataDict[fss["channel_partner_name"]][
                "supply_allocated"
              ] += inCurrentProgram ? this_supply_amt : 0

              summaryDataDict[fss["channel_partner_name"]]["num_fields"] += 1
            } else {
              summaryDataDict[fss["channel_partner_name"]] = {
                channelPartner: fss["channel_partner_name"],
                supply_available: this_supply_amt,
                supply_allocated: inCurrentProgram ? this_supply_amt : 0,
                num_fields: 1,
              }
            }
          }
        })

        this.summaryDataArray = []
        for (let key in summaryDataDict) {
          let tempVal = summaryDataDict[key]
          tempVal["supply_available"] =
            summaryDataDict[key]["supply_available"].toFixed(0)
          tempVal["supply_allocated"] =
            summaryDataDict[key]["supply_allocated"].toFixed(0)
          this.summaryDataArray.push(tempVal)
        }

        this.showLoading = false
      })
    },

    getInsetRollup() {
      if (this.updateOrderID != null) {
        let params = { order_id: this.updateOrderID }

        CarbonReadyAPI.getInsetOrderRollup(params).then(response => {
          this.insetOrderRollupName = response.data.name
          this.insetOrderRollupCO2e = response.data.average_co2e
          if (
            response.data.acres_allocated != undefined &&
            response.data.acres_allotted != undefined
          ) {
            this.insetOrderRollupAcreSummary =
              response.data.acres_allocated.toLocaleString() + //so would prb want to be able to switch this based on type
              " / " +
              response.data.acres_allotted.toLocaleString()
          }
        })
      }
    },
  },

  mounted() {
    if (this.updateOrderID != null) {
      CarbonReadyAPI.getInsetOrder({ id: this.updateOrderID }).then(
        response => {
          this.orderSupplyTarget =
            response.data["params"]["supply_target"]["value"]

          this.orderSupplyType =
            response.data["params"]["supply_target"]["type"]

          this.refreshFSSList()
          this.getInsetRollup()
        }
      )
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
</style>
