<template>
  <div :class="`${view == CSM_VIEW ? 'superuser-view' : ''} enrollment-detail-container`">
    <Permissions package="inset-enrollment" :superUserOnly="false" />

    <!--
    <LetterOfIntentModal
      v-if="openLOIModal"
      :data="dataForLOIModal"
      @close-modal="handleLOIModalClose"
      @generate-loi="generateLOI"
    />
    -->

    <div class="pb-0 pb-md-4 position-relative contract-group-main-content">
      <div
        v-if="showBottomActionBar"
        class="position-absolute pl-8 py-4 d-flex align-center justify-space-between bottom-action-bar"
      >
        <div class="d-flex">
          <p :class="`mb-0 pr-4 mr-4 ${pending['acreage'] < 0 ? 'pending-acreage-decrease' : 'pending-acreage-increase'}`">
            {{ pending['acreage'] < 0 ? '' : '+' }} {{ numberToLocale(Math.floor(pending['acreage'] * 100) / 100) }}
          </p>

          <p class="mb-0">{{ pending['numFields'] }} Fields</p>
        </div>

        <div>
          <v-btn
            height="44"
            class="mr-4"
            outlined
            v-if="fssData['snapshot'] != null"
            :disabled="cancelDisabled"
            @click="cancelSupplyChanges()"
            v-amplitude="['enrollmentGroupCancel', fssData]"
          >
            Cancel
          </v-btn>

          <v-btn
            outlined
            :disabled="submissionsDisabled"
            @click="submitSupplyChanges({ groupId, clientId })"
            v-amplitude="['enrollmentGroupRequestsSubmit', fssData]"
          >
            Send field selections for review
          </v-btn>
        </div>
      </div>

      <div class="d-flex justify-space-between align-center mb-2 mb-md-4">
        <div class="d-flex align-center">
          <router-link to="/enrollment-groups">
            <v-icon class="back-icon">mdi-chevron-left</v-icon>
          </router-link>
          <h1 class="mb-0 font-weight-bold">
            {{
              enrollmentTableLoading['loading']
                ? 'Loading group data...'
                : `${isClientBased ? 'Client: ' + fssData['name'] : 'Legal Entity: ' + fssData['name']}`
            }}
          </h1>
        </div>

        <div>
          <v-btn class="mr-3" :ripple="false" outlined href="https://www.loom.com/share/0ab36823ce6c4445b1fe35caa5bc3b53?sid=4a5d6533-732a-46e5-99f8-ecca64d33a2a" target='_blank'>
            <v-icon>mdi-play-outline</v-icon>
            3 Min Training
          </v-btn>

          <v-menu offset-y v-if="isSuperuser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-3" :ripple="false" outlined v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-down</v-icon>
                <span class="ml-1">{{ view == CSM_VIEW ? 'CSM View' : 'CP View' }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group :value="view" @change="setView" mandatory color="primary">
                <v-list-item>
                  <v-list-item-title>CP View</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>CSM View</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
  
      <RollupTable
        :selectedRegions="selectedRegions"
        :selectedCrops="selectedCrops"
        @updateSelected="handleUpdateSelected"
        @updateRollupTableHeight="updateRollupTableHeight"
      />

      <v-card outlined class="px-3 py-1 px-md-6 py-md-3">
        <v-progress-circular v-if="enrollmentTableLoading['loading']" indeterminate :size="48" color="#79c61c" />

        <div v-if="enrollmentTableLoading['error'] != null">
          <p>Error: {{ enrollmentTableLoading['error'] }}</p>
        </div>

        <v-data-table
          v-else
          hide-default-footer
          disable-pagination
          :show-select="isClientBased"
          :class="`enrollment-table ${enrollmentTableLoading['loading'] ? 'loading' : ''}`"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          :headers="headers"
          :items="items"
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :modelValue="selectedItems"
          @input="(items) => selectedItems = items.map(({ id }) => id)"
        >
          <template v-slot:top="props">
            <div v-bind="props" class="d-flex justify-space-between align-center mb-1 mb-md-4">
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ props.items.length }} Field{{ props.items.length == 1 ? '' : 's' }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>
              <div class="d-flex justify-end">
                <div id="region-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    v-model="selectedRegions"
                    attach="#region-selector"
                    placeholder="All Regions"
                    :items="allUniqueRegions"
                    :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Regions <span>{{ selectedRegions.length }}</span>
                        </p>
                      </div>
                    </template>
  
                    <template v-slot:item="{ on, attrs, item }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active" />
                          </v-list-item-action>
  
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ regionAbbrevToFull(item) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>
                
                <div id="crop-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    item-value="itemKey"
                    v-model="selectedCrops"
                    attach="#crop-selector"
                    placeholder="All Crops"
                    :items="allUniqueCrops"
                    :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Crops <span>{{ selectedCrops.length }}</span>
                        </p>
                      </div>
                    </template>
  
                    <template v-slot:item="{ on, attrs, item }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active" />
                          </v-list-item-action>
  
                          <v-list-item-content>
                            <v-list-item-title v-if="item['id'] == 4 && item['harvest_type'] == 'Silage'">
                              Corn (Silage)
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              {{ CROP_DISPLAY_NAME[item['id']] }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>

                <div id="farm-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    v-model="selectedFarms"
                    attach="#farm-selector"
                    placeholder="All Farms"
                    :items="allUniqueFarms"
                    :class="`mr-2 ${selectedFarms.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Farms <span>{{ selectedFarms.length }}</span>
                        </p>
                      </div>
                    </template>
                  </v-select>
                </div>

                <div id="status-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    v-model="selectedStatuses"
                    attach="#status-selector"
                    placeholder="All Statuses"
                    :items="allStatuses"
                    :class="`mr-2 ${selectedStatuses.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Statuses <span>{{ selectedStatuses.length }}</span>
                        </p>
                      </div>
                    </template>
  
                    <template v-slot:item="{ on, attrs, item }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
  
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ STATUS_MAP[item]['text'] }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:header.requestEnrollment="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>This will request this field is added to your enrollment for this region and crop.</span>
            </v-tooltip>
          </template>

          <template v-slot:header.practicesDetail="props">
            <span>{{ props.header['text'] }}</span>
            <v-tooltip content-class="tooltip-no-transition" top max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>These are the sustainable practices we have data for in our system for this field and crop. If you see something missing, it can be updated in the management practices or management practices worksheet found under the List View of Fields.</span>
            </v-tooltip>
          </template>

          <template v-slot:item.field="props">
            <div class="d-flex align-center py-0 py-md-3">
              <img class="thumbnail" :src="props.value['thumbnail']" @error="(e) => e.target.src = fallback">
              <p class="mb-0 ml-2 ml-md-4 field-name">{{ props.value['name'] }}</p>
            </div>
          </template>

          <template v-slot:item.region="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>

          <template v-slot:item.crops="props">
            <div v-bind="props">
              <p v-if="props.value['cropId'] == 4 && props.value['harvest_type'] == 'Silage'" class="mb-0 capitalize">
                Corn (Silage)
              </p>
              <p v-else class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>

          <template v-slot:item.acres="{ value }">
            <div class="text-end">
              {{ value }}
            </div>
          </template>
          
          <template v-slot:item.status="{ value }">
            <v-chip :color="STATUS_MAP[value['value']]['color']">
              {{ STATUS_MAP[value['value']]['text'] }}
            </v-chip>
          </template>

          <template v-slot:item.requestEnrollment="{ item }">
            <div :class="
              `d-flex custom-toggler
              ${fssLoading.includes(item['id']) ? 'loading' : ''}
              ${confirmedFSSCrops.includes(item['crops']['cropId']) && view != CSM_VIEW ? 'disabled' : ''}
              `
            ">
              <v-progress-circular v-if="fssLoading.includes(item['id'])" indeterminate :size="24" color="#79c61c" />
              <a
                v-amplitude="['enrollmentGroupYesToggle', item]"
                @click="handleSelectSupplyState(item['id'], YES)"
                :class="`px-2 py-1 px-md-4 py-md-3 d-flex align-center justify-center w-100 ${getTogglerClass(item['id'], YES)}`"
              >
                Yes
              </a>
              <a
                v-amplitude="['enrollmentGroupNoToggle', item]"
                @click="handleSelectSupplyState(item['id'], NO)"
                :class="`px-2 py-1 px-md-4 py-md-3 d-flex align-center justify-center w-100 ${getTogglerClass(item['id'], NO)}`"
              >
                No
              </a>
            </div>
          </template>

          <template v-slot:item.practicesDetail="props">
            <p class="practice-text">{{ props.value.join(', ') }}</p>
          </template>

          <template v-slot:item.inSupplyShed="{ item }">
            {{ item['inSupplyShed'] ? 'Yes' : 'No' }}
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
// TODO:
// entity view is no editing, no interaction, show the data.
// entity view - add client as a column
// client view is interactable

import fallback from "@/assets/images/fallback.png"
import Permissions from "@/components/permissions/Permissions"
import RollupTable from "@/components/contractGroups/RollupTable.vue"
import LetterOfIntentModal from "@/components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"
import { numberToLocale, regionAbbrevToFull, getMinutesCopySinceDate } from "@/utility"
import { EnrollmentGroups } from "@/store/modules"
import { mapActions, mapState, mapMutations } from "vuex";
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EVIDENCING_TYPE_CHOICES } from "@/constants/defaults"
import {
  NO,
  YES,
  OPEN,
  LOCKED_FOR_PAYMENT,
  CSM_VIEW,
  CONFIRMED,
  AVAILABLE,
  REQUESTED,
  STATUS_MAP,
  FSS_INDIVIUAL,
  ENROLLMENT_TABLE,
  REQUESTED_PENDING,
} from "@/constants/contractGroups"

// temp constants
const HEADERS = [
  {
    text: "Field", value: "field", class: 'xxlarge-cell', filter: v =>
      v['selectedFarms'].length == 0 || v['selectedFarms'].includes(v['farm'])
  },
  {
    text: "Region", value: "region", class: 'small-cell', filter: v =>
      v['selectedRegions'].length == 0 || v['selectedRegions'].includes(v['value'])
  },
  {
    text: "Crop", value: "crops", sortable: false, class: 'medium-cell', filter: v => {
      if (v['selectedCrops'].length == 0) return true
      for (const itemKey of v['selectedCrops']) {
        const [cropId, harvest_type] = itemKey.split('-')
        if (v['cropId'] == Number(cropId)) {
          if (Number(cropId) != 4) return true
          if (harvest_type == 'null' && v['harvest_type'] == null) return true
          if (harvest_type == v['harvest_type']) return true
        }
      }
      return false
    }
  },
  { text: "Acres", value: "acres", class: 'xsmall-cell' },
  { text: "Request Enrollment", value: "requestEnrollment", sortable: false, class: 'large-cell' },
  {
    text: "Status", value: "status", class: 'large-cell', filter: v =>
      v['selectedStatuses'].length == 0 || v['selectedStatuses'].includes(v['value'])
  },
  { text: "Practices", value: "practices", class: 'small-cell' },
  { text: "Practices", value: "practicesDetail", class: 'huge-cell' },
]

export default {
  name: "ContractGroupDetail",
  components: {
    Permissions,
    RollupTable,
    LetterOfIntentModal,
  },
  mounted() {
    if (this.fssData == null) {
      this.fetchRollup({ fetchOverview: true })
      .then(() => {
        this.fetchFssMetadata({ groupId: this.groupId, clientId: this.clientId })
      })
    }
    else {
      this.fetchFssMetadata({ groupId: this.groupId, clientId: this.clientId })
    }
  },
  destroyed() {
    clearInterval(this.minutesInterval)
  },
  data() {
    return {
      selectedView: null,
      minutesInterval: null,
      minutesSince: '',
      search: '',
      selectedItems: [],
      selectedCrops: [],
      selectedFarms: [],
      selectedRegions: [],
      selectedStatuses: [],
      rollupTableHeight: 58,
      openLOIModal: false,
      dataForLOIModal: null,
      HEADERS,
      OPEN,
      AVAILABLE,
      REQUESTED,
      REQUESTED_PENDING,
      LOCKED_FOR_PAYMENT,
      fallback,
      STATUS_MAP,
      allStatuses: [AVAILABLE, REQUESTED_PENDING, REQUESTED, CONFIRMED, LOCKED_FOR_PAYMENT],
      YES,
      NO,
      CSM_VIEW,
      CROP_DISPLAY_NAME,
      numberToLocale
    }
  },
  computed: {
    ...mapState({
      isSuperuser: state => state.User.user != null && state.User.user['is_superuser'],
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      fssLoading: state => state.EnrollmentGroups[FSS_INDIVIUAL],
      view: state => state.EnrollmentGroups.view,
      fssData: function(state) {
        return !this.isClientBased
          ? state.EnrollmentGroups.groupData[this.groupId]
          : state.EnrollmentGroups.clientData[this.clientId]
      },
    }),
    headers() {
      const h = [...HEADERS]
      if (!this.isClientBased) {
        h.splice(-4, 1)
        h.splice(-2)
        h.splice(2, ...[
          { text: "Acres", value: "acres", class: 'xsmall-cell' },
          { text: "Client", value: "client", class: 'medium-cell' }
        ])
      }

      // 01/24 Ariah requested to tempoprary hide the "In Supply Shed" column
      // if (this.isSuperuser) {
      //   h.splice(3, ...[
      //     { text: "Acres", value: "acres", class: 'xsmall-cell' },
      //     { text: "In Supply Shed", value: "inSupplyShed", class: 'small-cell', sortable: false }
      //   ])
      // }

      return h
    },
    isClientBased() {
      return this.$router.currentRoute.path.includes('client') 
        && this.$router.currentRoute.params['clientId'] != null
    },
    confirmedFSSCrops() {
      if (this.fssData == null) return []
      
      return this.fssData['fieldsupply'].reduce((accum, curr) => {
        if (curr['status'] == CONFIRMED && !accum.includes(curr['crop_id'])) {
          accum.push(curr['crop_id'])
        }

        return accum
      }, [])
    },
    groupId() {
      return this.$router.currentRoute.params['id']
    },
    clientId() {
      return this.$router.currentRoute.params['clientId']
    },
    submissionsDisabled() {
      return this.fssData['supplyChanges'][YES].length == 0 && this.fssData['supplyChanges'][NO].length == 0
    },
    cancelDisabled() {
      if (this.submissionsDisabled) return true
      if (this.snapshottedChangedFSS.length == 0) return true
      return false
    },
    snapshottedChangedFSS() {
      // check if at least one fss is in the snapshot and in the changes obj
      // cancel should not apply to fss that is a 'change' but not in the snapshot
      const { fieldsupply, supplyChanges, snapshot } = this.fssData
      return fieldsupply.filter(({ id }) => {
        const isChange = supplyChanges[YES].includes(id) || supplyChanges[NO].includes(id)
        const inSnapshot = snapshot['yes_fieldsupply'].includes(id) || snapshot['no_fieldsupply'].includes(id)
        return isChange && inSnapshot
      })
    },
    showBottomActionBar() {
      return this.fssData != null && !this.submissionsDisabled
    },
    pending() {
      const result = { acreage: 0, numFields: 0 }

      if (this.fssData == null) return result

      const { acreageValues } = this.fssData
      
      for (const shim of [null, "Silage"]) {
        for (const cropId in acreageValues[REQUESTED_PENDING]) {
          for (const region in acreageValues[REQUESTED_PENDING][cropId][shim]) {
            result['acreage'] += acreageValues[REQUESTED_PENDING][cropId][shim][region]['acreage']
            result['numFields'] += acreageValues[REQUESTED_PENDING][cropId][shim][region]['numFields']
          }
        }
      }

      return result
    },
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 32 + 60 + 12

      const rollupHead = 58

      const barOffset = this.showBottomActionBar ? 76 : 0

      // 24px of artificial padding at bottom
      const extra = 30

      return external + internal + rollupHead + barOffset + extra
    },
    allUniqueRegions() {
      if (this.fssData == null) return []

      const { fieldsupply } = this.fssData
      const regs = []

      for (const { state_name } of fieldsupply) {
        const found = US_STATES.find(({ text }) => text == state_name)
        if (found == null && !regs.includes('Canada')) regs.push('Canada')
        if (found != null && !regs.includes(found['value'])) regs.push(found['value'])
      }

      regs.sort()

      return regs
    },
    allUniqueFarms() {
      if (this.fssData == null) return []

      const { fieldsupply } = this.fssData
      const allFarms = []

      for (const { metadata: { farmName } } of fieldsupply) {
        if (!allFarms.includes(farmName)) allFarms.push(farmName)
      }

      return allFarms.sort()
    },
    allUniqueCrops() {
      if (this.fssData == null) return []

      return this.fssData['crops']
      .reduce((accum, { id, harvest_type }) => {
        const found = accum.find(c => c['id'] == id)
        
        if (found == null) accum.push({ id, harvest_type: null, itemKey: `${id}-null` })
        else if (
          found['id'] == 4
          && accum.find(c => c['id'] == 4 && c['harvest_type'] == 'Silage') == null
          && harvest_type == 'Silage'
        ) {
          accum.push({ id, harvest_type, itemKey: `${id}-${'Silage'}` })
        }

        return accum
      }, [])
      .sort((a, b) => CROP_DISPLAY_NAME[a['id']].localeCompare(CROP_DISPLAY_NAME[b['id']]))
    },
    items() {
      if (this.fssData == null) return []

      const { fieldsupply } = this.fssData

      return fieldsupply.map(({ id, status, harvest_type, state_name, client_name, crop_id, field_name, acreage, metadata }) => {
        const foundRegion = US_STATES.find(({ text }) => text == state_name)
        return {
          id,
          field: {
            name: field_name,
            thumbnail: metadata['thumbnail'],
            farm: metadata['farmName'],
            selectedFarms: this.selectedFarms
          },
          acres: acreage.toFixed(2),
          client: client_name,
          crops: {
            harvest_type: harvest_type == "Silage" ? "Silage": null,
            cropId: crop_id,
            value: CROP_DISPLAY_NAME[crop_id],
            selectedCrops: this.selectedCrops
          },
          region: {
            value: foundRegion != null ? foundRegion['value'] : state_name,
            selectedRegions: this.selectedRegions
          },
          status: {
            value: status,
            selectedStatuses: this.selectedStatuses
          },
          inSupplyShed: metadata['orderIds'] > 0,
          practices: metadata['practices'].length,
          practicesDetail: metadata['practices']
            .map(p => p.includes(': ') ? p.split(': ')[1] : p)
            .map(p => EVIDENCING_TYPE_CHOICES.find(({ value }) => p == value || null))
            .filter(p => p)
            .map(p => p['name']),
          submitDate: null,
          deadline: null
        }
      })
      .sort((a, b) => a['crops']['value'].localeCompare(b['crops']['value']))
      .sort((a, b) => a['field']['name'].localeCompare(b['field']['name']))
    }
  },
  methods: {
    getMinutesCopySinceDate,
    regionAbbrevToFull,
    ...mapActions({
      fetchRollup: EnrollmentGroups.Actions.fetchRollup,
      applyPendingFssState: EnrollmentGroups.Actions.applyPendingFssState,
      submitSupplyChanges: EnrollmentGroups.Actions.submitSupplyChanges,
      fetchFssMetadata: EnrollmentGroups.Actions.fetchFssMetadata,
    }),
    ...mapMutations({
      setView: EnrollmentGroups.Mutations.setView
    }),
    cancelSupplyChanges() {
      const supplyChanges = this.snapshottedChangedFSS.reduce((accum, { supply_state, id }) => {
        if (!accum.hasOwnProperty(YES)) accum[YES] = []
        if (!accum.hasOwnProperty(NO)) accum[NO] = []

        const otherState = supply_state == YES ? NO : YES
        accum[otherState].push(id)

        return accum
      }, {})

      for (const supplyState in supplyChanges) {
        this.applyPendingFssState({
          supplyState,
          fssIds: supplyChanges[supplyState],
          groupId: this.groupId,
          clientId: this.clientId
        })
      }
    },
    handleSelectSupplyState(fssId, supplyState) {
      // if yes/no was clicked and it's already confirmed, don't run it
      if (this.getTogglerClass(fssId, supplyState).includes('selected-confirmed')) return
      
      const fssIds = [fssId]
      
      if (this.selectedItems.length > 0) 
      fssIds.push(...this.selectedItems.filter(id => id != fssId))

      this.applyPendingFssState({
        fssIds,
        supplyState,
        groupId: this.groupId,
        clientId: this.clientId
      })
    },
    getTogglerClass(fssId, state) {
      // TODO: need to make this computed and not an actual call every time
      const otherState = state == YES ? NO : YES

      // check if is a change
      // if not a change, it's confirmed
      if (this.fssData['supplyChanges'][state].includes(fssId)) return 'selected-pending'
      else if (this.fssData['supplyChanges'][otherState].includes(fssId)) return 'not-selected'
      else if (this.fssData['fieldsupply'].find(({ id, supply_state }) => id == fssId && supply_state == otherState)) return 'not-selected'
      return `selected-confirmed-${state}`
    },
    handleUpdateSelected({ crops, region }) {
      if (
        crops.every(c => this.selectedCrops.includes(c))
        && crops.length == this.selectedCrops.length
        && region.every(r => this.selectedRegions.includes(r))
        && region.length == this.selectedRegions.length
      ) {
        this.selectedCrops = []
        this.selectedRegions = []
      }
      else {
        this.selectedCrops = crops
        this.selectedRegions = region
      }
    },
    updateRollupTableHeight(height) {
      this.rollupTableHeight = height
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true

      const fieldName = item['field']['name'].toLowerCase()
      if (fieldName.includes(search)) return true
      return false
    },
    handleLOIModalOpen(data) {
      this.dataForLOIModal = {
        ...data,
        org: this.org,
      }
      this.openLOIModal = true
    },
    handleLOIModalClose() {
      this.dataForLOIModal = null
      this.openLOIModal = false
    },
    async generateLOI(payload) {
      //
      // TEMP
      //
      return
      const appendedPayload = {
        ...payload,
        fss_ids: this.groupData["fieldsupply"].map(({ id }) => id),
        year: this.groupData["year"],
      }

      this.openLOIModal = false
      this.showLoading = true
      FieldsAPI.generateLOI(appendedPayload).then(r => {
        alert("LOI Created!")
        // this.fetchClientProfile()
        // this.showLoading = false
        // this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        // this.showSuccessAlert = true
        // setTimeout(() => {
        //   this.showSuccessAlert = false
        // }, 10000)
      })
    }
  },
  watch: {
    org() {
      this.fetchRollup({ fetchOverview: true })
      .then(() => {
        this.fetchFssMetadata({ groupId: this.groupId, clientId: this.clientId })
      })
    },
    year(curr, prev) {
      if (curr != null && prev != null) {
        this.fetchRollup({ fetchOverview: true })
        .then(() => {
          this.fetchFssMetadata({ groupId: this.groupId, clientId: this.clientId })
        })
      }
    }
  }
}
</script>

<style>
.tooltip-no-transition {
  transition: none !important;
}
</style>

<style scoped>
:deep(th i) {
  margin-left: 4px;
  font-size: 16px !important;
}
.contract-group-main-content {
  min-height: calc(100vh - 82px);
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  z-index: 1;
}
.capitalize {
  text-transform: capitalize;
}
p {
  color: #000000;
}
.superuser-view a:has(+h1) i {
  color: #ffffff;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  transition: color 0.25s;
}
.superuser-view h1 {
  color: #ffffff;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}

.enrollment-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  background: #FFFFFF;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox) {
  width: 24px;
  margin: 0 auto !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox i) {
  margin-left: 0 !important;
}
:deep(.v-simple-checkbox i::before) {
  font-size: 24px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table:not(.rollup-table) :deep(tr > th:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(2)) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-chip.v-size--default {
  height: 20px !important;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.field-name {
  width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
}
.enrollment-table :deep(table) {
  table-layout: fixed;
}
.enrollment-table :deep(th:nth-of-type(1):not([class*="-cell"])) {
  width: 64px !important;
}
.enrollment-table :deep(th.huge-cell) {
  width: 500px;
}
.enrollment-table :deep(th.xxlarge-cell) {
  width: 250px;
}
.enrollment-table :deep(th.xlarge-cell) {
  width: 200px;
}
.enrollment-table :deep(th.large-cell) {
  width: 175px;
}
.enrollment-table :deep(th.medium-cell) {
  width: 150px;
}
.enrollment-table :deep(th.small-cell) {
  width: 125px;
}
.enrollment-table :deep(th.xsmall-cell) {
  width: 100px;
}
.enrollment-table :deep(tr td:nth-of-type(9)) {
  padding: 0 4px;
}
.enrollment-table :deep(tr td:nth-of-type(8) > p) {
  color: #4B5563;
  font-size: 14px;
  line-height: 16px;
}
.v-chip :deep(span) {
  font-size: 12px;
}
.custom-toggler {
  position: relative;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  width: 148px;
  overflow: hidden;
}
.custom-toggler.disabled a {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.custom-toggler a {
  background-color: #FFFFFF;
  color: #374151;
  transition: background-color 0.25s color 0.25s;
}
.custom-toggler a:first-of-type {
  border-right: 1px solid #E5E7EB;
}
.custom-toggler a:hover {
  opacity: 0.75;
}
.custom-toggler a[class*=selected-] {
  color: #FFFFFF;
}
.custom-toggler a.selected-confirmed-yes {
  background-color: #61AF02;
}
.custom-toggler a.selected-confirmed-no {
  background-color: #64748B;
}
.custom-toggler a.selected-pending {
  background-color: #0E7490;
}
.custom-toggler .v-progress-circular {
  position: absolute;
  left: calc(50% - 12px);
  top: calc(50% - 12px);
  z-index: 0;
}

/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-input.v-select :deep(.v-select__slot label) {
  top: 7px;
}
.v-input.v-select :deep(.v-input__append-inner) {
  margin-top: 0px !important;
}
.v-chip.gray {
  background: #F3F4F6 !important;
}
.v-chip.gray :deep(span) {
  color: #374151;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.custom-icon {
  font-size: 28px;
}
.back-icon {
  font-size: 32px;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
.bottom-action-bar {
  z-index: 1;
  left: -30px;
  bottom: -2px;
  background: #3F3F46;
  width: calc(100% + 60px);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding-right: 84px;
}
.bottom-action-bar button:first-of-type {
  color: #61B100 !important;
  border-color: #61B100;
  background-color: #3F3F46;
}
.bottom-action-bar button:last-of-type {
  color: #FFFFFF !important;
  border-color: #61B100;
  background-color: #61B100;
}
.bottom-action-bar p {
  color: #E5E7EB;
  font-size: 24px;
  line-height: 32px;
}
.bottom-action-bar .pending-acreage-increase {
  color: #C4E197;
}
.bottom-action-bar .pending-acreage-decrease {
  color: #FECACA;
}
.bottom-action-bar p:first-of-type {
  border-right: 2px solid rgba(255, 255, 255, 0.05);
}
::v-deep(.v-input__control input) {
  cursor: pointer;
}
.v-btn.no-click-events {
  pointer-events: none;
}
.v-btn p {
  margin: 0;
  line-height: 1.125;
  text-align: left;
  font-weight: normal;
}
.practice-text {
  margin: 0;
  color: #4B5563;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
#crop-selector, #region-selector, #farm-selector, #status-selector {
  position: relative;
}

.confirmed-green {
  background: #F0FDF4 !important;
  color: #22C55E;
}

@media screen and (max-width: 1276px) {
  /* just for testing, basically */
  h1 {
    font-size: 24px;
    line-height: 1.5;
  }

  .h-44 {
    height: 36px !important;
  }

  .v-card__title {
    font-size: 20px;
    line-height: 1;
  }

  /* v-select overrides */
  .v-input.v-select {
    height: 20px;
    border-radius: 6px;
    font-size: 16px;
  }
  .v-input.v-select :deep(div[role="button"]) {
    min-height: 20px;
  }
  :deep(.v-select__selections input) {
    padding: 4px 0;
  }

  #region-selector > div,
  #crop-selector > div,
  #farm-selector > div,
  #status-selector > div {
    width: 120px;
  }

  .search-input :deep(.v-input__slot) {
    height: 28px;
  }

  .enrollment-table > :deep(.v-data-table__wrapper) {
    height: calc(calc(100vh - var(--table-height-modifier, 0)) + 166px);
  }

  .enrollment-table :deep(td) {
    padding: 0 8px !important;
    height: 24px !important;
    min-height: unset !important;
  }

  .enrollment-table :deep(th) {
    height: 32px !important; 
    padding: 0 8px !important;
  }

  .enrollment-table :deep(td),
  .enrollment-table :deep(span),
  .enrollment-table :deep(.v-btn__content) {
    font-size: 12px !important;
  }

  table .v-btn:not(.v-btn--round).v-size--default {
    height: 24px;
  }

  .enrollment-detail-container {
    margin-top: 12px !important;
  }

  .thumbnail {
    width: 24px;
    height: 24px;
  }
}
</style>
