<template>
  <ArvaModal
    name="confirmDeleteInsetOrderModal"
    title="Confirm Inset Program Deletion"
    :width="1100"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <h5>
        <span
          >Delete Program
          <v-chip color="green lighten-1">
            {{ currentOrderName }} (ID: {{ currentOrderID }}) </v-chip
          >? This can not be undone!</span
        >
        <br />
        <span>
          If this Program is Already Executed, any Fields Allocated to this
          Program will have to be Reallocated.</span
        >
      </h5>
      <div
        v-if="showLoading"
        class="mt-3 d-flex justify-content-center"
      >
        <div class="text-bold mb-0">Unallocation FSS and Deleting Subprogram</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Processing...</span>
        </div>
      </div>

      <h5 v-if="showError" class="pw-error pa-2">{{ errorText }}</h5>

      <!-- also have a input for order name-->
      <ArvaModalSaveFooter
        confirmText="Confirm Delete"
        cancelText="Cancel"
        @on-cancel="handleCloseModal"
        @on-confirm="handleSubmit"
      />
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

export default {
  name: "ConfirmDeleteInsetOrderModal",
  props: ["currentOrderName", "currentOrderID"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      showError: false,
      showLoading: false,
      errorText: "",
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    handleSubmit() {
      this.showLoading = true
      let payload = { id: this.currentOrderID }
      CarbonReadyAPI.deleteInsetOrder(payload).then(response => {
        this.showLoading = false
        this.$emit("confirm-delete-modal")
       
        
      }).catch(err => {
           
            this.errorText = err.response.data
            this.showError = true
            this.showLoading = false
          })
      
    },
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
