<template>
  <div>
    <Permissions package="sustainability" />
    <div v-if="showSustainability">
      <div class="row">
        <div class="col">
          <div class="d-flex mb-3">
            <DashboardTitle
              :title="`Harvest for ${year}`"
              faClass="fa-columns"
            />
            <v-btn v-once class="ml-auto" text @click="goBack">
              <v-icon>mdi-chevron-left</v-icon>
              Return To Practices</v-btn
            >
          </div>
        </div>
      </div>
      <v-card class="my-4" elevation="0" v-if="showLoadingView">
        <v-progress-circular
          class="mt-4"
          :size="100"
          :width="8"
          color="green"
          indeterminate
        >
        </v-progress-circular>
        <div>
          <h3>Loading harvest information...</h3>
        </div>
      </v-card>
      <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
        <v-card elevation="0">
          <v-card-text class="text-center">
            <span class="text-h4"
              >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using
              the top navigation tools.</span
            >
          </v-card-text>
        </v-card>
      </div>
      <div v-else-if="selectedFields.length > 0">
        <v-card class="my-4" elevation="0" v-if="showSaveView">
          <v-progress-circular
            class="mt-4"
            :size="100"
            :width="8"
            color="green"
            indeterminate
          >
          </v-progress-circular>
          <div>
            <h3>Saving your harvest information...</h3>
          </div>
        </v-card>
        <v-card class="my-4 px-5 py-5" color="grey lighten-4" elevation="0">
          <h3
            v-if="
              editedFieldIds[year]?.size == 0 &&
              summaryFields[year]?.length == 0 &&
              failedFields[year]?.length == 0
            "
          >
            Add your harvest information below.
          </h3>
          <h3 v-if="editedFieldIds[year]?.size > 0">
            Unsaved fields for year {{ year }}
          </h3>
          <div v-if="editedFieldIds[year]?.size > 0">
            <v-chip
              v-for="fieldId in editedFieldIds[year]"
              class="mx-1"
              color="gray lighten-1"
            >
              {{ harvestSettingsByField[fieldIdToSetting[fieldId]].fieldName }}
            </v-chip>
          </div>
          <div v-if="summaryFields[year]?.length > 0">
            <h3>Successfully saved fields for year {{ year }}</h3>
            <v-chip
              v-for="fieldName in summaryFields[year]"
              :key="fieldName"
              class="mx-1"
              color="green"
              text-color="white"
            >
              {{ fieldName }}
            </v-chip>
          </div>
          <div v-if="failedFields[year]?.length > 0">
            <h3>Failed to save fields for year {{ year }}</h3>
            <v-chip
              v-for="fieldName in failedFields[year]"
              :key="fieldName"
              class="mx-2"
              color="red lighten-3"
              text-color="white"
            >
              {{ fieldName }}
            </v-chip>
          </div>
        </v-card>
        <v-row class="mt-4 mb-2 row-margin-correct">
          <!-- v-if showsaveview -->
          <div>
            <span class="text-h5 ml-1"
              >{{ harvestSettingsByField.length }} fields</span
            >
            <span class="text-h5 ml-2"
              >({{ dataCompletionForYear }}% Complete)</span
            >
          </div>
          <div class="ml-auto d-flex">
            <div>
              <v-btn
                v-for="y in yearListModified"
                :key="y.idx"
                :color="
                  parseInt(y) === parseInt(year)
                    ? 'blue-grey lighten-4'
                    : 'white'
                "
                elevation="0"
                tile
                dense
                class="mx-1 py-1"
                @click="handleSeasonSelection(y)"
              >
                {{ y }}
              </v-btn>
            </div>
            <div class="text-container ml-2">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="tableFilterText"
                label="Filter Fields"
              ></v-text-field>
            </div>
          </div>
        </v-row>

        <!-- Quick Filtering Buttons -->
        <v-row class="mt-4 row-margin-correct">
          <v-btn
            dense
            tile
            :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
            @click="handleSelectAll(true, true)"
            class="px-2 mx-2 py-1 text-none letter-spacing-0"
            elevation="0"
          >
            All ({{ harvestSettingsByField.length }})
          </v-btn>
          <v-btn
            v-for="sharedCropPlan in historicalPlantings"
            :key="sharedCropPlan.idx"
            dense
            tile
            :color="
              JSON.stringify(cropSelect) ==
              JSON.stringify(sharedCropPlan.crops.split(','))
                ? 'blue-grey lighten-4'
                : 'white'
            "
            @click="handleCropFilterButton(sharedCropPlan)"
            class="px-2 mx-2 py-1 text-none"
            elevation="0"
          >
            {{ mapCropNames(sharedCropPlan.crops) }} ({{
              sharedCropPlan.fieldIds.length
            }})
          </v-btn>
        </v-row>

        <!-- Field Filtering Table -->
        <v-row class="pt-3 overflow-x-auto">
          <v-col>
            <v-simple-table class="mx-4 mb-14">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex">
                      <v-simple-checkbox
                        color="blue"
                        v-model="selectAllBox"
                        @input="handleSelectAll($event)"
                      ></v-simple-checkbox>
                    </div>
                  </th>
                  <th />
                  <th class="text-overline">Name</th>
                  <th class="text-overline">Farm</th>
                  <th class="text-overline">Locked</th>
                  <th class="text-overline">Crops</th>
                  <th class="text-overline">
                    Harvest Date<span class="red--text">*</span>
                  </th>
                  <th class="text-overline">
                    Yield<span class="red--text">*</span>
                  </th>
                  <th class="text-overline">
                    Unit<span class="red--text">*</span>
                  </th>
                  <th class="text-overline">Residue Removed</th>
                  <th class="text-overline">Residue Burned</th>
                  <th class="text-overline">Type</th>
                  <th class="text-overline">Data Complete</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(field, fieldIndex) in harvestSettingsByFieldFiltered"
                  :key="field.idx"
                  :class="{
                    'light-blue lighten-5': fieldsInSelection.includes(
                      parseInt(field.fieldId)
                    ),
                  }"
                >
                  <td>
                    <v-simple-checkbox
                      color="blue"
                      :value="
                        fieldsInSelection.includes(parseInt(field.fieldId))
                      "
                      :disabled="
                        disableCheckbox(field.noYearData) || field.fieldLocked
                      "
                      @input="fieldChecked(field, $event)"
                    ></v-simple-checkbox>
                  </td>
                  <td>
                    <img :src="field.fieldImg" style="width: 50px" />&nbsp;
                  </td>
                  <td class="low-space">{{ field.fieldName }}</td>
                  <td class="overflow-hidden">{{ field.farmName }}</td>
                  <td>
                    <v-icon
                      size="20"
                      v-if="field.fieldLocked"
                      :color="field.fieldLocked ? 'red' : 'green'"
                    >
                      {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                    </v-icon>
                  </td>
                  <td class="small-column" v-if="'cropNames' in field">
                    <div
                      class="py-2 low-space"
                      v-for="crop in field.cropNames"
                      :key="crop"
                    >
                      {{ crop }}
                    </div>
                  </td>
                  <td v-else>
                    <div>No Crop</div>
                  </td>
                  <td class="py-1 medium-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row"
                        dense
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-menu
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          :ref="'menu' + fieldIndex"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-lazy>
                              <v-tooltip
                                v-if="
                                  harvestInfo.date.source == 'shapefile' &&
                                  harvestInfo.date.value !== null
                                "
                                top
                                >
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-1 my-auto machine-icon"
                                    :src="machineIcon"
                                  />
                                </template>
                                <span>
                                  Data is from a machine source (such as John
                                  Deere) or shapefile import.
                                </span>
                              </v-tooltip>
                            </v-lazy>
                            <v-text-field
                              :disabled="field.fieldLocked"
                              :value="
                                getItemValue(field.fieldId, index, 'date')
                              "
                              @input="
                                setItemValue(
                                  field.fieldId,
                                  index,
                                  'date',
                                  $event
                                )
                              "
                              readonly
                              clearable
                              :hide-details="true"
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            :disabled="field.fieldLocked"
                            :min="yearPriorOne + '-06-01'"
                            :max="yearExtendedOne + '-06-01'"
                            :value="
                              getItemValue(field.fieldId, index, 'date') ||
                              `${year}-09-01`
                            "
                            @input="
                              setItemValue(
                                field.fieldId,
                                index,
                                'date',
                                $event,
                                true
                              )
                              $refs[`menu${fieldIndex}`][index].save()
                            "
                            scrollable
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 sm-md-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row"
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-lazy>
                          <v-tooltip
                            v-if="
                              harvestInfo.yield.source == 'shapefile' &&
                              harvestInfo.yield.value !== null
                            "
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1 my-auto machine-icon"
                                :src="machineIcon"
                              />
                            </template>
                            <span>
                              Data is from a machine source (such as John Deere)
                              or shapefile import.
                            </span>
                          </v-tooltip>
                        </v-lazy>
                        <v-text-field
                          :disabled="field.fieldLocked"
                          clearable
                          class="my-auto yield-field"
                          v-arrow-navigation="'.yield-field'"
                          :value="getItemValue(field.fieldId, index, 'yield')"
                          type=""
                          @input="
                            setItemValue(field.fieldId, index, 'yield', $event)
                          "
                          hide-details="true"
                          :rules="[numberValidator]"
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 medium-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0 d-flex flex-row"
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-lazy>
                          <v-tooltip
                            v-if="
                              harvestInfo.unit.source == 'shapefile' &&
                              harvestInfo.unit.value !== null
                            "
                            top
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <img
                                v-bind="attrs"
                                v-on="on"
                                class="mr-1 my-auto machine-icon"
                                :src="machineIcon"
                              />
                            </template>
                            <span>
                              Data is from a machine source (such as John Deere)
                              or shapefile import.
                            </span>
                          </v-tooltip>
                        </v-lazy>
                        <v-select
                          :disabled="field.fieldLocked"
                          clearable
                          hide-details="true"
                          :items="yieldUnitChoices"
                          item-text="display_name"
                          item-value="value"
                          :value="getItemValue(field.fieldId, index, 'unit')"
                          @input="
                            setItemValue(
                              field.fieldId,
                              index,
                              'unit',
                              $event,
                              true
                            )
                          "
                        />
                      </div>
                    </div>
                  </td>
                  <td class="py-1 sm-md-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0"
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-select
                          :disabled="field.fieldLocked"
                          :value="harvestInfo.residue_removal.value"
                          @input="
                            setItemValue(
                              field.fieldId,
                              index,
                              'residue_removal',
                              $event,
                              true
                            )
                          "
                          :items="[
                            ...residueRemovalOptions,
                            harvestInfo.residue_removal.value,
                          ]"
                          clearable
                          hide-details="true"
                          suffix="%"
                        ></v-select>
                      </div>
                    </div>
                  </td>
                  <td class="py-1 small-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0"
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-select
                          :disabled="field.fieldLocked"
                          clearable
                          hide-details="true"
                          :items="yesNoOptions"
                          item-text="display_name"
                          item-value="value"
                          :value="harvestInfo.residue_burned.value"
                          @input="
                            setItemValue(
                              field.fieldId,
                              index,
                              'residue_burned',
                              $event,
                              true
                            )
                          "
                        />
                      </div>
                    </div>
                  </td>
                  <td class="py-1 medium-column" v-if="!field.noYearData">
                    <div class="d-flex flex-column">
                      <div
                        class="mx-0"
                        v-for="(harvestInfo, index) in field.harvestInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="harvestInfo.idx"
                      >
                        <v-select
                          :disabled="field.fieldLocked"
                          hide-details="true"
                          :items="harvestTypeChoices"
                          item-text="display_name"
                          item-value="value"
                          clearable
                          :value="
                            getItemValue(field.fieldId, index, 'harvest_type')
                          "
                          @input="
                            setItemValue(
                              field.fieldId,
                              index,
                              'harvest_type',
                              $event,
                              true
                            )
                          "
                        />
                      </div>
                    </div>
                  </td>
                  <td class="small-column">
                    <v-chip
                      label
                      v-if="dataCompletionForFields[field.fieldId] == 100"
                      color="green lighten-3"
                      class="font-weight-bold"
                      >Complete</v-chip
                    >
                    <v-chip
                      v-else
                      label
                      class="font-weight-bold"
                      color="yellow lighten-3"
                      >Incomplete</v-chip
                    >
                  </td>

                  <td class="py-2" colspan="100%" v-if="field.noYearData">
                    <div @click="handleShowRedirect(field)">
                      <v-tooltip top
                        ><template v-slot:activator="{ on, attrs }"
                          ><v-icon v-bind="attrs" v-on="on" color="red"
                            >mdi-alert-circle</v-icon
                          ></template
                        ><span
                          >This field doesn't have data for this year. Add
                          another year in the data spreadsheet or upload data
                          from this year.</span
                        ></v-tooltip
                      ><span class="ml-2 cursor red--text text-bold"
                        >No Data for Year</span
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>

        <!-- No Fields Selected Alert -->
        <AlertErrorModal
          v-if="alertErrorModalOpen"
          titleText="No Fields Selected"
          :errorMessage="[
            'You currently have no fields selected to save management practices for. Please select a field to continue.',
          ]"
          @close-modal="alertErrorModalOpen = false"
        />

        <!-- Redirect -->
        <WizardRedirectModal
          v-if="showRedirectModal"
          :redirectField="redirectField"
          :year="year"
          @close-template-modal="handleCloseRedirect"
          @redirect-confirm="handleRedirectConfirm"
        />

        <ConfirmModalWithCheckbox
          v-if="showSaveConfirmModal"
          :titleText="distributeConfirmTitle"
          :confirmText="distributeConfirmMessages.join('<br /><br />')"
          @confirm="handleDistribute"
          @close-modal="handleCancelDistribute"
          @dontask-changed="handleDontAskChanged"
        />

        <ArvaModal
          v-if="confirmBeforeLeavingMessages.length"
          name="confirmModal"
          title="Unsaved Changes"
          @close-modal="cancelLeaving"
        >
          <div
            class="confirm-text"
            v-html="confirmBeforeLeavingMessages.join('<br /><br />')"
          ></div>

          <div class="row footer-row">
            <div class="col-12 btn-row d-flex justify-content-end">
              <v-btn class="ma-1" @click="closeWithoutSaving">
                Close without Saving
              </v-btn>

              <v-btn
                class="ma-1"
                color="green lighten-4"
                @click="cancelLeaving"
              >
                Stay on Page
              </v-btn>
            </div>
          </div>
        </ArvaModal>
      </div>
      <div v-else>
        <v-card elevation="0">
          <v-card-text class="text-center">
            <span class="text-h4"
              >No Fields Selected. Please select a field to apply management
              practices.</span
            >
          </v-card-text>
        </v-card>
      </div>
      <div class="save-footer py-3" v-if="editedFieldIds[year]?.size > 0">
        <div class="d-flex align-center justify-space-between">
          <h3 class="text-button text-white">Unsaved changes for {{ year }}</h3>
          <div>
            <v-btn class="cancel-btn my-3" outlined @click="cancelChanges"
              >Cancel</v-btn
            >
            <v-btn class="save-btn" outlined @click="handleSave">Save</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Organization, Filter } from "@/store/modules"
import { CROP_KEY, CROP_DISPLAY_NAME } from "@/constants"
import { YES_NO_OPTIONS } from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import { numberValidator } from "@/utility"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModalWithCheckbox from "@/components/modals/ConfirmModalWithCheckbox"
import ArvaModal from "@/components/modals/ArvaModal"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import machineIcon from "@/assets/images/star-04.svg"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import Permissions from "@/components/permissions/Permissions"

const unloadHandler = e => {
  e.preventDefault()
  e.returnValue = "Unsaved changes"
}

export default {
  name: "HarvestDataTable",
  components: {
    AlertErrorModal,
    ArvaModal,
    PracticeChoiceDropdown,
    ConfirmModalWithCheckbox,
    DashboardTitle,
    Permissions,
  },
  data() {
    return {
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      yesNoOptions: YES_NO_OPTIONS,
      harvestSettingsByField: [],
      editedFieldIds: {},
      residueRemovalOptions: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      // data values
      // table related
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: false,
      numberValidator,
      // redirection
      showRedirectModal: false,
      alertErrorModalOpen: false,
      redirectField: null,
      // select crop type UX
      cropSelect: null,
      //   new UI
      summaryFields: {},
      failedFields: {},
      showSaveView: false, // might just use loadState instead
      showLoadingView: false,
      showSaveConfirmModal: false,
      distributeConfirmTitle: "",
      distributeConfirmMessages: [],
      distributeValue: {},
      machineIcon,
      dontAskAgain: false,
      pendingNavigation: null,
      confirmBeforeLeavingMessages: [],
    }
  },
  directives: {
    arrowNavigation: {
      inserted(el, classBindingName) {
        el.addEventListener("keydown", function (e) {
          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
            e.preventDefault()
            const inputs = Array.from(
              document.querySelectorAll(`${classBindingName.value}`)
            ).map(el => el.querySelector("input"))

            const currentIndex = inputs.indexOf(e.target)
            let nextIndex

            if (e.key === "ArrowUp") {
              nextIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex
            } else {
              nextIndex =
                currentIndex < inputs.length - 1
                  ? currentIndex + 1
                  : currentIndex
            }

            const nextTextField = inputs[nextIndex]
            if (nextTextField) {
              nextTextField.focus()
            }
          }
        })
      },
    },
  },
  computed: {
    ...mapState({
      opsSettings: state => state.Defaults.newFieldSettings,
      years: state => state.Organization.organization.years,
      showSustainability: state =>
        state.Organization.organization.showSustainability,
    }),
    ...mapGetters({
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
      harvestTypeChoices: Defaults.Getters.getHarvestTypeChoices,
      dataCompletionMaster: Defaults.Getters.getWizardDataCompletionSummary,
      selectedFields: Filter.Getters.getSelectedFields,
      year: Organization.Getters.getYear,
    }),
    yieldUnitChoices() {
      return this.practiceChoices["harvest_units"].filter(
        item => item.value !== "g C/m2"
      )
    },
    selectedFieldIds() {
      return this.selectedFields.map(f => f.id)
    },
    fieldIdToSetting() {
      const settingMap = {}
      this.harvestSettingsByField.forEach((setting, index) => {
        settingMap[setting.fieldId] = index
      })
      return settingMap
    },
    dataCompletionForYear() {
      if (
        !this.year ||
        this.selectedFields.length == 0 ||
        !this.dataCompletionMaster ||
        "Harvest" in this.dataCompletionMaster === false
      ) {
        return 0
      }

      let yearDataComps = this.dataCompletionMaster["Harvest"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.round(average(yearDataComps))
      } else {
        return 0
      }
    },
    dataCompletionForFields() {
      if (
        !this.dataCompletionMaster ||
        "Harvest" in this.dataCompletionMaster === false
      ) {
        return {}
      }

      let comps = {}
      for (const completion of this.dataCompletionMaster["Harvest"]) {
        const compFound = completion.data_comp.by_year.find(
          yearObj => yearObj.year == this.year
        )
        if (compFound) {
          comps[completion.field_id] = compFound.avg_for_year
        } else {
          comps[completion.field_id] = 0
        }
      }
      return comps
    },
    fieldLocks() {
      let fieldLocks = {}

      for (const field of this.harvestSettingsByField) {
        fieldLocks[field.fieldId] = field[this.year].fieldLocked
      }
      return fieldLocks
    },
    harvestSettingsByFieldFiltered() {
      // returns sorted list for table, only reduces list when filtering by name

      let arrayToReturn = _.cloneDeep(
        this.harvestSettingsByField
          .map(f => f[this.year])
          .filter(f => this.selectedFieldIds.includes(Number(f.fieldId)))
      )

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]
      const filteredFields = _.orderBy(
        arrayToReturn,
        sortColumns,
        sortDirections
      )

      return filteredFields
    },
    historicalPlantings() {
      const thisYearsHarvest = _.cloneDeep(
        this.harvestSettingsByField
          .map(f => f[this.year])
          .filter(f => this.selectedFieldIds.includes(Number(f.fieldId)))
      )
      const something = _(thisYearsHarvest)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
        }))
        .value()

      return something
    },
    yearList() {
      // TODO copied from HistoricalPracticesLandingView
      if (!this.years || typeof this.years === "undefined") {
        return ["No Data Found"]
      }

      if (_.isEmpty(this.years)) {
        // TO DO: Reenable when logic for creating defaults for a year with no planting/harvest data (no year info) is fixed
        let currentDate = new Date()
        let currentYear = currentDate.getFullYear()
        let currentYears = [
          currentYear - 1,
          currentYear - 2,
          currentYear - 3,
          currentYear - 4,
        ]
        currentYears.unshift("All Years")

        return currentYears
      }

      let yearsCopy = _.cloneDeep(this.years.map(y => Number(y)))
      yearsCopy.unshift("All Years")
      return yearsCopy
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateHarvest: Defaults.Actions.updateHarvest,
      fetchCarbonSettingsByField: Defaults.Actions.fetchCarbonSettingsByField,
      updateWizardDataCompletionSummary:
        Defaults.Actions.updateWizardDataCompletionSummary,
    }),
    checkInfo(harvestInfo) {
      console.log("check info called")
      console.log(JSON.parse(JSON.stringify(harvestInfo)))
      return true
    },
    goBack() {
      this.$router.push("/historical-practices")
    },
    getItemValue(fieldId, harvestIndex, prop) {
      const idx = this.fieldIdToSetting[fieldId]

      const propItem =
        this.harvestSettingsByField[idx][this.year]["harvestInfo"][
          harvestIndex
        ][prop]

      if (propItem.source != "User Input" && propItem.source != "shapefile") {
        return null
      }
      const val =
        this.harvestSettingsByField[idx][this.year]["harvestInfo"][
          harvestIndex
        ][prop].value

      return val
    },
    setItemValue(fieldId, harvestIndex, prop, value, distribute = false) {
      if (value == this.getItemValue(fieldId, harvestIndex, prop)) {
        return
      }

      window.addEventListener("beforeunload", unloadHandler)

      this.editedFieldIds[this.year].add(parseInt(fieldId))
      this.summaryFields[this.year] = []
      this.failedFields[this.year] = []

      const idx = this.fieldIdToSetting[fieldId]

      this.harvestSettingsByField[idx][this.year]["harvestInfo"][harvestIndex][
        prop
      ].value = value
      this.harvestSettingsByField[idx][this.year]["harvestInfo"][harvestIndex][
        prop
      ].source = "User Input"

      if (distribute) {
        const cropId =
          this.harvestSettingsByField[idx][this.year]["harvestInfo"][
            harvestIndex
          ].crop_id.value
        this.confirmDistributeItemValue(prop, value, cropId, fieldId)
      }
    },
    confirmDistributeItemValue(prop, value, cropId, originFieldId) {
      this.distributeConfirmMessages = []

      if (
        this.fieldsInSelection.length == 0 ||
        (this.fieldsInSelection.length == 1 &&
          this.fieldsInSelection.includes(Number(originFieldId)))
      ) {
        return
      }

      const sameCropFields = this.fieldsInSelection.filter(f => {
        const idx = this.fieldIdToSetting[f]

        if (f == originFieldId) {
          return false
        }
        return this.harvestSettingsByField[idx][this.year]["crops"].includes(
          cropId
        )
      })

      if (sameCropFields.length == 0) {
        return
      }

      const machineValueFields = sameCropFields.filter(f => {
        const idx = this.fieldIdToSetting[f]
        const cropHarvestInfos = this.harvestSettingsByField[idx][this.year][
          "harvestInfo"
        ].filter(h => h.crop_id.value == cropId)
        return cropHarvestInfos.some(h => h[prop].source == "shapefile")
      })

      this.distributeConfirmTitle = `You're about to update ${
        sameCropFields.length
      } other field${sameCropFields.length > 1 ? "s" : ""}, continue?`
      const skippedFieldCount =
        this.fieldsInSelection.length - sameCropFields.length - 1
      if (skippedFieldCount > 0) {
        this.distributeConfirmMessages.push(
          `Only ${sameCropFields.length} other field${
            sameCropFields.length > 1 ? "s" : ""
          } will be updated. ${skippedFieldCount} selected field${
            skippedFieldCount.length > 1 ? "s" : ""
          } with a different crop will not be updated.`
        )
      }
      if (machineValueFields.length > 0) {
        this.distributeConfirmMessages.push(
          `${machineValueFields.length} ${prop} values from API or Shapefile data will be replaced`
        )
      }

      this.distributeValue = {
        prop: prop,
        value: value,
        cropId: cropId,
        sameCropFields: sameCropFields,
      }

      if (!this.dontAskAgain) {
        this.showSaveConfirmModal = true
      } else {
        this.handleDistribute()
      }
    },
    handleDistribute() {
      const { prop, value, cropId, sameCropFields } = this.distributeValue

      for (const fieldId of sameCropFields) {
        const idx = this.fieldIdToSetting[fieldId]
        const cropHarvestInfos = this.harvestSettingsByField[idx][this.year][
          "harvestInfo"
        ].filter(h => h.crop_id.value == cropId)
        for (const harvestInfo of cropHarvestInfos) {
          harvestInfo[prop].value = value
          harvestInfo[prop].source = "User Input"
        }
        this.editedFieldIds[this.year].add(parseInt(fieldId))
      }

      this.distributeValue = {}
      this.showSaveConfirmModal = false
      this.distributeConfirmMessages = []
      this.distributeConfirmTitle = ""
    },
    handleCancelDistribute() {
      this.distributeValue = {}
      this.showSaveConfirmModal = false
      this.distributeConfirmMessages = []
      this.distributeConfirmTitle = ""
    },
    handleDontAskChanged(dontAskAgain) {
      this.dontAskAgain = dontAskAgain
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (e == true) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    blankHarvestData(cropValue = null) {
      return {
        amount: null,
        crop_id: cropValue,
        date: this.year + "-01-01",
        type: null,
        pickerDate: this.year + "-01",
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
      }
    },
    async handleSave() {
      this.showSaveView = true

      let updateHarvests = []
      for (const editedFieldId of this.editedFieldIds[this.year]) {
        if (this.selectedFieldIds.includes(parseInt(editedFieldId))) {
          const field =
            this.harvestSettingsByField[this.fieldIdToSetting[editedFieldId]]

          if (!field.noYearData && !field.fieldLocked) {
            updateHarvests.push({
              fieldId: field.fieldId,
              promise: this.updateHarvest({
                fieldId: field.fieldId,
                year: this.year,
                harvests: field[this.year].harvestInfo,
              }),
            })
          } else {
            console.log(`field ${field.fieldName} is locked or has no data`)
          }
        } else {
          console.log(`field ${editedFieldId} is not in selection`)
        }
      }

      const results = await Promise.allSettled(
        updateHarvests.map(u => u.promise)
      )

      const failedFields = updateHarvests
        .filter((item, index) => results[index].status === "rejected")
        .map(item => parseInt(item.fieldId))
      const successFields = updateHarvests
        .filter((item, index) => results[index].status === "fulfilled")
        .map(item => parseInt(item.fieldId))

      for (const successFieldId of successFields) {
        this.editedFieldIds[this.year].delete(successFieldId)
      }

      const successFieldNames = successFields.map(
        id => this.harvestSettingsByField[this.fieldIdToSetting[id]].fieldName
      )
      const failedFieldNames = failedFields.map(
        id => this.harvestSettingsByField[this.fieldIdToSetting[id]].fieldName
      )

      this.summaryFields[this.year] = successFieldNames
      this.failedFields[this.year] = failedFieldNames
      this.showSaveView = false
      this.showSummaryView = true

      if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
        window.removeEventListener("beforeunload", unloadHandler)
      }

      this.updateWizardDataCompletionSummary({
        fieldIds: this.selectedFields.map(x => x.id),
      })
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (!resetCropSelect && e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    assembleHarvestSettingsSingleField(opsDefault, onlyYear = null) {
      const opsDict = {}

      const assembleYears = onlyYear ? [onlyYear] : this.yearListModified

      for (const yearInConsideration of assembleYears) {
        const fieldKey = opsDefault[0]
        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }

        const fieldSettingObj = opsDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
        }

        if (!(fieldKey in opsDict)) {
          opsDict[fieldKey] = {}
          opsDict[fieldKey] = idSettings
        }

        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            opsDict[fieldKey][yearInConsideration] = _.cloneDeep(idSettings)
            opsDict[fieldKey][yearInConsideration]["crops"] = ["No Data"]
            opsDict[fieldKey][yearInConsideration]["cropNames"] = ["No Data"]
            opsDict[fieldKey][yearInConsideration]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            opsDict[fieldKey][yearInConsideration] = _.cloneDeep(idSettings)
            opsDict[fieldKey][yearInConsideration]["crops"] = []
            opsDict[fieldKey][yearInConsideration]["cropNames"] = []
            opsDict[fieldKey][yearInConsideration]["cropSearch"] = ""
            opsDict[fieldKey][yearInConsideration]["plantings"] = []
            opsDict[fieldKey][yearInConsideration]["harvestInfo"] = []

            opsDict[fieldKey]["isSelected"] = this.fieldsInSelection.includes(
              parseInt(fieldKey)
            )
              ? true
              : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )

            cultivations.forEach(cultivation => {
              opsDict[fieldKey][yearInConsideration]["crops"].push(
                cultivation.arva_crop_id
              )
              opsDict[fieldKey][yearInConsideration]["cropSearch"] =
                opsDict[fieldKey][yearInConsideration]["cropSearch"] +
                CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              opsDict[fieldKey][yearInConsideration]["plantings"] = opsDict[
                fieldKey
              ][yearInConsideration]["plantings"].concat(cultivation.plantings)

              for (const harvest of cultivation.harvests) {
                if (harvest.yield?.value) {
                  harvest.yield.value = parseFloat(harvest.yield.value).toFixed(2)
                }

                opsDict[fieldKey][yearInConsideration]["harvestInfo"].push(
                  harvest
                )
              }
              opsDict[fieldKey][yearInConsideration]["crops"].sort(
                  (a, b) => a - b
                )

              opsDict[fieldKey][yearInConsideration]["cropNames"] = opsDict[
                fieldKey
              ][yearInConsideration]["crops"].map(c => CROP_DISPLAY_NAME[c])

              opsDict[fieldKey][yearInConsideration]["fieldLocked"] =
                cultivation.year_data_locked
              opsDict[fieldKey][yearInConsideration]["noYearData"] = false
            })
          }
        })
      }
      return Object.values(opsDict)[0]
    },
    assembleHarvestSettingsByField() {
      Object.entries(_.cloneDeep(this.opsSettings)).forEach(opsDefault => {
        if (!Object.keys(this.fieldIdToSetting).includes(opsDefault[0])) {
          const oneFieldSetting =
            this.assembleHarvestSettingsSingleField(opsDefault)
          if (oneFieldSetting) {
            this.harvestSettingsByField.push(oneFieldSetting)
          }
        }
      })
    },
    cancelChanges() {
      Object.entries(_.cloneDeep(this.opsSettings)).forEach(
        ([fieldId, fieldSettingObj]) => {
          if (fieldId in this.fieldIdToSetting) {
            const index = this.fieldIdToSetting[fieldId]

            const restoredFieldSetting =
              this.assembleHarvestSettingsSingleField(
                [fieldId, fieldSettingObj],
                this.year
              )

            if (restoredFieldSetting && restoredFieldSetting[this.year]) {
              this.harvestSettingsByField[index][this.year] =
                restoredFieldSetting[this.year]
            }
          }
        }
      )
      this.editedFieldIds[this.year] = new Set()
      if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
        window.removeEventListener("beforeunload", unloadHandler)
      }
    },
    loadHarvestData() {
      this.assembleHarvestSettingsByField()
    },
    closeWithoutSaving() {
      this.pendingNavigation()
    },
    cancelLeaving() {
      this.confirmBeforeLeavingMessages = []
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!Object.values(this.editedFieldIds).some(set => set.size > 0)) {
      next()
    } else if (this.confirmBeforeLeavingText) {
      next()
    } else {
      this.pendingNavigation = next
      const unsavedMsgs = Object.entries(this.editedFieldIds)
        .filter(([_, crops]) => crops.size !== 0)
        .map(
          ([year, crops]) =>
            `• ${crops.size} unsaved field${
              crops.size > 1 ? "s" : ""
            } for ${year}`
        )
      this.confirmBeforeLeavingMessages = [
        "You have unsaved changes. Are you sure?",
        ...unsavedMsgs,
      ]
    }
  },

  mounted() {
    this.fieldsInSelection = []

    this.yearList.forEach(year => {
      this.$set(this.editedFieldIds, year, new Set())
      this.$set(this.failedFields, year, [])
      this.$set(this.summaryFields, year, [])
    })

    this.loadHarvestData()
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", unloadHandler)
  },
  watch: {
    opsSettings: {
      handler: async function (newFields, oldFields) {
        this.assembleHarvestSettingsByField()
      },
    },
    yearList: {
      handler: async function (newYears) {
        newYears.forEach(year => {
          if (year in this.editedFieldIds === false) {
            this.$set(this.editedFieldIds, year, new Set())
          }
          if (year in this.summaryFields === false) {
            this.$set(this.summaryFields, year, [])
          }
          if (year in this.failedFields === false) {
            this.$set(this.failedFields, year, [])
          }
        })
      },
    },
    selectedFields: {
      handler: async function (newFields, oldFields) {
        // this is done in HistoricalPracticesLandingView for the un-routed wizards
        // fetchCarbonSettingsByField updates opsSettings which triggers that watcher :/
        const newFieldIds = newFields.map(f => f.id)
        const oldFieldIds = oldFields.map(f => f.id)

        if (!_.isEqual(newFieldIds, oldFieldIds)) {
          const fieldIds = this.selectedFields.map(f => f.id)
          this.showLoadingView = true
          await this.fetchCarbonSettingsByField({
            fieldIds: fieldIds,
          })
          this.showLoadingView = false
        }
      },
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(
          this.harvestSettingsByField.map(f => f[this.year])
        )

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        // TODO - not sure about this. if implemented should be skipped for All
        // this.fieldsInSelection = arrayToFilter
        //   .map(f => parseInt(f.fieldId))
        //   .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.text-container {
  width: 200px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}

.machine-icon {
  width: 20px;
  height: 100%;
}

.sm-md-column {
  min-width: 120px;
}

.medium-column {
  min-width: 150px;
}

.small-column {
  min-width: 50px;
}

.save-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #464b58 !important;
  padding-left: 300px;
}

.save-footer h3 {
  font-size: 1.1rem !important;
}

.save-btn {
  height: 40px !important;
  width: 110px;
  margin-right: 90px;
  background-color: #28a745 !important;
  color: #ffffff !important;
  font-size: 1.1rem !important;
}

.cancel-btn {
  height: 40px !important;
  width: 110px;
  margin-right: 30px;
  color: #c5c9d2 !important;
  font-size: 1.1rem !important;
}

::v-deep .v-data-table__wrapper td,
::v-deep .v-data-table__wrapper th {
  padding: 1px 7px !important;
}

::v-deep .v-data-table__wrapper th {
  line-height: 1.5;
}

::v-deep .v-input--is-focused .v-input__slot {
  background-color: #f4f8fd !important;
}

.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.confirm-text {
  font-size: 15px;
  font-weight: 500;
  padding: 30px 30px;
}

.low-space {
  line-height: 1.1;
}
</style>
